import React from "react";
import styled from "styled-components";
import MediumDeviceSlider from "./MediumDeviceSlider";
import LargeDeviceSlider from "./LargeDeviceSlider";
import MobileDeviceSlider from "./MobileDeviceSlider";
import MediaQuery from "react-responsive";
function About() {
  return (
    <AboutContainer id="about">
      <Heading>
        <p>
          We transform supply chain transparency data to accelerate your access
          to vetted manufacturers.
        </p>
      </Heading>
      <MediaQuery query="(max-width: 658px)">
        <MobileDeviceSlider />
      </MediaQuery>
      <MediaQuery query="(min-width: 659px) and (max-width: 930px)">
        <MediumDeviceSlider />
      </MediaQuery>
      <MediaQuery query="(min-width: 931px)">
        <LargeDeviceSlider />
      </MediaQuery>
      <Paragraph>
        Search and easily sort more than 20,000 factories from over 100
        countries based on the brands they work with and their social or
        environmental impact. Our platform adheres to the United Nations
        Sustainable Development Goals to assess suppliers, with the aim of being
        the most transparent and trusted resource for high-quality, ethical and
        sustainable fashion. We make it easier for brands to meet the demands of
        the modern conscious consumer, employee, and shareholder to address the
        critical impact targets needed by 2030.
      </Paragraph>
      <img
        src={require("../../assets/images/whiteEllipse.svg")}
        alt="ellipse"
        className="child"
      />
    </AboutContainer>
  );
}

export default About;
const AboutContainer = styled.div`
  position: relative;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #d980fa;
  align-items: center;
  justify-content: center;
  padding: 140px 0;
  .child {
    position: absolute;
    bottom: -450px;
    left: -100px;
    opacity: 0.16;
    transform: rotate(180deg);
    @media (max-width: 685px) {
      padding: 70px 0;
    }
    @media (max-width: 538px) {
      bottom: -280px;
      left: -100px;
      width: 450px;
    }
  }
  @media (max-width: 685px) {
    padding: 70px 0;
  }
  @media (min-width: 685px) and (max-width: 870px) {
    width: 100%;
    overflow-x: hidden;
  }
`;

const Heading = styled.div`
  max-width: 896px;
  p {
    padding: 0 60px;
    font-family: "ProximaNova-Bold";
    font-size: 1.875em;
    text-align: center;
    color: white;
  }
  @media (max-width: 568px) {
    p {
      font-size: 20px;
      text-align: left;
      padding: 0 30px;
    }
  }
`;

const Paragraph = styled.p`
  padding: 0 60px;
  font-family: "ProximaNova-Regular";
  color: white;
  font-size: 1.125em;
  line-height: 1.44;
  max-width: 1000px;
  text-align: center;
  padding-bottom: 30px;
  @media (max-width: 568px) {
    font-size: 18px;
    text-align: left;
    padding: 0 30px;
  }
`;
