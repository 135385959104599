import React from "react";
import {
  Form,
  Input,
  Label,
  FormButton,
  FormElement
} from "../../common/formStyles";
import AuthService from "../../services/AuthService";
import { Formik } from "formik";
import styled from "styled-components";
import { signUpValidationSchema } from "../../utils/validationSchemas";
import { SnackbarControllerContext } from "../../context/SnackbarControllerContext";
import { UserContext } from "../../context/UserContext";
import { ModalContext } from "../../context/ModalContext";
import MediaQuery from "react-responsive";
import { navigate } from "@reach/router";

const SignUpModal = styled.div`
  width: "100%";
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .signUpForm {
    margin: 25px auto;
    @media (max-width: 685px) {
      width: 90%;
      padding: 0 10px;
    }
  }
  .terms-and-conditions {
    width: 100%;
    height: 18px;
    font-family: "ProximaNova-Light";
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    padding-top: 22px;
    padding-bottom: 22px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5e5e5e;
    span {
      cursor: pointer;
      text-decoration: underline;
      font-family: ProximaNova-Light;
    }
    @media (max-width: 685px) {
      display: none;
    }
  }
  .login-container {
    width: 100%;
    height: 18px;
    font-family: "ProximaNova-Light";
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5e5e5e;
    padding-top: 0em;
    margin-bottom: 2em;
    .login-text {
      padding: 0 5px;
      font-size: 14px;
      font-weight: bold;
      font-family: "ProximaNova-Bold";
      color: #1b1b1b;
      cursor: pointer;
    }
  }
  .input-field {
    outline: 0;
    border: none;
    border-bottom: solid 0.4px rgba(67, 78, 160, 0.98);
    font-family: "ProximaNova-Regular";
    font-size: 1em;
    padding-left: 0;
  }
  .field-label {
    width: 36px;
    height: 17px;
    font-family: "ProximaNova-SemiBold";
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #000000;
  }
  .form-error {
    font-family: "ProximaNova-Regular";
    padding: 5px 0;
  }
  .signup-title {
    width: 100%;
    .signup {
      width: 97px;
      height: 34px;
      font-family: "ProximaNova-Bold";
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
  }
  .signup-btn {
    all: unset;
    width: 100%;
    height: 62px;
    border-radius: 1px;
    background-color: #2f3b55;
    margin: 38px 0px 0px 0px;
    text-align: center;
    color: white;
    -webkit-text-fill-color: white;
    font-size: 1em;
    font-family: "ProximaNova-Bold";
    cursor: pointer;
    @media (max-width: 685px) {
      width: 100%;
    }
  }
`;

const HeaderCenter = styled.div`
  align-self: center;
  display: flex;
  align-items: flex-end;
  color: white;
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 50%;
  font-size: 20px;
  transform: translate(-50%, -50%);
  .logoBold {
    font-family: "Brown-Regular";
  }
  .logoRegular {
    font-family: "Brown-Light";
  }
  @media (max-width: 685px) {
    left: 62%;
    top: 40%;
  }
`;

const SvgContainer = styled.div`
  position: relative;
  height: 63px;
  width: 100%;
  overflow: hidden;
  .ellipse {
    position: absolute;
    left: 15%;
    transform: translateX(-50%);
    top: -27px;
    width: 359px;
    transform: rotate(-7deg);
  }
  .close {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
  }
  @media (min-width: 599px) and (max-width: 600px) {
    .logo {
      position: absolute;
      top: -16px;
      transform: translateX(-105%);
    }
  }
  @media (min-width: 299px) and (max-width: 320px) {
    .logo {
      position: absolute;
      top: -11px;
      transform: translateX(-50%);
      height: 29px;
    }
  }
  .close {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
    @media (max-width: 685px) {
      top: 0.75em;
      right: 1.25em;
    }
    @media (max-width: 320px) {
      top: 1.3em;
      right: 1.25em;
    }
  }
`;

function SignUpForm({ onClose }) {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { setUser } = React.useContext(UserContext);
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  const { setLoginModalOpen, setSignupModalOpen } = React.useContext(
    ModalContext
  );

  async function onSubmit(e, values) {
    e.preventDefault();
    setSubmitting(true);
    const post_body = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      password_confirmation: values.confirmPassword
    };
    try {
      const result = await AuthService.signup(post_body);
      if (result && result.status === 201) {
        setSubmitting(false);
        onClose();
        setSnackbarProps({
          variant: "success",
          isOpen: true,
          message: "Signup Successful"
        });
        setUser(JSON.stringify(result.response.user));
        localStorage.setItem("user", JSON.stringify(result.response.user));
      }
    } catch (error) {
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.error.message && typeof error.error.message === "string"
            ? error.error.message
            : "Please try again later"
      });
      setSubmitting(false);
    }
  }

  function onLoginClick() {
    setLoginModalOpen(true);
    setSignupModalOpen(false);
  }

  function onTermsAndConditionsClick() {
    setSignupModalOpen(false);
    navigate("termsofservice");
  }

  return (
    <SignUpModal>
      <SvgContainer>
        <HeaderCenter>
          <img
            src={require("../../assets/images/goodlist_logo4.svg")}
            style={{ width: "120px", cursor: "pointer" }}
            alt="goodlist"
            className="logo"
          />
        </HeaderCenter>
        <img
          src={require("../../assets/images/login.svg")}
          alt="goodlist"
          className="ellipse"
        />
        <MediaQuery query="(min-width: 659px)">
          <img
            src={require("../../assets/images/x@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 658px)">
          <img
            src={require("../../assets/images/close-white@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 600px)">
          <img
            src={require("../../assets/images/x@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 414px)">
          <img
            src={require("../../assets/images/close-white@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
      </SvgContainer>

      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: ""
        }}
        validationSchema={signUpValidationSchema}
      >
        {({ handleChange, handleBlur, values, errors, isValid, touched }) => (
          <Form className="signUpForm" onSubmit={e => onSubmit(e, values)}>
            <div className="signup-title">
              <p className="signup">Sign&nbsp;Up</p>
            </div>
            <FormElement>
              <Label className="field-label">First Name</Label>
              <Input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                name="firstName"
                value={values.firstName}
                autoFocus
                className="input-field"
              />
              <div className="form-error">
                {touched.firstName && errors.firstName}
              </div>
            </FormElement>
            <FormElement>
              <Label className="field-label">Last Name</Label>
              <Input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                name="lastName"
                value={values.lastName}
                className="input-field"
              />
              <div className="form-error">
                {touched.lastName && errors.lastName}
              </div>
            </FormElement>
            <FormElement>
              <Label className="field-label">Email</Label>
              <Input
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                value={values.email}
                className="input-field"
              />
              <div className="form-error">{touched.email && errors.email}</div>
            </FormElement>
            <FormElement>
              <Label className="field-label">Password</Label>
              <Input
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                name="password"
                value={values.password}
                className="input-field"
              />
              <div className="form-error">
                {touched.password && errors.password}
              </div>
            </FormElement>
            <FormElement>
              <Label className="field-label">Confirm password</Label>
              <Input
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                name="confirmPassword"
                value={values.confirmPassword}
                className="input-field"
              />
              <div className="form-error">
                {touched.confirmPassword && errors.confirmPassword}
              </div>
            </FormElement>
            <p className="terms-and-conditions">
              By Clicking Done you agree to accept GoodList’s <br />
              <span onClick={onTermsAndConditionsClick}>Terms of Service</span>
            </p>
            <FormButton
              type="submit"
              disabled={isSubmitting || !isValid}
              style={{ opacity: (isSubmitting || !isValid) && 0.4 }}
              className="signup-btn"
            >
              Sign up
            </FormButton>
          </Form>
        )}
      </Formik>
      <div className="login-container">
        Already have an account?
        <span className="login-text" onClick={onLoginClick}>
          Log in
        </span>
      </div>
    </SignUpModal>
  );
}

export default SignUpForm;
