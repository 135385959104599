import React, { useState, useEffect, useContext } from "react";
import Header from "./components/home/header/Header";
import Filter from "./GoodlistComponents/Filter";
import FilterContainer from "./GoodlistComponents/FilterContainer";
import styled from "styled-components";
import SuppliersList from "./GoodlistComponents/SuppliersList";
import GoodListService from "./GoodlistComponents/GoodListService";
import { SnackbarControllerContext } from "./context/SnackbarControllerContext";
import FullpageLoader from "./common/FullpageLoader";
import { FullpageLoaderContext } from "./context/FullpageLoaderContext";
import { UserContext } from "./context/UserContext";
import { ScrollPosition } from "./managers/StoreManager";
import { FilterCountContext } from "./context/FilterCountContext";
import { FilterOptionsContext } from "./context/FilterOptionsContext";

function GoodList() {
  const [response, setResponse] = useState({});
  const [filters, setFilters] = useState();
  const [applyingFilter, setApplyingFilter] = useState(false);
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  const { fullPageLoader } = React.useContext(FullpageLoaderContext);
  const { setFiltersForCount } = useContext(FilterCountContext);
  const { filterOptions, setFilterOptions } = useContext(FilterOptionsContext);
  const { user } = React.useContext(UserContext);
  const [scrollPos, setScrollPos] = useState();
  useEffect(() => {
    if (ScrollPosition.getSuppliersList() > 0) {
      setTimeout(() => {
        window.scrollTo({
          top: ScrollPosition.getSuppliersList(),
          left: 0,
          behavior: "smooth"
        });
        ScrollPosition.deleteScrollValue();
      }, 1000);
    }
    window.addEventListener("scroll", () => {
      setScrollPos(window.pageYOffset);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScrollPos(window.pageYOffset);
      });
    };
  }, []);

  const listProducts = async params => {
    setFilters(params);
    let resultParams = {};
    for (let key in params) {
      resultParams = {
        ...resultParams,
        [key.replace(/\s/g, "").toLowerCase()]: params[key]
      };
    }
    try {
      const response = await GoodListService.listProducts(
        resultParams,
        user ? JSON.parse(user).auth_token : null
      );
      if (params.editorsPick !== null && response && response.status === 200) {
        setFiltersForCount(params);
      }
      handleSuccess(response);
      window.scrollTo(0, 0);
    } catch (err) {
      handleError(err);
    }
  };
  const handleSuccess = response => {
    setApplyingFilter(false);
    setResponse(response.response);
    if(response.response.filter_options){
    setFilterOptions(response.response.filter_options);
  } 
  };
  const handleError = error => {
    setSnackbarProps({
      variant: "error",
      isOpen: true,
      message:
        error && typeof error === "string"
          ? error
          : error.error.message && typeof error.error.message === "string"
          ? error.error.message
          : "Please try again later"
    });
  };
  return (
    <div
      style={{
        background: "#f9f9f9",
        position: "relative",
        minHeight: "100vh"
      }}
    >
      {fullPageLoader.isOpen ? <FullpageLoader /> : null}
      <Header main={true} suppliers={true} />

      <Filter
        getProducts={listProducts}
        applyingFilter={bool => setApplyingFilter(bool)}
      />
      <Main>
       {filterOptions ?  <FilterContainer
        filterOptions={filterOptions}
          getProducts={listProducts}
          applyingFilter={bool => setApplyingFilter(bool)}
        /> : null }
        <SuppliersList
          getProducts={listProducts}
          response={response}
          filters={filters}
          applyingFilter={applyingFilter}
          scrollPosition={scrollPos}
        />
      </Main>
      {/* <Footer contact={true} /> */}
    </div>
  );
}

export default GoodList;

const Main = styled.div`
  width: 100vw;
  display: flex;
  position: relative;

  @media (max-width: 685px) {
  }
`;
