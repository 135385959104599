import React, { useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import {
  Form,
  Input,
  Label,
  FormButton,
  FormElement,
  TextArea
} from "../../common/formStyles";
import { navigate } from "@reach/router";
import GoodListService from "../../GoodlistComponents/GoodListService";
import Dropdown from "react-dropdown";
import { contactValidationSchema } from "../../utils/validationSchemas";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/dist/style.css";
import { SnackbarControllerContext } from "../../context/SnackbarControllerContext";
function ContactForm() {
  const [clientChoice, setClientChoice] = useState();
  const clientChoices = [
    { value: "Agent", Label: "Agent", className: "myOptionClassName" },
    { value: "Buyer", Label: "Buyer", className: "myOptionClassName" },
    { value: "Supplier", Label: "Supplier", className: "myOptionClassName" },
    { value: "Other", Label: "Other", className: "myOptionClassName" }
  ];
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);

  const onSubmit = async (e, values) => {
    e.preventDefault();

    setSubmitting(true);
    try {
      const result = await GoodListService.contact({
        ...values,
        type: clientChoice.value
      });
      if (
        (result && result.status === 200) ||
        (result && result.status === 201)
      ) {
        setSnackbarProps({
          variant: "success",
          isOpen: true,
          message: "We will get back to you shortly."
        });
        navigate("/");
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.error.message && typeof error.error.message === "string"
            ? error.error.message
            : "Please try again later"
      });
    }
  };
  return (
    <ContactFormContainer>
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          contactNumber: "",
          reason: ""
        }}
        validationSchema={contactValidationSchema}
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          isValid,
          touched,
          setFieldValue
        }) => (
          <Form className="formContainer" onSubmit={e => onSubmit(e, values)}>
            <FormElement className="formElement">
              <Label className="field-label">Name</Label>
              <Input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                name="fullName"
                value={values.fullName}
                className="input-field"
                autoFocus={false}
              />
              <div className="form-error">
                {touched.fullName && errors.fullName}
              </div>
            </FormElement>
            <FormElement className="formElement">
              <Label className="field-label">Email</Label>
              <Input
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                value={values.email}
                className="input-field"
              />
              <div className="form-error">{touched.email && errors.email}</div>
            </FormElement>
            <FormElement className="formElement">
              <Label className="field-label">Number</Label>
              <ReactPhoneInput
                defaultCountry={"us"}
                containerStyle={{
                  marginTop: 10
                }}
                dropdownStyle={{ marginLeft: "100px" }}
                buttonClass="codeBtn"
                inputStyle={{
                  height: "40px",
                  paddingLeft: 68,
                  fontFamily: "ProximaNova-Regular",
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid rgba(179, 179, 179, 0.37)"
                }}
                value={values.contactNumber}
                inputExtraProps={{
                  name: "contactNumber"
                }}
                onChange={phone => setFieldValue("contactNumber", phone)}
                onBlur={handleBlur}
              />
              <div className="form-error">
                {touched.contactNumber && errors.contactNumber}
              </div>
            </FormElement>

            <FormElement className="formElement">
              <Label className="field-label">Message</Label>
              <TextArea
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                name="reason"
                value={values.reason}
                className="input-field"
                maxLength="255"
              />
              <p
                style={{
                  textAlign: "right",
                  fontFamily: "ProximaNova-Regular",
                  fontSize: 12,
                  paddingTop: "15px "
                  // color: ""
                }}
              >
                {255 - values.reason.length} characters
              </p>
              <div className="form-error">
                {touched.reason && errors.reason}
              </div>
            </FormElement>
            <FormElement className="formElement">
              <Label className="field-label">You are a/an</Label>
              <Dropdown
                placeholderClassName="placeholderClass"
                menuClassName="myMenuClassName"
                arrowClassName="myArrowClassName"
                className="dropDown"
                controlClassName="myControlClassName"
                onChange={select => {
                  setClientChoice(select);
                }}
                options={clientChoices}
                placeholder={"Select One"}
                value={clientChoice}
                arrowClosed={
                  <span className="arrow-closed">
                    <img
                      alt="open"
                      src={require("../../assets/images/dropdown@3x.png")}
                    />
                  </span>
                }
                arrowOpen={
                  <span className="arrow-open">
                    <img
                      alt="close"
                      src={require("../../assets/images/dropdown@3x.png")}
                    />
                  </span>
                }
              />
            </FormElement>
            <FormButton
              type="submit"
              disabled={isSubmitting || !clientChoice || !isValid}
              style={{
                opacity: isSubmitting || !clientChoice || !isValid ? 0.5 : 1
              }}
            >
              Contact
            </FormButton>
          </Form>
        )}
      </Formik>
    </ContactFormContainer>
  );
}

export default ContactForm;

const ContactFormContainer = styled.div`
  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 380px;
    margin: 0 !important;
    @media (max-width: 685px) {
      width: 100%;
    }
  }
  .formElement {
    @media (max-width: 685px) {
      width: 100%;
    }
  }
  .myControlClassName {
    position: relative;
    height: 33px;
    width: 100%;
    border: none;
    border-bottom: solid 0.4px rgba(179, 179, 179, 0.98);
    font-family: "ProximaNova-Regular";
    color: #606060;
    font-size: 0.75em;
    padding-bottom: 10px;
  }
  .placeholderClass {
    font-family: "ProximaNova-Regular";
    color: black;
    font-size: 1.2em;
  }
  .dropDown {
    cursor: pointer;
    padding-top: 15px;
    height: 100%;
  }
  .myOptionClassName {
    padding: 10px 0;
    font-family: "ProximaNova-SemiBold";
  }
  .myOptionHeaderClassName {
    font-size: 1.5em;
    padding: 13px 0;
    color: black;
  }
  .myMenuClassName {
    padding: 5px 10px;
    background: white;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
    margin-top: 20px;
    cursor: pointer;
  }
  .arrow-closed {
    position: absolute;
    right: 0;
    top: 0;
    img {
      width: 8px;
    }
  }
  .arrow-open {
    position: absolute;
    right: 0;
    top: 0;
    img {
      width: 7px;
      transform: rotate(180deg);
    }
  }
  .codeBtn {
    background: none !important;
    border: none !important;
    border-right: 1px solid rgba(179, 179, 179, 0.37) !important;
    width: 55px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .input-field {
    outline: 0;
    border: none;
    border-bottom: solid 0.4px rgba(179, 179, 179, 0.98);
    font-family: "ProximaNova-Regular";
    font-size: 0.875em;
    background-color: #fff;
    color: #000;
  }
  .field-label {
    width: 36px;
    height: 17px;
    font-family: "ProximaNova-SemiBold";
    font-size: 0.875em;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #848484;
  }
  .form-error {
    font-family: "ProximaNova-Regular";
    padding: 5px 0;
  }
`;
