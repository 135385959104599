import React from "react";
import styled from "styled-components";
function Button(props) {
  return (
    <ButtonConatiner onClick={() => props.buttonClick()} {...props}>
      {props.btnText}
    </ButtonConatiner>
  );
}

export default Button;

const ButtonConatiner = styled.div`
  display: flex;
  cursor: ${props => (props.cursor ? props.cursor : "pointer")};
  align-items: center;
  justify-content: center;
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props =>
    props.backgroundColorProp ? props.backgroundColorProp : "#2f3b55"};
  color: ${props => (props.home ? "#2f3b55" : "white")};
  font-family: "ProximaNova-Bold";
  font-size: ${props => (props.home ? "1em" : "0.875em")};
  &:hover {
    box-shadow: ${props =>
      props.cursor === "not-allowed"
        ? null
        : props.home
        ? "0px 0px 30px 0px rgba(218, 131, 250, 0.2)"
        : "0px 8px 10px rgba(47, 59, 85, 0.15)"};
  }
  &:active {
    box-shadow: ${props =>
      props.cursor === "not-allowed"
        ? null
        : "0px 8px 10px rgba(47, 59, 85, 0.15)"};
  }
  @media (max-width: 685px) {
    width: ${props =>
      props.headerBtn ? "67px" : props.fiterBtn ? "64px" : "100%"};
    font-size: ${props =>
      props.headerBtn ? "12px" : props.fiterBtn ? "12px" : null};
    background: ${props => (props.headerBtn ? "white" : null)};
    color: ${props => (props.headerBtn ? "black" : null)};
    border: ${props => (props.headerBtn ? "1px solid black" : null)};
    font-family: ${props => (props.headerBtn ? "ProximaNova-SemiBold" : null)};
  }
  @media (min-width: 600px) and (max-width: 900px) {
    font-size: ${props => (props.home ? "20px" : null)};
    height: ${props => (props.home ? "70px" : props.height)};
  }
`;
