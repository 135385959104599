import React from "react";

export const FilterOptionsContext = React.createContext();

function FilterOptionsProvider({ children }) {
  const [filterOptions, setFilterOptions] = React.useState(null);
  const value = React.useMemo(() => {
    return {
      filterOptions,
      setFilterOptions
    };
  }, [filterOptions, setFilterOptions]);

  return (
    <FilterOptionsContext.Provider value={value}>
      {children}
    </FilterOptionsContext.Provider>
  );
}

export default FilterOptionsProvider;
