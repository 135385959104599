const StoreKeys = {
  UserAuthToken: "user_auth_token",
  SuppliersListScrollPosition: "suppliers-list-scroll-position",
  User: "user",
  Url: 'url'
};

const UserAuthentication = {
  deleteAllKeys() {
    Object.values(StoreKeys).forEach(value => {
      localStorage.removeItem(value);
    });
  },
  isUserTokenAvailable() {
    let token = localStorage.getItem(StoreKeys.UserAuthToken);
    return token ? true : false;
  },
  saveToken(token) {
    localStorage.setItem(StoreKeys.UserAuthToken, token);
  },
  getToken() {
    let user = JSON.parse(localStorage.getItem(StoreKeys.User));
    return user ? user.auth_token : null;
  }
};
const ScrollPosition = {
  setSuppliersList(position) {
    localStorage.setItem(StoreKeys.SuppliersListScrollPosition, position);
  },
  getSuppliersList() {
    const scrollPosition = localStorage.getItem(
      StoreKeys.SuppliersListScrollPosition
    );
    return scrollPosition;
  },
  deleteScrollValue() {
    localStorage.removeItem(StoreKeys.SuppliersListScrollPosition);
  }
};
module.exports = {
  UserAuthentication,
  ScrollPosition
};
