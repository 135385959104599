export const customSignUpModalStyles = {
  content: {
    top:   window.innerWidth < 321 ? "73%" :window.innerWidth < 361 ? "67%" : window.innerWidth < 413? "52%" : window.innerWidth < 801 ? "48%" : "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 685 ? "90%" : "500px",
    overflowY: "auto",
    padding: 0,
    border: "none",
    boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.07)"
  },
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.43)",
    position: "fixed",
    overflowY: "auto"
  }
};
