import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SupplierCard from "./SupplierCard";
import ReactPaginate from "react-paginate";
import "./SuppliersList.css";
import Switch from "react-switch";
import { FullpageLoaderContext } from "../context/FullpageLoaderContext";
import { ScrollPosition } from "../managers/StoreManager";
import { QueryParams } from "../managers/QueryParamsManager";
import GoodListService from "./GoodListService";
import { SnackbarControllerContext } from "../context/SnackbarControllerContext";

import LoaderContainer from "../common/LoaderContainer";
import { UserContext } from "../context/UserContext";
import FullpageLoader from "../common/FullpageLoader";
function SuppliersList({
  applyingFilter,
  response,
  getProducts,
  filters,
  scrollPosition
}) {
  const [pageNo, setPageNo] = useState(1);
  const [producers, setProducers] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [totalItemCount, setTotalItemCount] = useState();
  const [bookmarkLoader, setBookmarkLoader] = useState();
  const [paginating, setPaginating] = useState(false);
  const { setFullPageLoader } = React.useContext(FullpageLoaderContext);
  const { user } = React.useContext(UserContext);
  const [editorsPick, setEditorsPick] = useState(true);
  const [checked, setChecked] = useState(true);
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  const handleChange = checked => {
    setChecked(checked);
    
    setEditorsPick(true);
    const localFilters = QueryParams.refineQueryParams();
    let params = {
      ...localFilters,
      editorsPick: checked,
      pageNo: 1
    };
    let refineParams = {};
    for ( let key in params) {
      if(params[key]) {
        refineParams = {...refineParams, [key]: params[key]}
      }
    }
    QueryParams.setQueryParams(refineParams);
    getProducts(params);
  };
  useEffect(() => {
    setProducers(response && response.suppliers);
    setPaginating(false);
    setEditorsPick(false);
    setTotalPages(response && response.total_pages);
    setTotalItemCount(response && response.item_count);
    setFullPageLoader({
      isOpen: false
    });
    const localFilters = QueryParams.refineQueryParams();
    setPageNo(localFilters && localFilters.pageNo ? localFilters.pageNo : 1);
    setChecked(
      localFilters && localFilters.editorsPick
        ? localFilters.editorsPick
        : false
    );
  }, [response]);
  useEffect(() => {
    if (!paginating) {
      setFullPageLoader({
        isOpen: true
      });
    }

    const localFilters = QueryParams.refineQueryParams();
    if (localFilters) {
      let params = {
        ...localFilters,
        editorsPick: localFilters.editorsPick ? localFilters.editorsPick : false
      };
    let refineParams = {};
    for ( let key in params) {
      if(params[key]) {
        refineParams = {...refineParams, [key]: params[key]}
      }
    }
    QueryParams.setQueryParams(refineParams);
      getProducts(params);
    } else {
      let params = { ...filters, pageNo: pageNo, editorsPick: checked };
    let refineParams = {};
    for ( let key in params) {
      if(params[key]) {
        refineParams = {...refineParams, [key]: params[key]}
      }
    }
    QueryParams.setQueryParams(refineParams);
      getProducts(params);
    }
  }, [pageNo, user]);

  const handlePageClick = page => {
    setPaginating(true);
    ScrollPosition.deleteScrollValue();

    const localFilters = QueryParams.refineQueryParams();
    if (localFilters) {
      QueryParams.setQueryParams({
        ...localFilters,
        pageNo: page.selected + 1
      });
    } else {
      QueryParams.setQueryParams({ pageNo: page.selected + 1 });
    }
    setPageNo(page.selected + 1);
  };

  const bookmarkSupplier = async (id, flag) => {
    setBookmarkLoader({
      id: id,
      flag: true
    });
    try {
      const result = await GoodListService.bookmarkSupplier(id, flag);
      if (result && result.status === 200) {
        handleBookmarkSuccess(result, id, false);
      } else if (result && result.status === 201) {
        handleBookmarkSuccess(result, id, true);
      }
    } catch (error) {
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.error.message && typeof error.error.message === "string"
            ? error.error.message
            : "Please try again later"
      });
    }
    setBookmarkLoader({
      id: id,
      flag: false
    });
  };
  const handleBookmarkSuccess = (result, id, flag) => {
    const suppliers = producers;
    suppliers.forEach(data => {
      if (data.id === id) {
        data.is_bookmarked = flag;
      }
    });
    // setProducers([]);
    setBookmarkLoader({
      id: id,
      flag: false
    });
    setProducers(suppliers);
    setSnackbarProps({
      variant: result.status === 200 ? "info" : "success",
      isOpen: true,
      message:
        result.response.message && typeof result.response.message === "string"
          ? result.response.message
          : "Success"
    });
  };
  return (
    <SuppliersListContainer>
      {!producers || paginating || applyingFilter ? null : (
        <InfoContainer>
          <p className="resultsText">
            Showing {editorsPick ? 0 : producers && producers.length} of{" "}
            {editorsPick ? 0 : totalItemCount} results
          </p>

          <SwitchContainer>
            <p className="editorsPickText" style={{ paddingRight: "12px" }}>{"Editors Pick"}</p>
            <label>
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={handleChange}
                checked={checked}
                onColor={"#2f3b55"}
                handleDiameter={window.innerWidth < 685 ? 13 : 21}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={window.innerWidth < 685 ? 5.6 : 9}
                width={window.innerWidth < 685 ? 24.2 : 40}
                className="react-switch"
                id="material-switch"
              />
            </label>
          </SwitchContainer>
        </InfoContainer>
      )}
      <SuppliersContainer>
        {editorsPick ? (
          <div style={{ margin: "25%  auto" }}>
            <LoaderContainer message="Applying changes" />
          </div>
        ) : !applyingFilter ? (
          paginating ? (
            <div style={{ margin: "25%  auto" }}>
              <LoaderContainer message={`Moving to Page ${pageNo}..`} />
            </div>
          ) : producers ? (
            producers.length !== 0 ? (
              producers.map((data, index) => {
                return (
                  <SupplierCard
                    bookmarkLoader={bookmarkLoader}
                    onBookmark={(id, flag) => bookmarkSupplier(id, flag)}
                    producer={data}
                    key={index}
                    scrollPosition={scrollPosition}
                  />
                );
              })
            ) : (
              <div style={{ margin: "25%  auto" }}>
                <LoadingContainerLocal>
                  <p>No Suppliers Found.</p>
                </LoadingContainerLocal>
              </div>
            )
          ) : null
        ) : (
       <FullpageLoader></FullpageLoader>
        )}
      </SuppliersContainer>
      {editorsPick ||
      !producers || 
      (producers && producers.length === 0) ||
      paginating ||
      totalPages === 1 ||
      applyingFilter ? null : (
        <PaginationContainer>
          <ReactPaginate
            previousLabel={
              pageNo === 1 ? null : (
                <img
                  alt="open"
                  src={require("../assets/images/dropdown@3x.png")}
                  style={{
                    width: 10,
                    transform: "rotate(90deg)",
                    position: "relative",
                    top: -2
                  }}
                />
              )
            }
            nextLabel={
              pageNo === totalPages ? null : (
                <img
                  alt="close"
                  src={require("../assets/images/dropdown@3x.png")}
                  style={{
                    width: 10,
                    transform: "rotate(-90deg)",
                    position: "relative",
                    top: -2
                  }}
                />
              )
            }
            forcePage={pageNo - 1}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages ? totalPages : 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </PaginationContainer>
      )}
    </SuppliersListContainer>
  );
}

export default SuppliersList;

const SuppliersListContainer = styled.div`
  overflow-y: auto;
  width: calc(100% - 320px);
  padding-left: 36px;
  background: #f9f9f9;
  border-left: 1px solid rgba(151, 151, 151, 0.15);
  @media (max-width: 685px) {
    width: 100%;
    padding: 0 16px;
  }
  @media (min-width: 685px) and (max-width: 1200px) {
    width: 70%;
    padding-left: 3%;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  padding: 20px 0;
  width: 71.2%;
  justify-content: space-between;
  p {
    font-family: "ProximaNova-Light";
    font-size: 1em;
  }
  @media (min-width: 685px) and (max-width: 1200px) {
    width: 96%;
  }
  @media (max-width: 685px) {
    width: 100%;
    flex-direction: column;
    .editorsPickText{
      margin-top: 20px;
      font-family: 'ProximaNova-SemiBold';
      color: #2f3b55;
    }
    p {
      padding-left: 5px;
      font-size: 14px;
    }
  }
`;
const SuppliersContainer = styled.div`
  overflow-y: scroll;
  width: 71.2%;
  @media (min-width: 685px) and (max-width: 1200px) {
    width: 96%;
  }
  @media (max-width: 685px) {
    width: 100%;
  }
`;
const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 685px) {
    justify-content: space-between;
    .react-switch {
    margin-top: 20px;
  }
  }
`;
const PaginationContainer = styled.div`
  width: 71.2%;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  @media (min-width: 685px) and (max-width: 950px) {
    width: 96%;
  }
  @media (max-width: 685px) {
    width: 100%;
    flex-direction: column;
  }
`;
const LoadingContainerLocal = styled.div`
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-family: "ProximaNova-SemiBold";
    font-size: 1.8em;
  }
`;
