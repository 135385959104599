import React from "react";

export const UserContext = React.createContext();

function UserProvider({ children }) {
  const [user, setUser] = React.useState(() => localStorage.getItem("user"));
  const [resetToken, setResetToken] = React.useState("");
  const [resetEmail, setresetEmail] = React.useState("");
  const value = React.useMemo(() => {
    return {
      user,
      setUser,
      resetToken,
      setResetToken,
      resetEmail, 
      setresetEmail      
    };
  }, [user, resetToken, resetEmail]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserProvider;
