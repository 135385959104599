import React from "react";
import styled from "styled-components";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
function MediumDeviceSlider() {
  const carousel = [
    "nike",
    "m&s",
    "patagonia",
    "adidas",
    "inditex",
    "h&m",
    "boss",
    "levis",
    "target",
    "pvh"
  ];
  return (
    <CarouselProvider
      isPlaying={true}
      naturalSlideWidth={100}
      naturalSlideHeight={125}
      totalSlides={3}
    >
      <StyledSlider>
        <StyledSlide index={0}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {carousel.slice(0, 4).map((data, index) => {
              return (
                <CarousalItem key={index}>
                  <img
                    alt={data}
                    src={require(`../../assets/images/${data}@3x.png`)}
                  />
                </CarousalItem>
              );
            })}
          </div>
        </StyledSlide>
        <StyledSlide index={1}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {carousel.slice(4, 8).map((data, index) => {
              return (
                <CarousalItem key={index}>
                  <img
                    alt={data}
                    src={require(`../../assets/images/${data}@3x.png`)}
                  />
                </CarousalItem>
              );
            })}
          </div>
        </StyledSlide>
        <StyledSlide index={3}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {carousel.slice(-2).map((data, index) => {
              return (
                <CarousalItem key={index}>
                  <img
                    alt={data}
                    src={require(`../../assets/images/${data}@3x.png`)}
                  />
                </CarousalItem>
              );
            })}
          </div>
        </StyledSlide>
      </StyledSlider>
    </CarouselProvider>
  );
}

export default MediumDeviceSlider;

const StyledSlider = styled(Slider)`
  width: 100vw;
  height: 300px;
`;

const StyledSlide = styled(Slide)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CarousalItem = styled.div`
  min-width: 160px;
  max-width: 160px;
  min-height: 160px;
  max-height: 160px;
  align-self: center;
  background: black;
  /* box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15); */
  border-radius: calc(160px / 2);
  display: flex;
  margin: 70px 20px;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  @media (max-width: 685px) {
    min-width: 96px;
    max-width: 96px;
    min-height: 96px;
    max-height: 96px;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9;
    }
  }
`;
