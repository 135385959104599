import React, { useState, useMemo } from "react";

export const SnackbarControllerContext = React.createContext();

const SnackbarProvider = props => {
  const [snackbarProps, setSnackbarProps] = useState({
    isOpen: false,
    variant: "",
    message: ""
  });
  const value = useMemo(() => {
    return {
      snackbarProps,
      setSnackbarProps
    };
  }, [snackbarProps]);
  return (
    <SnackbarControllerContext.Provider value={value}>
      {props.children}
    </SnackbarControllerContext.Provider>
  );
};

export default SnackbarProvider;
