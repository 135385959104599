import React from "react";
import styled from "styled-components";
import Header from "../home/header/Header";
import Footer from "../footer/Footer";

function TermsAndConditions() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainContainer className="mainContainer" style={{ background: " #f9f9f9" }}>
      <Header contact={true} main={true} />
      <TermsAndConditionsContainer>
        <Content>
          <h2 className="heading">Terms of service</h2>
          <p>
            These terms and conditions ("Terms", "Agreement") are an agreement
            between GoodList LLC ("GoodList LLC", "us", "we" or "our") and you
            ("User", "you" or "your"). This Agreement sets forth the general
            terms and conditions of your use of the goodlist.co website and any
            of its products or services (collectively, "Website" or "Services")
          </p>
        </Content>
        <Content>
          <h2 className="heading">Accounts and membership</h2>
          <p>
            If you create an account on the Website, you are responsible for
            maintaining the security of your account and you are fully
            responsible for all activities that occur under the account and any
            other actions taken in connection with it. We may monitor and review
            new accounts before you may sign in and use our Services. Providing
            false contact information of any kind may result in the termination
            of your account. You must immediately notify us of any unauthorized
            uses of your account or any other breaches of security. We will not
            be liable for any acts or omissions by you, including any damages of
            any kind incurred as a result of such acts or omissions. We may
            suspend, disable, or delete your account (or any part thereof) if we
            determine that you have violated any provision of this Agreement or
            that your conduct or content would tend to damage our reputation and
            goodwill. If we delete your account for the foregoing reasons, you
            may not re-register for our Services. We may block your email
            address and Internet protocol address to prevent further
            registration.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Backups</h2>
          <p>
            We are not responsible for Content residing on the Website. In no
            event shall we be held liable for any loss of any Content. It is
            your sole responsibility to maintain appropriate backup of your
            Content. Notwithstanding the foregoing, on some occasions and in
            certain circumstances, with absolutely no obligation, we may be able
            to restore some or all of your data that has been deleted as of a
            certain date and time when we may have backed up data for our own
            purposes. We make no guarantee that the data you need will be
            available.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Advertisements</h2>
          <p>
            During use of the Website, you may enter into correspondence with or
            participate in promotions of advertisers or sponsors showing their
            goods or services through the Website. Any such activity, and any
            terms, conditions, warranties or representations associated with
            such activity, is solely between you and the applicable third-party.
            We shall have no liability, obligation or responsibility for any
            such correspondence, purchase or promotion between you and any such
            third-party.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Links to other websites</h2>
          <p>
            Although this Website may link to other websites, we are not,
            directly or indirectly, implying any approval, association,
            sponsorship, endorsement, or affiliation with any linked website,
            unless specifically stated herein. Some of the links on the Website
            may be "affiliate links". This means if you click on the link and
            purchase an item, GoodList LLC will receive an affiliate commission.
            We are not responsible for examining or evaluating, and we do not
            warrant the offerings of, any businesses or individuals or the
            content of their websites. We do not assume any responsibility or
            liability for the actions, products, services, and content of any
            other third-parties. You should carefully review the legal
            statements and other conditions of use of any website which you
            access through a link from this Website. Your linking to any other
            off-site websites is at your own risk.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Prohibited uses</h2>
          <p>
            In addition to other terms as set forth in the Agreement, you are
            prohibited from using the Website or its Content: (a) for any
            unlawful purpose; (b) to solicit others to perform or participate in
            any unlawful acts; (c) to violate any international, federal,
            provincial or state regulations, rules, laws, or local ordinances;
            (d) to infringe upon or violate our intellectual property rights or
            the intellectual property rights of others; (e) to harass, abuse,
            insult, harm, defame, slander, disparage, intimidate, or
            discriminate based on gender, sexual orientation, religion,
            ethnicity, race, age, national origin, or disability; (f) to submit
            false or misleading information; (g) to upload or transmit viruses
            or any other type of malicious code that will or may be used in any
            way that will affect the functionality or operation of the Service
            or of any related website, other websites, or the Internet; (h) to
            collect or track the personal information of others; (i) to spam,
            phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene
            or immoral purpose; or (k) to interfere with or circumvent the
            security features of the Service or any related website, other
            websites, or the Internet. We reserve the right to terminate your
            use of the Service or any related website for violating any of the
            prohibited uses.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Intellectual property rights</h2>
          <p>
            This Agreement does not transfer to you any intellectual property
            owned by GoodList LLC or third-parties, and all rights, titles, and
            interests in and to such property will remain (as between the
            parties) solely with GoodList LLC. All trademarks, service marks,
            graphics and logos used in connection with our Website or Services,
            are trademarks or registered trademarks of GoodList LLC or GoodList
            LLC licensors. Other trademarks, service marks, graphics and logos
            used in connection with our Website or Services may be the
            trademarks of other third-parties. Your use of our Website and
            Services grants you no right or license to reproduce or otherwise
            use any GoodList LLC or third-party trademarks.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Disclaimer of warranty</h2>
          <p>
            You agree that your use of our Website or Services is solely at your
            own risk. You agree that such Service is provided on an "as is" and
            "as available" basis. We expressly disclaim all warranties of any
            kind, whether express or implied, including but not limited to the
            implied warranties of merchantability, fitness for a particular
            purpose and non-infringement. We make no warranty that the Services
            will meet your requirements, or that the Service will be
            uninterrupted, timely, secure, or error-free; nor do we make any
            warranty as to the results that may be obtained from the use of the
            Service or as to the accuracy or reliability of any information
            obtained through the Service or that defects in the Service will be
            corrected. You understand and agree that any material and/or data
            downloaded or otherwise obtained through the use of Service is done
            at your own discretion and risk and that you will be solely
            responsible for any damage to your computer system or loss of data
            that results from the download of such material and/or data. We make
            no warranty regarding any goods or services purchased or obtained
            through the Service or any transactions entered into through the
            Service. No advice or information, whether oral or written, obtained
            by you from us or through the Service shall create any warranty not
            expressly made herein.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Limitation of liability</h2>
          <p>
            To the fullest extent permitted by applicable law, in no event will
            GoodList LLC, its affiliates, officers, directors, employees,
            agents, suppliers or licensors be liable to any person for (a): any
            indirect, incidental, special, punitive, cover or consequential
            damages (including, without limitation, damages for lost profits,
            revenue, sales, goodwill, use of content, impact on business,
            business interruption, loss of anticipated savings, loss of business
            opportunity) however caused, under any theory of liability,
            including, without limitation, contract, tort, warranty, breach of
            statutory duty, negligence or otherwise, even if GoodList LLC has
            been advised as to the possibility of such damages or could have
            foreseen such damages. To the maximum extent permitted by applicable
            law, the aggregate liability of GoodList LLC and its affiliates,
            officers, employees, agents, suppliers and licensors, relating to
            the services will be limited to an amount greater of one dollar or
            any amounts actually paid in cash by you to GoodList LLC for the
            prior one month period prior to the first event or occurrence giving
            rise to such liability. The limitations and exclusions also apply if
            this remedy does not fully compensate you for any losses or fails of
            its essential purpose.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Indemnification</h2>
          <p>
            You agree to indemnify and hold GoodList LLC and its affiliates,
            directors, officers, employees, and agents harmless from and against
            any liabilities, losses, damages or costs, including reasonable
            attorneys' fees, incurred in connection with or arising from any
            third-party allegations, claims, actions, disputes, or demands
            asserted against any of them as a result of or relating to your
            Content, your use of the Website or Services or any willful
            misconduct on your part.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Severability</h2>
          <p>
            All rights and restrictions contained in this Agreement may be
            exercised and shall be applicable and binding only to the extent
            that they do not violate any applicable laws and are intended to be
            limited to the extent necessary so that they will not render this
            Agreement illegal, invalid or unenforceable. If any provision or
            portion of any provision of this Agreement shall be held to be
            illegal, invalid or unenforceable by a court of competent
            jurisdiction, it is the intention of the parties that the remaining
            provisions or portions thereof shall constitute their agreement with
            respect to the subject matter hereof, and all such remaining
            provisions or portions thereof shall remain in full force and
            effect.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Dispute resolution</h2>
          <p>
            The formation, interpretation, and performance of this Agreement and
            any disputes arising out of it shall be governed by the substantive
            and procedural laws of New York, United States without regard to its
            rules on conflicts or choice of law and, to the extent applicable,
            the laws of United States. The exclusive jurisdiction and venue for
            actions related to the subject matter hereof shall be the state and
            federal courts located in New York, United States, and you hereby
            submit to the personal jurisdiction of such courts. You hereby waive
            any right to a jury trial in any proceeding arising out of or
            related to this Agreement. The United Nations Convention on
            Contracts for the International Sale of Goods does not apply to this
            Agreement
          </p>
        </Content>
        <Content>
          <h2 className="heading">Assignment</h2>
          <p>
            You may not assign, resell, sub-license or otherwise transfer or
            delegate any of your rights or obligations hereunder, in whole or in
            part, without our prior written consent, which consent shall be at
            our own sole discretion and without obligation; any such assignment
            or transfer shall be null and void. We are free to assign any of its
            rights or obligations hereunder, in whole or in part, to any
            third-party as part of the sale of all or substantially all of its
            assets or stock or as part of a merger.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Changes and amendments</h2>
          <p>
            We reserve the right to modify this Agreement or its policies
            relating to the Website or Services at any time, effective upon
            posting of an updated version of this Agreement on the Website. When
            we do, we will revise the updated date at the bottom of this page.
            Continued use of the Website after any such changes shall constitute
            your consent to such changes.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Acceptance of these terms</h2>
          <p>
            You acknowledge that you have read this Agreement and agree to all
            its terms and conditions. By using the Website or its Services you
            agree to be bound by this Agreement. If you do not agree to abide by
            the terms of this Agreement, you are not authorized to use or access
            the Website and its Services.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Contacting us</h2>
          <p>
            If you would like to contact us to understand more about this
            Agreement or wish to contact us concerning any matter relating to
            it, you may send an email to info@goodlist.co
          </p>
          <p>This document was last updated on August 29, 2019</p>
        </Content>
      </TermsAndConditionsContainer>
      <Footer contact={true} />
    </MainContainer>
  );
}

export default TermsAndConditions;
const MainContainer = styled.div`
  min-height: 100vh;
  @media (min-width: 2400px) {
    height: 100vh;
    overflow: auto;
  }
`;

const TermsAndConditionsContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 80px;
  margin-left: 1.6em;
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 685px) {
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
  }
  @media (max-width: 1022px) {
    margin-left: 0em;
  }
`;

const Content = styled.div`
  margin: 1em 1em 1em 2em;
  .heading {
    font-family: "Helvetica";
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #172340;
    margin-bottom: 0.6em;
  }
  p {
    font-family: "ProximaNova-Regular";
    font-size: 14px;
    line-height: 22px;
  }
`;
