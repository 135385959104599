import React from "react";
import { Formik } from "formik";
import styled from "styled-components";
import AuthService from "../../services/AuthService";

import { SnackbarControllerContext } from "../../context/SnackbarControllerContext";
import {
  Form,
  Input,
  Label,
  FormButton,
  FormElement
} from "../../common/formStyles";
import { passwordResetValidationSchema } from "../../utils/validationSchemas";
import { UserContext } from "../../context/UserContext";
import { ModalContext } from "../../context/ModalContext";
import MediaQuery from "react-responsive";

const PasswordRestModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .resetForm {
    margin: 25px auto;
    @media (max-width: 685px) {
      width: 90%;
      padding: 0 10px;
    }
  }
  .forgot-password {
    cursor: pointer;
    width: 120px;
    height: 15px;
    font-weight: bold;
    font-family: MyriadPro;
    font-size: 14px;
    color: #000000;
    padding: 25px 0;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .form-error {
    font-family: "ProximaNova-Regular";
    padding: 5px 0;
  }
  .field-label {
    width: 36px;
    height: 17px;
    font-family: "ProximaNova-Bold";
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #000000;
  }
  .input-field {
    outline: 0;
    border: none;
    border-bottom: solid 0.4px #434ea0;
    font-family: "ProximaNova-Regular";
    font-size: 1em;
    padding-left: 0;
  }
  .signup-container {
    height: 15px;
    font-family: MyriadPro;
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    span {
      font-family: MyriadPro-Bold;
      cursor: pointer;
      margin-left: 5px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const HeaderCenter = styled.div`
  align-self: center;
  display: flex;
  align-items: flex-end;
  color: white;
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 50%;
  font-size: 20px;
  transform: translate(-50%, -50%);
  .logoBold {
    font-family: "Brown-Regular";
  }
  .logoRegular {
    font-family: "Brown-Light";
  }
  @media (max-width: 685px) {
    left: 62%;
    top: 40%;
  }
`;

const SvgContainer = styled.div`
  position: relative;
  height: 63px;
  width: 100%;
  overflow: hidden;
  .ellipse {
    position: absolute;
    left: 15%;
    transform: translateX(-50%);
    top: -27px;
    width: 359px;
    transform: rotate(-7deg);
  }
  .close {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
  }
  @media (min-width: 599px) and (max-width: 600px) {
    .logo {
      position: absolute;
      top: -16px;
      transform: translateX(-105%);
    }
  }
  @media (min-width: 299px) and (max-width: 320px) {
    .logo {
      position: absolute;
      top: -11px;
      transform: translateX(-50%);
      height: 29px;
    }
  }
  .close {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
    @media (max-width: 685px) {
      top: 0.75em;
      right: 1.25em;
    }
    @media (max-width: 320px) {
      top: 1.3em;
      right: 1.25em;
    }
  }
`;

function PasswordResetForm({ onClose }) {
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  const { setLoginModalOpen } = React.useContext(ModalContext);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { resetToken, resetEmail } = React.useContext(UserContext);
  const onSubmit = async (e, values) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const result = await AuthService.passwordReset(
        values,
        resetToken,
        resetEmail
      );
      if (result && result.status === 200) {
        setSnackbarProps({
          variant: "success",
          isOpen: true,
          message: result.response.message
        });
        setSubmitting(false);
        onClose();
        setLoginModalOpen(true);
      }
    } catch (error) {
      setSubmitting(false);
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.message && typeof error.message === "string"
            ? error.message
            : "Please try again later"
      });
    }
  };

  return (
    <PasswordRestModal>
      <SvgContainer>
        <HeaderCenter>
          <img
            src={require("../../assets/images/goodlist_logo4.svg")}
            style={{ width: "120px", cursor: "pointer" }}
            alt="goodlist"
            className="logo"
          />
        </HeaderCenter>
        <img
          src={require("../../assets/images/login.svg")}
          alt="goodlist"
          className="ellipse"
        />
        <MediaQuery query="(min-width: 659px)">
          <img
            src={require("../../assets/images/x@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 658px)">
          <img
            src={require("../../assets/images/close-white@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 600px)">
          <img
            src={require("../../assets/images/x@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 414px)">
          <img
            src={require("../../assets/images/close-white@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
      </SvgContainer>
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={passwordResetValidationSchema}
      >
        {({ handleChange, handleBlur, touched, errors, values, isValid }) => (
          <Form className="resetForm" onSubmit={e => onSubmit(e, values)}>
            <FormElement>
              <Label className="field-label">New Password</Label>
              <Input
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                name="password"
                value={values.password}
                className="input-field"
              />
              <div className="form-error">
                {touched.password && errors.password}
              </div>
            </FormElement>
            <FormElement>
              <Label className="field-label">Confirm New Password</Label>
              <Input
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                name="confirmPassword"
                value={values.confirmPassword}
                className="input-field"
              />
              <div className="form-error">
                {touched.confirmPassword && errors.confirmPassword}
              </div>
            </FormElement>
            <FormButton
              type="submit"
              disabled={isSubmitting || !isValid}
              style={{ opacity: (isSubmitting || !isValid) && 0.4 }}
            >
              Change Password
            </FormButton>
          </Form>
        )}
      </Formik>
    </PasswordRestModal>
  );
}

export default PasswordResetForm;
