const QueryParams = {
  setQueryParams(params) {
    window.history.pushState(
      {
        path: window.location.href.includes("?params")
          ? QueryParams.validateParams(0) + `?params=${JSON.stringify(params)}`
          : window.location.href + `?params=${JSON.stringify(params)}`
      },
      "",
      window.location.href.includes("?params")
        ? QueryParams.validateParams(0) + `?params=${JSON.stringify(params)}`
        : window.location.href + `?params=${JSON.stringify(params)}`
    );
  },
  clearFilters () {
    const url = QueryParams.validateParams(0);
    window.location.href = url;
  },
  validateParams(n) {
    const s = window.location.href;
    const result = s.split("?")[n];
    return result;
  },
  getQueryParams() {
    const params = QueryParams.validateParams(1);
    if (params.trim().length) {
      return "?" + params;
    } else {
      return null;
    }
  },

  refineQueryParams() {
    const params = QueryParams.validateParams(1);
    if (params && params.trim().length) {
      try {
        const replaced = params.replace("params=", "");
        const obj = JSON.parse(decodeURIComponent(replaced));
        return obj;
      } catch (err) {
        const url = QueryParams.validateParams(0);
        window.location.href = url;
      }
    } else {
      return null;
    }
  }
};

module.exports = {
  QueryParams
};
