import React from 'react'

function NotFound() {
    return (
        <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh"
        }}
      >
        <p style={{ fontSize: "2em", fontFamily: "ProximaNova-SemiBold" }}>
          Page not found.
        </p>
      </div>
    )
}

export default NotFound
