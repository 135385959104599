import React from "react";
import { Formik } from "formik";
import styled from "styled-components";
import { SnackbarControllerContext } from "../context/SnackbarControllerContext";
import {
  Form,
  Input,
  Label,
  FormButton,
  FormElement
} from "../common/formStyles";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/dist/style.css";
import { claimProfileValidationSchema } from "../utils/validationSchemas";
import MediaQuery from "react-responsive";
import GoodListService from "./GoodListService";
import { SupplierContext } from "../context/SupplierContext";
import { ModalContext } from "../context/ModalContext";

const ClaimProfileModal = styled.div`
  width: "100%";
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .claimProfileForm {
    margin: 25px auto;
    @media (max-width: 685px) {
      width: 90%;
      padding: 0 10px;
    }
  }
  .claimProfile-title {
    width: 100%;
    margin-top: 25px;
    .claimProfile {
      width: 200px;
      height: 34px;
      font-family: "ProximaNova-Bold";
      font-size: 28px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      text-align: left;
    }
    @media (max-width: 685px) {
      width: 100%;
    }
  }
  .form-error {
    font-family: "ProximaNova-Regular";
    padding: 5px 0;
  }
  .field-label {
    width: 36px;
    height: 17px;
    font-family: "ProximaNova-Bold";
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #000000;
  }
  .codeBtn {
    background: none !important;
    border: none !important;
    border-right: 1px solid rgba(179, 179, 179, 0.37) !important;
    width: 55px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .input-field {
    outline: 0;
    border: none;
    border-bottom: solid 0.4px rgba(67, 78, 160, 0.98);
    font-family: "ProximaNova-Regular";
    font-size: 1em;
    padding-left: 0;
  }
  .claimProfile-btn {
    all: unset;
    width: 100%;
    height: 62px;
    border-radius: 1px;
    background-color: #2f3b55;
    margin: 38px 0px 40px 0px;
    text-align: center;
    color: white;
    font-size: 1em;
    font-family: "ProximaNova-Bold";
    cursor: pointer;
    @media (max-width: 685px) {
      width: 100%;
    }
  }
`;

const HeaderCenter = styled.div`
  align-self: center;
  display: flex;
  align-items: flex-end;
  color: white;
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  .logoBold {
    font-family: "Brown-Regular";
  }
  .logoRegular {
    font-family: "Brown-Light";
  }
  @media (max-width: 685px) {
    left: 62%;
    top: 40%;
  }
`;

const SvgContainer = styled.div`
  position: relative;
  height: 63px;
  width: 100%;
  overflow: hidden;
  .ellipse {
    position: absolute;
    left: 15%;
    transform: translateX(-50%);
    top: -27px;
    width: 359px;
    transform: rotate(-7deg);
  }
  .close {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
  }
  @media (min-width: 599px) and (max-width: 600px) {
    .logo {
      position: absolute;
      top: -16px;
      transform: translateX(-105%);
    }
  }
  @media (min-width: 299px) and (max-width: 320px) {
    .logo {
      position: absolute;
      top: -11px;
      transform: translateX(-50%);
      height: 29px;
    }
  }
  .close {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
    @media (max-width: 685px) {
      top: 0.75em;
      right: 1.25em;
    }
    @media (max-width: 320px) {
      top: 1.3em;
      right: 1.25em;
    }
  }
`;

function ClaimProfileForm({ onClose }) {
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { supplier, setSupplier } = React.useContext(SupplierContext);
  const { setClaimProfileModalOpen } = React.useContext(ModalContext);
  const onSubmit = async (e, values) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const result = await GoodListService.claimSupplier(supplier.id, values);

      setSnackbarProps({
        variant: "success",
        isOpen: true,
        message:
          result.response.message && typeof result.response.message === "string"
            ? result.response.message
            : "Success"
      });
      setSupplier({ ...supplier, claimed_status: "claimed" });
      setClaimProfileModalOpen(false);
    } catch (error) {
      setSubmitting(false);
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.error.message && typeof error.error.message === "string"
            ? error.error.message
            : "Please try again later"
      });
    }
  };

  return (
    <ClaimProfileModal>
      <SvgContainer>
        <HeaderCenter>
          <img
            src={require("../assets/images/goodlist_logo4.svg")}
            style={{ width: "120px", cursor: "pointer" }}
            alt="goodlist"
            className="logo"
          />
        </HeaderCenter>
        <img
          src={require("../assets/images/login.svg")}
          alt="goodlist"
          className="ellipse"
        />
        <MediaQuery query="(min-width: 659px)">
          <img
            src={require("../assets/images/x@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 658px)">
          <img
            src={require("../assets/images/close-white@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 600px)">
          <img
            src={require("../assets/images/x@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 414px)">
          <img
            src={require("../assets/images/close-white@3x.png")}
            className="close"
            onClick={onClose}
            alt="close"
          />
        </MediaQuery>
      </SvgContainer>

      <Formik
        initialValues={{ email: "", phone: "" }}
        validationSchema={claimProfileValidationSchema}
      >
        {({
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          isValid,
          setFieldValue
        }) => (
          <Form
            className="claimProfileForm"
            onSubmit={e => onSubmit(e, values)}
          >
            <div className="claimProfile-title">
              <p className="claimProfile">Claim Profile</p>
            </div>
            <FormElement>
              <Label className="field-label">Email</Label>
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus
                value={values.email}
                className="input-field"
              />
              <div className="form-error">{touched.email && errors.email}</div>
            </FormElement>
            <FormElement>
              <Label className="field-label">Phone Number</Label>
              <ReactPhoneInput
                defaultCountry={"us"}
                containerStyle={{
                  marginTop: 10
                }}
                dropdownStyle={{ marginLeft: "100px" }}
                buttonClass="codeBtn"
                inputStyle={{
                  height: "40px",
                  paddingLeft: 68,
                  fontFamily: "ProximaNova-Regular",
                  width: "100%",
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid rgba(179, 179, 179, 0.37)"
                }}
                value={values.phone}
                inputExtraProps={{
                  name: "phone"
                }}
                onChange={phone => setFieldValue("phone", phone)}
                onBlur={handleBlur}
              />
              <div className="form-error">{touched.phone && errors.phone}</div>
            </FormElement>
            <FormButton
              type="submit"
              disabled={isSubmitting || !isValid}
              style={{ opacity: (isSubmitting || !isValid) && 0.4 }}
              className="claimProfile-btn"
            >
              Claim
            </FormButton>
          </Form>
        )}
      </Formik>
    </ClaimProfileModal>
  );
}

export default ClaimProfileForm;
