import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import Header from '../components/home/header/Header';
import {useDropzone} from 'react-dropzone';
import Footer from '../components/footer/Footer';
import Button from '../common/Button'; 
import CloseIcon from '@material-ui/icons/CloseRounded';
import { navigate } from "@reach/router";
import GoodListService from '../GoodlistComponents/GoodListService';
import { SnackbarControllerContext } from '../context/SnackbarControllerContext';
import { UserContext } from '../context/UserContext';

function UploadData() {
    const [files, setFiles] = useState([]);
    const [isSubmitting, setisSubmitting] = useState(false);
    const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
    const { user } = React.useContext(UserContext);
    useEffect(() => {
if(user && JSON.parse(user).is_admin)  {
return
}  else {
  navigate('/');
}
}, [user])
    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
      }, [files]);
    const {getRootProps, getInputProps,isDragActive,isDragAccept,isDragReject} = useDropzone({
        disabled: files.length? true : false,
        accept : 'text/csv',
      onDrop: (acceptedFiles) => {
          
        setFiles(acceptedFiles.map(file => Object.assign(file, {review: URL.createObjectURL(file)})));
      }
    });
    const onUpload = async () => {
      setisSubmitting(true);
        let data = new FormData();
  data.append('data_to_upload',files[0]);
  try {
    const result = await GoodListService.uploadData(data);
    handleSuccess(result);
  } catch (error) {
    handleError(error);
  }
    }
    const handleSuccess = result => {
      setSnackbarProps({
        isOpen: true,
        variant: "success",
        message: result.response.message
      });
      setFiles([]);
      navigate('/');
    };
    const handleError = error => {
      setSnackbarProps({
        isOpen: true,
        variant: "error",
        message:
          error && typeof error === "string"
            ? error
            : error.message && typeof error.message === "string"
            ? error.message
            : "Please try again later"
      });
    };
    const thumbs = files.map(file => (
       <div key={file.name} style={thumbCon} >
        <div style={thumb} >
          <div style={thumbInner}>
         {isSubmitting? null : <CloseIcon
            style={{
              position: "absolute",
              color: "white",
              cursor: "pointer",
              background: 'red',
              borderRadius: '50%',
              zIndex: 99,
              fontSize: 16,
            }}
            onClick={() => setFiles([])}
         /> }
            <img
              src={require('../assets/images/csv.png')}
              style={img}
            />
          </div>
        </div>
        <p style={{fontFamily: 'ProximaNova-Regular', color: 'black'}}>{file.name}</p>
        </div>
      ));
    return (
   <UploadContainer>
<Header main="true" />
{user && JSON.parse(user).is_admin ? <div className="container">
      <Container style={{cursor: files.length ? 'default' : 'pointer'}} {...getRootProps({isDragActive, isDragAccept, isDragReject})} >
        <input id="input_type" {...getInputProps()} />
       {files.length ?   <aside style={thumbsContainer}>
        {thumbs}
      </aside> : <p>Drag 'n' drop .csv file here, or click to select .csv file</p>} 
      {files.length ? <>
       <Button      
       fiterBtn={true}
        backgroundColorProp={
          isSubmitting ? `rgba(47, 59, 85, 0.5)` : null
        }
        cursor={isSubmitting ? "not-allowed" : null}
         buttonClick={() => onUpload()} btnText={isSubmitting ? 'Uploading..' : 'Upload'} width='100px' height="40px" /></> : null}
      </Container>
    </div> : null}
    <Footer contact={true} />
   </UploadContainer>
 
    )
}
const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
  }
  
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    width: 60%;
    height: 40%;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: white;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    @media (max-width: 685px) {
        width: 90%;
    }
    p{
        font-family: 'ProximaNova-Bold'
    }
  `;
const UploadContainer = styled.div`
.container {
    min-height: 80vh;
display: flex;
align-items: center;
justify-content: center;
}
`;
const thumbCon = {
width: 200,
height: 200,
display: 'flex',
flexDirection: 'column',
alignItems: 'center',
justifyContent: 'center',
}
const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };
export default UploadData
 