import React from "react";
import styled from "styled-components";
// import PersonsContainer from "./PersonsContainer";
import EditorsPick from "./EditorsPick";
import GoodListService from "../../GoodlistComponents/GoodListService";
import LoaderContainer from "../../common/LoaderContainer";
import { SnackbarControllerContext } from "../../context/SnackbarControllerContext";
function TopPersons() {
  const [editors, setEditors] = React.useState(null);
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  React.useEffect(() => {
    const handleError = error => {
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.message && typeof error.message === "string"
            ? error.message
            : "Please try again later"
      });
    };
    async function getEditorsPick() {
      try {
        const result = await GoodListService.getEditorsPick();
        handleSuccess(result);
      } catch (error) {
        handleError(error);
      }
    }
    getEditorsPick();
    return () => {
      setEditors([]);
    };
  }, []);
  const handleSuccess = result => {
    setEditors(result.response.editors_picks);
  };
  return (
    <TopPersonsContainer id="topPersons">
      <Heading>
        <p>Advance your sustainability goals.</p>
      </Heading>
      <Paragraph>
        Connect with suppliers applying environmental and social responsibility
        into daily operations.
      </Paragraph>
      {editors ? (
        editors.length ? (
          editors.map((data, index) => {
            return <EditorsPick key={index} data={data} />;
          })
        ) : (
          <LoaderContainer loader={false} message={"Yet to be added."} />
        )
      ) : (
        <LoaderContainer message={"Loading"} />
      )}
    </TopPersonsContainer>
  );
}

export default TopPersons;

const TopPersonsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 140px 0;
  @media (max-width: 685px) {
    padding: 70px 0;
  }
`;
const Heading = styled.div`
  max-width: 896px;
  p {
    padding: 0 60px;
    font-family: "ProximaNova-Bold";
    font-size: 1.875em;
    text-align: center;
  }
  @media (max-width: 568px) {
    p {
      font-size: 20px;
      text-align: left;
      padding: 0 30px;
    }
  }
`;
const Paragraph = styled.p`
  width: 50%;
  font-family: "ProximaNova-Regular";
  color: rgba(0, 0, 0, 0.51);
  font-size: 1.125em;
  line-height: 1.44;
  max-width: 971px;
  text-align: center;
  padding: 0 90px;
  padding-top: 33px;
  padding-bottom: 43px;
  @media (max-width: 568px) {
    font-size: 18px;
    text-align: left;
    padding: 20px 30px;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
  @media (max-width: 685px) {
    margin-bottom: 76px;
    width: 100%;
  }
`;
