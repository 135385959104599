import React from "react";
import styled from "styled-components";
import CopyRight from "../copyRight/CopyRight";
import InternalNavigation from "../../common/InternalNavigation";
import { navigate } from "@reach/router";
function Footer(props) {
  const navigateTo = name => {
    props.contact
      ? navigate("/", { state: { name } })
      : InternalNavigation.navigateAnim(name);
  };
  return (
    <FooterMain {...props}>
      <img
        className="ellipseBottom"
        src={require("../../assets/images/ellipse.svg")}
        alt="ellipse"
      />
      <FooterContainer>
        <Left>
          <FirstColumn>
            <Row>
              <p onClick={() => navigate("contact")}>Contact Us</p>
              <p onClick={() => navigate("about_us")}>About Us</p>
              <p onClick={() => navigate("suppliers")}>Suppliers</p>
            </Row>

            <Row>
              <p onClick={() => navigate("termsofservice")}>Terms of Service</p>
              <p onClick={() => navigate("privacypolicy")}>Privacy Policy</p>
            </Row>
          </FirstColumn>
          <Extras>
            {/* <p onClick={() => navigate("contact")}>Help & Support</p> */}
            <a
              href="https://www.instagram.com/goodlist/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.facebook.com/goodlist.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://www.twitter.com/@goodlistco/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
            <a
              href="https://www.linkedin.com/company/goodlist/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </Extras>
        </Left>

        <Right>
          <img
            className="logoImg"
            onClick={() => navigateTo("#top")}
            alt="whiteLogo"
            src={require("../../assets/images/goodlist_logo4.svg")}
          />
        </Right>
      </FooterContainer>

      <CopyRight />
    </FooterMain>
  );
}

export default Footer;
const FooterMain = styled.div`
  background: white;
  width: 100%;
  position: relative;
  overflow: hidden;
  .ellipseBottom {
    z-index: 1;
    width: 800px;
    position: absolute;
    bottom: -300px;
    right: -300px;
    transform: rotate(18deg);
  }
  @media (min-width: 440px) and (max-width: 685px) {
    .ellipseBottom {
      width: 600px !important;
      z-index: 1;
      position: absolute;
      bottom: -200px;
      right: 0px !important;
      transform: rotate(10deg);
    }
  }
  @media (min-width: 686px) and (max-width: 900px) {
    .ellipseBottom {
      z-index: 1;
      width: 700px;
      position: absolute;
      top: -50px;
    }
  }
  @media (max-width: 685px) {
    .ellipseBottom {
      z-index: 1;
      width: 550px;
      position: absolute;
      bottom: -200px;
      right: -60px;
      transform: rotate(10deg);
    }
  }
`;
const FooterContainer = styled.div`
  position: relative;
  z-index: 993;
  background: transparent;
  width: 100%;
  display: flex;
  padding: 59px 0;
  @media (max-width: 685px) {
    flex-direction: row;
  }
`;
const FirstColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 450px;
  @media (max-width: 685px) {
    width: 300px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 50%;
  /* background: red; */
  p {
    cursor: pointer;
    font-family: "ProximaNova-Regular";
    font-size: 1em;
    padding-bottom: 10px;
    &:hover {
      font-family: "ProximaNova-SemiBold";
    }
  }
  @media (max-width: 685px) {
    p {
      font-size: 9.6px;
      color: white;
    }
  }
`;
const Left = styled.div`
  width: 75%;
  padding-left: 65px;
  @media (max-width: 685px) {
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const Right = styled.div`
  display: flex;
  width: 20%;
  align-items: flex-end;
  justify-content: center;
  .logoImg {
    width: 150px;
    margin-left: 15%;
    /* position: absolute; */
  }
  @media (min-width: 1600px) {
    .logoImg {
    width: 150px;
    margin-left: 50%;
  }
  }
  @media (max-width: 685px) {
    display: none;
  }
`;
const Extras = styled.div`
  display: flex;
  margin-top: 30px;
  p,
  a {
    font-family: "ProximaNova-Regular";
    color: #000000;
    padding-right: 10px;
    opacity: 0.57;
    font-size: 0.875em;
    cursor: pointer;
    &:hover {
      font-family: "ProximaNova-SemiBold";
    }
  }
  a {
    text-decoration-line: none;
  }
  @media (max-width: 685px) {
    align-self: center;
    p {
      font-size: 8.4px;
      color: white;
    }
  }
`;
