import React, { useState, useMemo } from "react";

export const FullpageLoaderContext = React.createContext();

function FullpageLoaderProvider({ children }) {
  const [fullPageLoader, setFullPageLoader] = useState({
    isOpen: false
  });
  const value = useMemo(() => {
    return {
      fullPageLoader,
      setFullPageLoader
    };
  }, [fullPageLoader]);

  return (
    <FullpageLoaderContext.Provider value={value}>
      {children}
    </FullpageLoaderContext.Provider>
  );
}

export default FullpageLoaderProvider;
