export const Configs = {
  api: {
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1`,
    auth: {
      login: "auth/login",
      signup: "/auth/sign_up",
      logout: "/auth/logout",
      forgotPassword: "/auth/forgot_password",
      validateResetCode: "/auth/validate_password_code",
      passwordReset: "/auth/reset_password",
      contact: "/contacts"
    },
    routes: {
      listProducts: "/suppliers",
      filters: "/suppliers/filter_options",
      editors_pick: "/editors_picks",
      get_details: "/suppliers",
      get_bookmarks: "/suppliers/bookmarks",
      upload_data: "/data/upload"
    }
  }
};
