import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import Button from "../common/Button";
import Cross from "@material-ui/icons/CancelRounded";
import { FilterModalControllerContext } from "../context/FilterModalControllerContext";
import { QueryParams } from "../managers/QueryParamsManager";
import { FilterCountContext } from "../context/FilterCountContext";
function Filter(props) {
  const { filtersForCount, setFiltersForCount } = useContext(
    FilterCountContext
  );
  const [filterCount, setFilterCount] = useState();
  useEffect(() => {
    const localFilters = QueryParams.refineQueryParams();
    if (localFilters && localFilters.supplierName) {
      setSearch(localFilters.supplierName);
    }
  }, []);
  useEffect(() => {
    let filtersAvailable = [];
    if (!filterCount) {
      const localFilters = QueryParams.refineQueryParams();
      setFiltersForCount(localFilters);
    }
    for (let key in filtersForCount) {
      if (
        filtersForCount[key] &&
        key.replace(/\s/g, "").toLowerCase() !== "editorspick" &&
        key.replace(/\s/g, "").toLowerCase() !== "pageno" &&
        key.replace(/\s/g, "").toLowerCase() !== "suppliername" &&
        filtersForCount[key].value !== "default"
      ) {
        filtersAvailable.push(filtersForCount[key]);
      }
    }
    setFilterCount(filtersAvailable.length);
  }, [filtersForCount]);
  const { setFilterModalProps } = React.useContext(
    FilterModalControllerContext
  );

  const [search, setSearch] = React.useState();

  const handleChange = $event => {
    setSearch($event.target.value);
  };
  const handleButtonClick = () => {
    const localFilters = QueryParams.refineQueryParams();
    const params = { ...localFilters, supplierName: search, pageNo: 1 };
    QueryParams.setQueryParams(params);
    props.applyingFilter(true);
    props.getProducts(params);
  };
  const clear = () => {
    setSearch("");
    const localFilters = QueryParams.refineQueryParams();
    const params = { ...localFilters, supplierName: null, pageNo: 1 };
    let refineParams = {};
    for (let key in params) {
      if (params[key]) {
        refineParams = { ...refineParams, [key]: params[key] };
      }
    }
    QueryParams.setQueryParams(refineParams);
    props.applyingFilter(true);
    props.getProducts(params);
  };
  return (
    <Main>
      <FilterContainerMain>
        <Input
          disabled={
            filtersForCount &&
            filtersForCount.supplierName &&
            filtersForCount.supplierName.length
              ? true
              : false
          }
          onChange={$event => handleChange($event)}
          name="search"
          value={
            filtersForCount && filtersForCount.supplierName
              ? filtersForCount.supplierName
              : search
              ? search
              : ""
          }
          placeholder={"Search for a supplier"}
        />
        <img
          className="inputImg"
          src={require("../assets/images/search@3x.png")}
          alt="ellipse"
        />

        <div className="crossContainer">
          {filtersForCount &&
          filtersForCount.supplierName &&
          filtersForCount.supplierName.length > 0 ? (
            <Cross
              className="cross"
              style={{
                position: "absolute",
                color: "#2f3a55",
                cursor: "pointer"
              }}
              onClick={() => clear()}
            />
          ) : null}
        </div>

        <FilterButtonContainer
          onClick={() =>
            setFilterModalProps({
              isOpen: window.innerWidth < 685 ? true : false
            })
          }
        >
          <img
            className="filtersImg"
            src={require("../assets/images/sliders@3x.png")}
            alt="ellipse"
          />
          <p>Filters</p>{" "}
          {filterCount > 0 ? (
            <>
              <div
                style={{
                  background: "#2f3b55",
                  padding: 3,
                  width: 15,
                  height: 15,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 5,
                  borderRadius: 2
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontFamily: "ProximaNova-SemiBold",
                    fontSize: 8
                  }}
                >
                  {filterCount}
                </div>
              </div>
            </>
          ) : null}
        </FilterButtonContainer>
        <Button
          buttonClick={() =>
            search && search.trim().length > 0 ? handleButtonClick() : null
          }
          width={"110px"}
          height={"46px"}
          btnText={"GO"}
          fiterBtn={true}
          backgroundColorProp={
            search && search.trim().length > 0 ? null : `rgba(47, 59, 85, 0.5)`
          }
          cursor={search && search.trim().length > 0 ? null : "not-allowed"}
        />
      </FilterContainerMain>
      <div className="mobile">
        {filterCount > 0 ? (
          <p
            style={{ fontFamily: "ProximaNova-Regular", fontSize: 12, float: 'right' }}
            onClick={() => QueryParams.clearFilters()}
          >
            Clear All Filters
          </p>
        ) : null}
      </div>
    </Main>
  );
}

export default Filter;
const Main = styled.div`
  .mobile {
    display: none;
    @media (max-width: 685px) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
    }
  }
`;
const FilterContainerMain = styled.div`
  height: 100px;
  width: 100vw;
  border-bottom: 1px solid rgba(151, 151, 151, 0.15);
  display: flex;
  align-items: center;
  padding: 0 60px;
  background: #f9f9f9;
  .crossContainer {
    width: 60px;
    height: 46.4px;
    background: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 685px) {
      width: 40px;
    }
    .cross {
      @media (max-width: 685px) {
        font-size: 12px;
      }
    }
  }
  .inputImg {
    position: absolute;
    left: 80px;
    width: 15px;
    height: 15px;
    @media (max-width: 1060px) {
      left: 50px;
    }
    @media (max-width: 685px) {
      left: 30px;
      width: 10px;
      height: 10px;
    }
  }
  @media (max-width: 1060px) {
    padding: 0 30px;
  }
  @media (max-width: 685px) {
    border-bottom: none;
    padding: 0 16px;
  }
`;
const FilterButtonContainer = styled.div`
  display: none;
  align-items: center;
  width: 112px;
  height: 46.4px;
  background: white;
  border-left: 0.3px solid rgba(243, 243, 243, 1);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    font-family: "ProximaNova-Light";
    font-size: 0.875em;
    padding-left: 9px;
  }
  .filtersImg {
    width: 18px;
    height: 15px;
  }
  @media (max-width: 685px) {
    display: flex;
    font-size: 12px;
  }
`;
const Input = styled.input`
  position: relative;
  width: 40%;
  font-family: "ProximaNova-Regular";
  padding-left: 45px;
  font-size: 1em;
  background-color: white;
  height: 46.4px;
  border: none;
  box-shadow: 0px 0px 30px 0 rgba(0, 0, 0, 0.03);
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  &::placeholder {
    opacity: 0.5;
    @media (max-width: 685px) {
      font-size: 10px;
    }
  }
  &:focus {
    outline: none !important;
  }

  @media (min-width: 685px) and (max-width: 950px) {
    width: 60%;
  }
  @media (max-width: 685px) {
    width: 50%;
    font-size: 12px;
    padding-left: 38px;
  }
`;
