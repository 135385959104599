import React from "react";

export const ScrollPositionContext = React.createContext();

function ScrollPositionProvider({ children }) {
  const [scrollPos, setScrollPos] = React.useState();
  const value = React.useMemo(() => {
    return {
      scrollPos,
      setScrollPos
    };
  }, [scrollPos, setScrollPos]);

  return (
    <ScrollPositionContext.Provider value={value}>
      {children}
    </ScrollPositionContext.Provider>
  );
}

export default ScrollPositionProvider;
