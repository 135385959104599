import request from "../managers/ApiManager";
import { Configs } from "../utils/Configs";
import { QueryParams } from '../managers/QueryParamsManager';
const listProducts = (fparams, token) => {
  const params = getFinalParams(fparams);
  return request(
    {
      url:
        Configs.api.routes.listProducts +
        `?page=${params && params.pageno ? params.pageno : 1}${
          params && params.suppliername
            ? `&supplier_name=${params.suppliername.replace("&", "%26")}`
            : ""
        }${
          params && params.editorspick
            ? `&editors_pick=${params.editorspick}`
            : ""
        }${params && params.numberofworkers
          ? `&workers_count_range=${params.numberofworkers.replace("&", "%26")}`
          : ""}${getMultiFilters(params)}`,
      method: "GET"
    },
    true,
    token
  );
};
const getFinalParams = params => {
  let result = {};
  for (let key in params) {
    if (
      typeof params[key] === "string" ||
      typeof params[key] === "number" ||
      typeof params[key] === "boolean" ||
      Array.isArray(params[key]) ||
      !params[key]
    ) {
      result = {
        ...result,
        [key]: params[key]
      };
    } else if (params[key].value === "default") {
        result = {
          ...result,
          [key]: null
        };
    } else {
      result = {
        ...result,
        [key]: params[key].value
      };
    }
  }

  return result;
};
const getMultiFilters = params => {
  let finalParams = "";
  if (params.associatedbrands && params.associatedbrands.length) {
    params.associatedbrands.forEach(data => {
      finalParams = `${finalParams}&brands[]=${data.label.replace("&", "%26")}`;
    });
  }
  if (params.factorylocation && params.factorylocation.length) {
    params.factorylocation.forEach(data => {
      finalParams = `${finalParams}&locations[]=${data.label.replace(
        "&",
        "%26"
      )}`;
    });
  }
  if (params.productcategory && params.productcategory.length) {
    params.productcategory.forEach(data => {
      finalParams = `${finalParams}&categories[]=${data.label.replace("&", "%26")}`;
    });
  }
  if (params.unsustainabilitygoals && params.unsustainabilitygoals.length) {
    params.unsustainabilitygoals.forEach(data => {
      finalParams = `${finalParams}&sdgs[]=${data.label.replace("&", "%26")}`;
    });
  }
  if (params.esgcertification && params.esgcertification.length) {
    params.esgcertification.forEach(data => {
      finalParams = `${finalParams}&certifications[]=${data.label.replace("&", "%26")}`;
    });
  }
  if (params.impactfocus && params.impactfocus.length) {
    params.impactfocus.forEach(data => {
      finalParams = `${finalParams}&${data.label.replace("&", "%26") === 'Environmentally Responsible' ? 'is_environmentally_responsible=true' : data.label.replace("&", "%26") === 'Socially Responsible' ? 'is_socially_responsible=true' : ''}`;
    });
  }
  return finalParams;
};
const getFilters = () => {
  return request({
    url: Configs.api.routes.filters,
    method: "GET"
  });
};
const getEditorsPick = () => {
  return request({
    url: Configs.api.routes.editors_pick,
    method: "GET"
  });
};
const getDetails = (id, token) => {
  return request(
    {
      url: `${Configs.api.routes.get_details}/${id}`,
      method: "GET"
    },
    true,
    token
  );
};
const getBookmarks = () => {
  return request(
    {
      url: Configs.api.routes.get_bookmarks,
      method: "GET"
    },
    true
  );
};
const bookmarkSupplier = (id, flag) => {
  return request(
    {
      url: `${Configs.api.routes.get_details}/${id}/bookmark`,
      method: "POST",
      data: {
        bookmark: flag
      }
    },
    true
  );
};
const uploadData = (file) => {
  return request(
    {
      url: `${Configs.api.routes.upload_data}`,
      method: "POST",
      data: file
    },
    true,
    null,
    'multipart/form-data'
  );
};
const claimSupplier = (id, values) => {
  return request(
    {
      url: `${Configs.api.routes.get_details}/${id}/claim`,
      method: "POST",
      data: {
        email: values.email,
        phone: values.phone
      }
    },
    true
  );
};
const getLocations = val => {
  const filters = QueryParams.refineQueryParams();
  let resultParams = {};
  for (let key in filters) {
    resultParams = {
      ...resultParams,
      [key.replace(/\s/g, "").toLowerCase()]: filters[key]
    };
  }
  const params = getFinalParams(resultParams);
  return request({
    url: Configs.api.routes.get_details + `/locations?query=${val}${
      params && params.editorspick
        ? `&editors_pick=${params.editorspick}`
        : ""
    }${params && params.numberofworkers
      ? `&workers_count_range=${params.numberofworkers.replace("&", "%26")}`
      : ""}${getMultiFilters(params)}`,
    method: "GET"
  });
};
const contact = values => {
  return request(
    {
      url: Configs.api.auth.contact,
      method: "POST",
      data: {
        full_name: values.fullName,
        email: values.email,
        contact: values.contactNumber,
        description: values.reason,
        user_type: values.type
      }
    },
    true
  );
};
const GoodListService = {
  listProducts,
  getFilters,
  getEditorsPick,
  getBookmarks,
  getDetails,
  bookmarkSupplier,
  claimSupplier,
  getLocations,
  contact,
  uploadData
};
export default GoodListService;
