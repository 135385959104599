import React, { useEffect } from "react";
import styled from "styled-components";
import { ThemeProvider } from "@material-ui/styles";
import {
  makeStyles,
  withStyles,
  createMuiTheme
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import KeyboardArrowDown from "@material-ui/icons/ArrowDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import AutosuggestInput from "../common/AutosuggestInput";
const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "#2f3a55",
          fontFamily: "ProximaNova-Bold"
        }
      }
    }
  }
});
const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2)
    }
  },
  input: {
    border: "none",
    position: "relative",
    paddingBottom: 12,
    fontSize: 14,
    borderBottom: "0.3px solid #b3b3b3",
    textOverflow: "ellipsis",
    padding: "10px 0 6px 0",
    transition: theme.transitions.create(["border-color"]),
    "&:focus": {
      borderBottomColor: "#2f3a55",
      borderBottomWidth: 2,
      background: "none"
    }
  }
}))(InputBase);
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 !important",
    padding: "0 !important",

    input: {
      borderColor: "red"
    }
  },
  checkbox: {
    "& .MuiFormControlLabel-label	": {
      fontFamily: "ProximaNova-Regular",
      fontSize: "1em",
      color: "#606060"
    }
  },
  formControl: {
    width: "100%"
  },
  selectEmpty: {}
}));
function FilterMain(props) {
  const [values, setValues] = React.useState({});
  useEffect(() => {
    let dropdown = {};
    for (let key in props.selected) {
      dropdown = {
        ...dropdown,
        [key]:
          Array.isArray(props.selected[key]) || !props.selected[key]
            ? props.selected[key]
            : props.selected[key].label
      };
    }
    setValues(oldValues => ({
      ...oldValues,
      ...dropdown
    }));
  }, [props.selected]);
  const classes = useStyles();
  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
    const select = {
      label: event.target.value,
      value: event.target.value === "" ? "default" : event.target.value,
    };
    if(event.target.value === ""){
      props.onSelect(select, event.target.name, true);
    } else {
      props.onSelect(select, event.target.name, false);
    }
    
  }
  return (
    <FilterMainContainer>
      {props.filterTypes.map((data, index) => {
        return (
          <React.Fragment key={index}>
            <FilterType>
              {data.type === "dropdown" ? (
                <>
                <div style={{height: '20px',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></div>
                <FormControl className={classes.formControl}>
                  <ThemeProvider theme={theme}>
                    <InputLabel htmlFor="age-helper" className="inputLabel">
                      {data.name}
                    </InputLabel>
                  </ThemeProvider>
                  <Select
                    IconComponent={() => (
                      <KeyboardArrowDown
                        style={{
                          color: "#979797",
                          fontS: 4,
                          pointerEvents: "none",
                          position: "absolute",
                          right: -8,
                          width: 30,
                          background: "white"
                        }}
                      />
                    )}
                    value={values[data.name] ? values[data.name] : ""}
                    onChange={handleChange}
                    input={<BootstrapInput name={data.name} id="age-helper" />}
                  >
                    {data.options.map((option, index) => {
                      return option.label === "Number of Workers" 
                        ? (
                          props.selected[`${option.label}`] ? 
                        <MenuItem
                          style={{
                            fontFamily: "ProximaNova-SemiBold",
                            fontSize: 12
                          }}
                          key={index}
                          value={""}
                        >
                          - Remove filter -
                        </MenuItem> : null
                      ) : (
                        <MenuItem
                          style={{
                            fontFamily: "ProximaNova-SemiBold",
                            fontSize: 12
                          }}
                          disabled={!option.type}
                          key={index}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                </>
              ) : data.type === "input" ? (
                <>
                <div style={{height: '20px',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></div>
                <AutosuggestInput
                  label={data.name}
                  options={data.options}
                  selectedValuesFromLocal={
                    values[data.name] ? values[data.name] : []
                  }
                  handleChangeMulti={(name, select, isApiCall) =>
                    props.onSelect(select, name, isApiCall)
                  }
                />
                </>
              ) : null}
            </FilterType>
          </React.Fragment>
        );
      })}
      {/* <p>Coming Soon..</p> */}
    </FilterMainContainer>
  );
}

export default FilterMain;
const FilterMainContainer = styled.div`
  padding: 0 24px;
  padding-bottom: 43px;
  width: 241px;
  height: 100%;
  background: white;
  margin-bottom: 33px;
  div {
    font-family: "ProximaNova-SemiBold";
  }
  .autocomplete-dropdown-container {
    width: 100%;
    background: white;
    margin-top: 15px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
  }
  .suggestion-item {
    padding: 10px 10px;
  }
  .suggestion-item--active {
    padding: 10px 10px;
  }
  .inputLabel {
    font-family: "ProximaNova-Regular";
    font-size: 0.92em;
    color: #606060;
    &:active {
      color: #d980fa;
    }
  }

  @media (max-width: 685px) {
    padding: 3px 10px;
    width: 100%;
    height: 100%;
  }
  @media (min-width: 686px) and (max-width: 900px) {
    padding: 0 20px;
    width: 180px;
  }
`;

const FilterType = styled.div`
  font-size: 0.875em;
  padding: 8px 0;
  input {
    position: relative;
    height: 33px;
    width: 100%;
    border: none;
    border-bottom: solid 1px #b3b3b3;
    font-family: "ProximaNova-Regular";
    color: #606060;
    font-size: 0.875em;
    padding-bottom: 10px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-family: "ProximaNova-Regular";
      color: #606060;
      font-size: 1.001em;
    }
  }

  img {
    position: absolute;
    width: 12px;
    height: 12px;
  }
`;
