import React from "react";
import styled from "styled-components";
function DetailsCertificationCard(props) {
  // const certification = [
  //   {
  //     name: "Generic Certificate for Ethical Meat Production",
  //     year: "2011",
  //     decription:
  //       "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequatDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sun"
  //   },
  //   {
  //     name: "Generic Certificate for Ethical Meat Production",
  //     year: "2009",
  //     decription:
  //       "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequatDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sun"
  //   }
  // ];
  const getUrlFixed = url => {
    const urlFixed =
      url.substring(0, 7) === "http://" || url.substring(0, 8) === "https://"
        ? url
        : "http://" + url;

    return urlFixed;
  };
  return (
    <>
      {props.factory &&
      props.factory.certifications &&
      props.factory.certifications.length ? (
        <CertificationCardContainer>
          <SubHeading>Certifications</SubHeading>
          {props.factory.certifications.map((data, index) => {
            return (
              <CerticationContainer key={index}>
                <Name>{data.name ? data.name : null}</Name>

                <Year>
                  {data.initial_certification_date
                    ? data.initial_certification_date
                    : null}
                </Year>
                {data.last_certification_date ? (
                  <>
                    <LastCertifiDate>
                      Latest Re-certification Date
                    </LastCertifiDate>
                    <Year>{data.last_certification_date}</Year>
                  </>
                ) : null}

                <Paragraph>
                  {data.description ? data.description : null}
                </Paragraph>
                {data.body ? <BodyName>{data.body}</BodyName> : null}
                <a
                  className="urlForWebsite"
                  href={getUrlFixed(data.website)}
                  target="_blank"
                >
                  <InfoAction key={index}>
                    <img
                      src={require(`../assets/images/globe.svg`)}
                      alt="info"
                    />

                    <p>
                      {data.website.length > 20
                        ? data.website.slice(0, 20) + "..."
                        : data.website}
                    </p>
                  </InfoAction>
                </a>
              </CerticationContainer>
            );
          })}
        </CertificationCardContainer>
      ) : null}
    </>
  );
}

export default DetailsCertificationCard;

const CertificationCardContainer = styled.div`
  border-top: solid 0.3px rgba(151, 151, 151, 0.98);
  margin-top: 20px;
  background: white;
  width: 100%;
  padding-top: 20px;
  @media (max-width: 685px) {
    padding-top: 20px;
  }
`;
const CerticationContainer = styled.div`
  padding: 37px 0;
  border-bottom: solid 0.3px rgba(151, 151, 151, 0.98);
  &:last-child {
    border-bottom: none;
  }
  .urlForWebsite {
    color: black;
    text-decoration: none;
    display: inline-block;
    &:hover {
      color: #0000ee;
    }
  }
`;
const Name = styled.p`
  font-family: "ProximaNova-SemiBold";
  font-size: 1.25em;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
const LastCertifiDate = styled.p`
  font-family: "ProximaNova-SemiBold";
  font-size: 0.85em;
  padding-top: 8px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
const BodyName = styled.p`
  font-family: "ProximaNova-SemiBold";
  font-size: 0.95em;
  padding: 14px 0;
`;
const Year = styled.p`
  font-family: "ProximaNova-Regular";
  color: #8a8a8a;
  font-size: 0.8em;
  padding: 16px 0;
`;
const Paragraph = styled.p`
  font-family: "ProximaNova-Regular";
  font-size: 1em;
  line-height: 1.5;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
const SubHeading = styled.div`
  font-family: "ProximaNova-SemiBold";
  color: #313e7e;
  font-size: 1.125em;
`;

const InfoAction = styled.div`
  min-width: 244px;
  max-width: 280px;
  padding: 0 45px;
  min-height: 60px;
  margin-top: 20px;
  margin-right: 10px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
  }
  p {
    font-family: "ProximaNova-Regular";
    font-size: 1em;
    padding-left: 12px;
  }
`;
