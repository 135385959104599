import React from "react";
import styled from "styled-components";
import Dropdown from "react-dropdown";
function DetailsProductPreviewCard(props) {
  // const wear = [
  //   {
  //     name: "Winter Wear",
  //     type: [
  //       { value: "Sweaters", className: "myOptionClassName" },
  //       { value: "Jackets", className: "myOptionClassName" },
  //       { value: "Hoodies", className: "myOptionClassName" }
  //     ]
  //   },
  //   {
  //     name: "Active Wear",
  //     type: [
  //       { value: "Sweaters", className: "myOptionClassName" },
  //       { value: "Jackets", className: "myOptionClassName" },
  //       { value: "Hoodies", className: "myOptionClassName" }
  //     ]
  //   },
  //   {
  //     name: "Inner Wear",
  //     type: [
  //       { value: "Sweaters", className: "myOptionClassName" },
  //       { value: "Jackets", className: "myOptionClassName" },
  //       { value: "Hoodies", className: "myOptionClassName" }
  //     ]
  //   }
  // ];
  // const getAvailableProducts = () => {
  //   const products = [];
  //   props.details.factories &&
  //     props.details.factories.length &&
  //     props.details.factories.forEach(data => {
  //       products.push(...data.factory_products);
  //     });
  //   console.log(products);
  //   return products;
  // };
  const getPopularProducts = products => {
    const pop = products.filter(product => {
      return product.product.is_popular;
    });
    return pop;
  };
  return props.factory &&
    props.factory.factory_products &&
    props.factory.factory_products.length ? (
    <DetailsProductContainer>
      {getPopularProducts(props.factory.factory_products).length ? (
        <SubHeading>Popular Products</SubHeading>
      ) : null}
      {getPopularProducts(props.factory.factory_products).length ? (
        <ProductsContainer>
          {getPopularProducts(props.factory.factory_products).map(
            (data, index) => {
              return (
                <ProductImageContainer key={index}>
                  <ProductImage>
                    <img
                      src={
                        data.product && data.product.logo_url
                          ? data.product.logo_url
                          : require("../assets/images/Placeholder-logo.png")
                      }
                      alt="goodlist"
                    />
                  </ProductImage>
                  <ProductTitle>
                    {data.product && data.product.category}
                  </ProductTitle>
                </ProductImageContainer>
              );
            }
          )}
        </ProductsContainer>
      ) : null}
      {props.factory.factory_products.length ? (
        <SubHeading>Products Available</SubHeading>
      ) : null}
      {props.factory.factory_products.length
        ? props.factory.factory_products.map((product, index) => {
            return (
              <DropdownStyled
                key={index}
                placeholderClassName="placeholderClass"
                menuClassName="myMenuClassName"
                arrowClassName="myArrowClassName"
                className="dropDown"
                controlClassName="myControlClassName"
                options={[]}
                disabled={true}
                placeholder={product.product && product.product.category}
                arrowClosed={
                  product.product && product.product.types ? (
                    <span className="arrow-closed">
                      <img
                        src={require("../assets/images/dropdown@3x.png")}
                        alt="dropdown"
                      />
                    </span>
                  ) : null
                }
                arrowOpen={
                  product.product && product.product.types ? (
                    <span className="arrow-open">
                      <img
                        src={require("../assets/images/dropdown@3x.png")}
                        alt="dropdown"
                      />
                    </span>
                  ) : null
                }
              />
            );
          })
        : null}
    </DetailsProductContainer>
  ) : null;
}

export default DetailsProductPreviewCard;
const DetailsProductContainer = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  background: white;
  width: 100%;
`;
const SubHeading = styled.div`
  font-family: "ProximaNova-SemiBold";
  color: #313e7e;
  font-size: 1.125em;
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;
const ProductsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 16px;
  margin-bottom: 26px;
`;
const ProductImageContainer = styled.div``;
const ProductImage = styled.div`
  position: relative;
  width: 138px;
  height: 131px;
  background: #f9f9f9;
  margin-right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: co;
  }
`;
const ProductTitle = styled.div`
  font-size: 1em;
  font-family: "ProximaNova-Regular";
  padding-top: 9px;
`;
const DropdownStyled = styled(Dropdown)`
  .myMenuClassName {
    min-width: 100px;
    width: 120px;
    margin-left: 60px;
    padding-left: 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90px;
    border-left: solid 1px #ececec;
  }
  .placeholderClass {
    font-family: "ProximaNova-Regular";
    font-size: 1.125em;
  }
  .myControlClassName {
    margin: 23px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 120px;
    max-width: 180px;
    /* justify-content: center; */
  }
  .myOptionClassName {
    font-family: "ProximaNova-Regular";
    font-size: 1.125em;
  }
  .arrow-open {
    img {
      width: 8px;
      transform: rotate(180deg);
    }
  }
  .arrow-closed {
    img {
      width: 8px;
    }
  }
`;
