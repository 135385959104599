import React from "react";
import styled from "styled-components";

function FullpageLoader() {
  return (
    <LoaderContainer>
      <p>Just a moment</p>
      <img alt="loader" src={require("../../src/assets/images/loader.gif")} />
    </LoaderContainer>
  );
}

export default FullpageLoader;

const LoaderContainer = styled.div`
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  p {
    font-family: "ProximaNova-SemiBold";
    font-size: 2em;
    @media (max-width: 685px) {
display: none;
    }
  }
  img {
    margin: 10px 0;
    width: 60px;
    height: 60px;
    @media (max-width: 685px) {
      width: 40px;
    height: 40px;
    }
  }
`;
