import React from "react";

export const NavigateContext = React.createContext();

function NavigateProvider({ children }) {
  const [navigateAnim, setNavigateAnim] = React.useState(null);
  const value = React.useMemo(() => {
    return {
      navigateAnim,
      setNavigateAnim
    };
  }, [navigateAnim, setNavigateAnim]);

  return (
    <NavigateContext.Provider value={value}>
      {children}
    </NavigateContext.Provider>
  );
}

export default NavigateProvider;
