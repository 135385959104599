import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Header from "../components/home/header/Header";
import SupplierCard from "./SupplierCard";
import { UserContext } from "../context/UserContext";
import { ModalContext } from "../context/ModalContext";
import Footer from "../components/footer/Footer";
import GoodListService from "./GoodListService";
import FullpageLoader from "../common/FullpageLoader";
import { SnackbarControllerContext } from "../context/SnackbarControllerContext";
import LoaderContainer from "../common/LoaderContainer";
function BookmarkedSuppliers() {
  const [bookmarks, setBookmarks] = useState(null);
  const { user } = React.useContext(UserContext);
  const { setSnackbarProps } = useContext(SnackbarControllerContext);
  const { setLoginModalOpen } = useContext(ModalContext);
  const [bookmarkLoader, setBookmarkLoader] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleError = error => {
      setSnackbarProps({
        isOpen: true,
        variant: "error",
        message:
          error && typeof error === "string"
            ? error
            : error.message && typeof error.message === "string"
            ? error.message
            : "Please try again later"
      });
    };
    async function getBookmarks() {
      try {
        const bookmarks = await GoodListService.getBookmarks();
        handleSuccess(bookmarks);
      } catch (error) {
        handleError(error);
      }
    }
    if (user) {
      getBookmarks();
    } else {
      setLoginModalOpen({
        isOpen: true
      });
    }
  }, []);
  const bookmarkSupplier = async id => {
    setBookmarkLoader({
      id: id,
      flag: true
    });
    try {
      const result = await GoodListService.bookmarkSupplier(id, false);
      if (result && result.status === 200) {
        handleBookmarkSuccess(result, id, false);
      }
    } catch (error) {
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.error.message && typeof error.error.message === "string"
            ? error.error.message
            : "Please try again later"
      });
      setBookmarkLoader({
        id: id,
        flag: false
      });
    }
  };
  const handleBookmarkSuccess = (result, id, flag) => {
    const suppliers = bookmarks.filter(data => {
      return data.id !== id;
    });
    setBookmarks(suppliers);
    setSnackbarProps({
      isOpen: true,
      variant: "info",
      message: "Bookmark Removed"
    });
    setBookmarkLoader({
      id: id,
      flag: false
    });
  };
  const handleSuccess = bookmarks => {
    setBookmarks(bookmarks.response.suppliers);
  };
  return (
    <div>
      <Header main={true} />

      <MainContainer>
        {/* <BackToButton onClick={() => window.history.back()}>
          <img src={require("../assets/images/arrow-right@3x.png")} />{" "}
          <p>Back to Results</p>
        </BackToButton> */}
        {user ? (
          <>
            {bookmarks && bookmarks.length ? (
              <p className="bookmarksCount" style={{ marginTop: "32px" }}>
                Bookmarked Suppliers ({bookmarks && bookmarks.length})
              </p>
            ) : null}
            <SupplierCardContainer>
              {bookmarks ? (
                bookmarks.length ? (
                  bookmarks.map((data, index) => {
                    return (
                      <SupplierCard
                        key={index}
                        bookmarkLoader={bookmarkLoader}
                        bookmarkComponent={true}
                        onBookmark={id => bookmarkSupplier(id)}
                        producer={data}
                        component="editorsPick"
                      />
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "60vh",
                      width: "100%",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <LoaderContainer
                      message="Bookmarks list is empty.."
                      loader={false}
                    />
                  </div>
                )
              ) : (
                <div
                  style={{
                    height: "60vh",
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <FullpageLoader />
                </div>
              )}
            </SupplierCardContainer>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(80vh - 100px)"
            }}
          >
            <p style={{ fontSize: "2em", fontFamily: "ProximaNova-SemiBold" }}>
              Login is required.
            </p>
          </div>
        )}
      </MainContainer>

      <Footer contact={true} />
    </div>
  );
}
const MainContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  padding: 40px 0;
  padding-left: 68px;
  .bookmarksCount {
    font-family: "ProximaNova-Bold";
    margin-top: 32px;
  }
  @media (min-width: 685px) and (max-width: 920px) {
    padding: 40px 34px;
  }
  @media (max-width: 685px) {
    padding-left: 0;
    .bookmarksCount {
      padding-left: 22px;
      margin-top: 32px;
    }
  }
`;

const SupplierCardContainer = styled.div`
  max-width: 812px;
  padding: 32px 0;
  @media (min-width: 685px) and (max-width: 920px) {
    width: 98%;
  }
`;
export default BookmarkedSuppliers;
