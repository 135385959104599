import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { ScrollPosition } from "../managers/StoreManager";
import MediaQuery from "react-responsive";
import { UserContext } from "../context/UserContext";
import { ModalContext } from "../context/ModalContext";
function SupplierCard(props) {
  const { user } = React.useContext(UserContext);
  const { setLoginModalOpen } = React.useContext(ModalContext);
  const onViewDetailsClick = (id, readmore) => {
    ScrollPosition.setSuppliersList(props.scrollPosition);
    if (readmore) {
      navigate(`details/${id}`, {
        state: {
          readmore: readmore,
          component: props.component ? props.component : null
        }
      });
    } else {
      navigate(`details/${id}`, {
        state: {
          readmore: false,
          component: props.component ? props.component : null
        }
      });
    }
  };
  const onBookmarkClick = (id, flag) => {
    if (user) {
      props.onBookmark(id, flag ? false : true);
    } else {
      setLoginModalOpen(true);
    }
  };
  const products = [
    // { name: "Generic Product" },
    // { name: "Cool Product" },
    // { name: "Rare Product" },
    // { name: "Expensive Product" },
    // { name: "Expensive Product" },
    // { name: "Expensive Product" },
    // { name: "Expensive Product" }
  ];
  return (
    <SupplierCardContainer>
      <Left>
        <MobileFLex>
          <LogoContainer
          >
            {props.producer && props.producer.logo_url ? (
              <img
                src={props.producer.logo_url}
                alt="goodlist"
              />
            ) : (
              <img
                src={require("../assets/images/Placeholder-logo.png")}
                alt="goodlist"
              />
            )}
          </LogoContainer>
          <DetailsMobile>
            <Name>
              {props.producer && props.producer.name
                ? props.producer.name
                : "Supplier"}
            </Name>
            <Info>
              <City>
                {props.producer && props.producer.city
                  ? props.producer.city + ","
                  : null}{" "}
                {props.producer && props.producer.country
                  ? props.producer.country
                  : null}
              </City>
              <Count>
                {props.producer && props.producer.workers_count
                  ? props.producer.workers_count + " Employees"
                  : null}
              </Count>
            </Info>
          </DetailsMobile>
        </MobileFLex>

        <Action onClick={() => onViewDetailsClick(props.producer.id)}>
          <p>View Profile</p>
        </Action>
      </Left>
      <Right>
        <HeadingConatiner>
          <Heading>
            {props.producer && props.producer.about ? "About" : null}
          </Heading>
          <img
            style={{
              cursor:
                props.bookmarkLoader && props.bookmarkLoader.flag
                  ? "not-allowed"
                  : "pointer"
            }}
            onClick={() =>
              props.bookmarkLoader && props.bookmarkLoader.flag
                ? null
                : onBookmarkClick(
                    props.producer.id,
                    props.producer.is_bookmarked
                  )
            }
            src={
              props.bookmarkLoader &&
              props.bookmarkLoader.flag &&
              props.bookmarkLoader.id === props.producer.id
                ? require("../assets/images/loader.gif")
                : (props.producer && props.producer.is_bookmarked === true) ||
                  props.bookmarkComponent
                ? require("../assets/images/bookmarked@3x.png")
                : require("../assets/images/bookmark@3x.png")
            }
            alt="bookmark"
          />
        </HeadingConatiner>
        <MediaQuery query="(max-width: 658px)">
          {props.producer && props.producer.about ? (
            props.producer.about.length > 120 ? (
              <Paragraph>
                {props.producer.about.substring(0, 120)}{" "}
                <span
                  onClick={() => onViewDetailsClick(props.producer.id, true)}
                >
                  {" "}
                  ...Read more
                </span>
              </Paragraph>
            ) : (
              <Paragraph>{props.producer && props.producer.about}</Paragraph>
            )
          ) : null}
        </MediaQuery>
        <MediaQuery query="(min-width: 658px)">
          {props.producer && props.producer.about ? (
            props.producer.about.length > 200 ? (
              <Paragraph>
                {props.producer && props.producer.about.substring(0, 200)}{" "}
                <span
                  onClick={() => onViewDetailsClick(props.producer.id, true)}
                >
                  {" "}
                  ...Read more
                </span>
              </Paragraph>
            ) : (
              <Paragraph>{props.producer && props.producer.about}</Paragraph>
            )
          ) : null}
        </MediaQuery>
        {props.producer &&
        props.producer.categories &&
        props.producer.categories.length &&
        props.producer.categories[0] !== null ? (
          <SubHeading>Product Range</SubHeading>
        ) : null}
        {props.producer &&
        props.producer.categories &&
        props.producer.categories.length
          ? props.producer.categories.map((data, index) => {
              return <Paragraph key={index}>{data}</Paragraph>;
            })
          : null}
        {products.length ? <SubHeading>Popular Products</SubHeading> : null}
        <ProductsContainer>
          {products.map((data, index) => {
            return (
              <ProductImageContainer key={index}>
                <ProductImage>
                  <img
                    src={require("../assets/images/Placeholder-logo.png")}
                    alt="goodlist"
                  />
                </ProductImage>
                <ProductTitle>{data.name}</ProductTitle>
              </ProductImageContainer>
            );
          })}
        </ProductsContainer>
      </Right>
    </SupplierCardContainer>
  );
}

export default SupplierCard;
const SupplierCardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 24px;
  background: white;
  border: 1px solid rgba(151, 151, 151, 0.15);

  @media (max-width: 685px) {
    width: 100%;
    flex-direction: column;
  }
`;
const Left = styled.div`
  position: relative;
  width: 28.2%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  border-right: 1px solid rgba(151, 151, 151, 0.15);
  @media (min-width: 685px) and (max-width: 950px) {
    padding: 24px 15px;
  }
  @media (max-width: 685px) {
    border-right: none;
    width: 100%;
    padding: 24px 12px;
    padding-top: 12px;
    /* flex-direction: row; */
  }
`;
const MobileFLex = styled.div`
  @media (max-width: 685px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;
const DetailsMobile = styled.div`
  @media (max-width: 685px) {
    flex-direction: column;
    padding-left: 12px;
  }
`;
const Right = styled.div`
  width: 71.8%;
  height: 100%;
  padding: 24px 36px;

  @media (max-width: 685px) {
    width: 100%;
    padding: 14px 14px;
    border-left: none;
  }
`;
const HeadingConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 19px;
    cursor: pointer;
  }
`;

const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 142px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 685px) {
    width: 80px;
    height: 68px;
  }
  @media (min-width: 1600px) and (max-width: 1900px) {
height: 200px
  }
  @media (min-width: 1900px) and (max-width: 2299px) {
height: 240px
  }
  @media (min-width: 2300px) {
    height: 320px;
  }
  @media (min-width: 686px) and (max-width: 1024px) {
    width: 100%;
    height: 100px;
  }
`;
const Name = styled.div`
  font-family: "ProximaNova-SemiBold";
  padding-top: 21px;
  padding-bottom: 14px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (max-width: 685px) {
    font-size: 14px;
    padding: 0;
  }
`;
const Info = styled.div``;
const City = styled.div`
  font-family: "ProximaNova-Regular";
  color: #8a8a8a;
  @media (max-width: 685px) {
    font-size: 12px;
    padding-top: 8px;
  }
`;
const Count = styled.div`
  font-family: "ProximaNova-Regular";
  color: #8a8a8a;
  padding-top: 7px;
  padding-bottom: 17px;
  @media (max-width: 685px) {
    font-size: 12px;
    padding: 0;
    padding-top: 1px;
  }
`;
const Action = styled.div`
  cursor: pointer;
  width: 100%;
  background: white;
  border: 1px solid #2f3b55;
  padding: 10px;
  transition: 0.3s;
  &:hover {
    background: #2f3b55;
    box-shadow: 0 5px 10px rgba(47, 59, 85, 0.2);
    p {
      color: white;
    }
  }
  &:active {
    box-shadow: 0 2px 5px rgba(47, 59, 85, 0.2);
  }
  p {
    font-family: "ProximaNova-Regular";
    color: #2f3b55;
    text-align: center;
    font-size: 1em;
  }

  @media (max-width: 685px) {
    width: 100%;
    position: relative !important;
    left: 0;
    transform: translateX(0);
    bottom: 0;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    p {
      font-size: 11px;
    }
  }
`;
const Heading = styled.div`
  font-family: "ProximaNova-SemiBold";
  font-size: 1em;
  @media (max-width: 685px) {
    font-size: 16px;
  }
`;
const Paragraph = styled.div`
  padding-top: 8px;
  font-family: "ProximaNova-Regular";
  font-size: 1em;
  line-height: 1.63;
  color: rgba(0, 0, 0, 0.51);
  span {
    padding-top: 21px;
    font-family: "ProximaNova-SemiBold";
    color: #2f3b55;
    cursor: pointer;
  }
  @media (max-width: 685px) {
    font-size: 13px;
  }
`;
const SubHeading = styled.div`
  padding-top: 18px;
  font-family: "ProximaNova-SemiBold";
  color: #313e7e;
  font-size: 0.787em;
  @media (max-width: 685px) {
    font-size: 12px;
  }
`;
const ProductsContainer = styled.div`
  margin-top: 13px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
`;
const ProductImageContainer = styled.div``;
const ProductImage = styled.div`
  position: relative;
  width: 97px;
  height: 92px;
  background: #f9f9f9;
  margin-right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: co;
  }
`;
const ProductTitle = styled.div`
  font-size: 0.7em;
  font-family: "ProximaNova-Regular";
  padding-top: 9px;
  @media (max-width: 685px) {
    font-size: 11.2px;
  }
`;
