import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./header/Header";
import Button from "../../common/Button";
import MainImage from "../../assets/images/mainimage2.jpg";
import { navigate } from "@reach/router";
import { UserAuthentication } from '../../managers/StoreManager';
function Home(props) {
  const [pageY, setPageY] = useState();
  useEffect(() => {
    window.addEventListener('scroll' , () => {
      setPageY(window.pageYOffset);
    })
    return () => {
      window.removeEventListener('scroll' , () => {
        setPageY(window.pageYOffset);
      })
    }
  }, []);
  return (
    <HomeContainer
      id="top"
      style={{
        background: `url(${MainImage}) no-repeat center`,
        backgroundSize: "cover",
        position: "relative"
      }}
    >
      <div
        style={{
          zIndex: 0,
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.75)"
        }}
      ></div>
      <Header pageY={pageY} home={true} />
      <Main style={{ zIndex: 1, position: "relative" }}>
        <Left>
          <Heading>Fashion's most trusted suppliers.</Heading>
          <Paragraph>
            Discover producers at the forefront of sustainability and behind
            today’s leading brands.
          </Paragraph>
          <Button
            home={true}
            backgroundColorProp={"#da83fa"}
            buttonClick={() => navigate("/suppliers")}
            width={"250px"}
            btnText={"Browse Suppliers"}
            height={"55px"}
          />
        </Left>
        {/* <Right>
          <img
            src={require("../../assets/images/ellipse.svg")}
            alt="ellipse"
            className="parent"
          />
          <img
            src={require("../../assets/images/ellipsewithImg.png")}
            alt="ellipse"
            className="child"
          />
        </Right> */}
      </Main>
    </HomeContainer>
  );
}

export default Home;
const HomeContainer = styled.div`
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`;
const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 40px);
  padding-left: 60px;
  @media (max-width: 685px) {
    flex-direction: column-reverse;
    padding: 0 30px;
    min-height: calc(100vh - 100px);
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;

  justify-content: center;
  @media (max-width: 1024px) {
    flex: 0.8;
  }
  @media (min-width: 1800px) {
    flex: 1;
    align-items: center;
  }
  @media (max-width: 685px) {
    flex: 1;
  }
`;
// const Right = styled.div`
//   position: relative;
//   flex: 0.5;
//   @media (max-width: 685px) {
//     flex: 0.5;
//   }
//   .parent {
//     width: 120%;
//     position: absolute;
//     top: -20%;
//     left: 40%;
//     transform: translate(-35%, -70%) rotate(90deg);
//     opacity: 0.16;
//     @media (max-width: 685px) {
//       width: 550px !important;
//       left: 50%;
//       top: 0%;
//       transform: translate(-46%, -50%) rotate(90deg);
//     }
//     @media (min-width: 686px) and (max-width: 1000px) {
//       width: 680px !important;
//     }
//     @media (max-width: 1500px) {
//       width: 130%;
//     }
//     @media (max-width: 1280px) {
//       width: 143%;
//     }
//     @media (min-width: 1600px) {
//       width: 120%;
//       transform: translate(-20%, -72%) rotate(90deg);
//     }
//   }
//   .child {
//     z-index: 1;
//     width: 80%;
//     position: absolute;
//     top: 50%;
//     right: -20px;
//     transform: translateY(-45%) rotate(180deg);
//     @media (max-width: 685px) {
//       width: 282px;
//       top: 0;
//       left: 50%;
//       transform: translate(-50%, 0%) rotate(180deg);
//     }
//     @media (min-width: 1600px) {
//       width: 65%;
//     }
//     @media (min-width: 2000px) {
//       width: 50%;
//     }
//   }
// `;
const Heading = styled.div`
  font-family: "ProximaNova-Bold";
  font-size: 3em;
  text-align: left;
  color: rgb(207, 207, 207);
  @media (max-width: 568px) {
    font-size: 1.7em;
    text-align: left;
  }
`;
const Paragraph = styled.p`
  font-family: "ProximaNova-Regular";
  font-size: 1.2em;
  line-height: 1.44;
  max-width: 971px;
  text-align: left;
  color: rgb(207, 207, 207);
  padding-top: 29px;
  padding-bottom: 37px;
  @media (max-width: 568px) {
    font-size: 15px;
    text-align: left;
  }
`;
