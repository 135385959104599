import React, { useState, useMemo } from "react";

export const FilterModalControllerContext = React.createContext();

const FilterModalProvider = props => {
  const [filterModalProps, setFilterModalProps] = useState({
    isOpen: false
  });
  const value = useMemo(() => {
    return {
      filterModalProps,
      setFilterModalProps
    };
  }, [filterModalProps]);
  return (
    <FilterModalControllerContext.Provider value={value}>
      {props.children}
    </FilterModalControllerContext.Provider>
  );
};

export default FilterModalProvider;
