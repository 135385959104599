import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
function EditorsPick(props) {
  return (
    <>
      {props.data && props.data ? (
        <EditorsPickCard>
          <SvgContainer>
            {props.data.is_environment_friendly ? (
              <>
                <img
                  className="ellipseImage"
                  src={require("../../assets/images/greenEllipse.svg")}
                  alt="ellipse"
                />
                <img
                  className="iconImage"
                  src={require("../../assets/images/tree-leaf.svg")}
                  alt="editorspick"
                />
              </>
            ) : props.data.is_socially_responsible ? (
              <>
                <img
                  className="ellipseImage"
                  src={require("../../assets/images/orangeEllipse.svg")}
                  alt="ellipse"
                />
                <img
                  className="iconImage"
                  src={require("../../assets/images/handshake.svg")}
                  alt="editorspick"
                />
              </>
            ) : null}
          </SvgContainer>
          <Main>
            {/* <Left> */}
            <Oval>
              {props.data.image_url ? (
                <img src={props.data.image_url} alt="editorspick" />
              ) : (
                <img
                  src={require("../../assets/images/Placeholder-logo.png")}
                  alt="editorspick"
                />
              )}
            </Oval>
            {/* </Left> */}
            <Right>
              <HeadingContainer>
                <NameContainer>
                  <Heading>{props.data ? props.data.name : null}</Heading>
                  <EditorsBadge>EDITOR'S PICK</EditorsBadge>
                </NameContainer>
                {/* <ActDesContainer> */}
                {/* <ActionDesktop
                    onClick={() =>
                      navigate("details", {
                        state: {
                          id: props.data.id,
                          readmore: false,
                          component: "editorsPick"
                        }
                      })
                    }
                  >
                    <p>
                      View Profile{" "}
                      <img
                        src={require("../../assets/images/arrow-right@3x.png")}
                        alt="editorspick"
                      />
                    </p>
                  </ActionDesktop> */}
                {/* </ActDesContainer> */}
              </HeadingContainer>
              {props.data.about.length > 150 ? (
                <Paragraph>
                  {props.data.about.substring(0, 150)}{" "}
                  <span
                    onClick={() =>
                      navigate(`details/${props.data.id}`, {
                        state: {
                          readmore: true,
                          component: "editorsPick"
                        }
                      })
                    }
                  >
                    {" "}
                    ...Read more
                  </span>
                </Paragraph>
              ) : (
                <Paragraph>{props.data.about}</Paragraph>
              )}
              <ActionMobile
                onClick={() =>
                  navigate(`details/${props.data.id}`, {
                    state: {
                      readmore: false,
                      component: "editorsPick"
                    }
                  })
                }
              >
                <p>
                  View Profile{" "}
                  <img
                    src={require("../../assets/images/arrow-right@3x.png")}
                    alt="editorspick"
                  />
                </p>
              </ActionMobile>
            </Right>
          </Main>
        </EditorsPickCard>
      ) : null}
    </>
  );
}

export default EditorsPick;
const EditorsPickCard = styled.div`
  margin-top: 33px;
  padding-bottom: 59px;
  background: white;
  min-height: 308px;
  width: 848px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
  @media (min-width: 685px) and (max-width: 900px) {
    width: 80%;
    padding-bottom: 40px;
  }
  @media (max-width: 685px) {
    margin-top: 23px;
    width: 95%;
    align-self: flex-end;
    min-height: 626px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
  }
`;
const SvgContainer = styled.div`
  position: relative;
  height: 77px;
  width: 100%;
  overflow: hidden;
  .ellipseImage {
    position: absolute;
    z-index: 3;
    bottom: -5px;
    left: -10px;
    width: 280px;
    transform: rotate(190deg);
  }
  .iconImage {
    position: absolute;
    width: 27px;
    margin-left: 78px;
    margin-top: 26px;
    z-index: 5;
  }
`;
const Main = styled.div`
  padding: 0 66px;
  margin-top: 42px;
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  @media (min-width: 685px) and (max-width: 900px) {
    padding: 0 33px;
  }
  @media (max-width: 685px) {
    flex-direction: column;
    padding: 0 33px;
  }
`;
// const Left = styled.div`
//   min-width: 234px;
//   height: 100%;
// `;
const Oval = styled.div`
  min-width: 138px;
  max-width: 138px;
  min-height: 138px;
  max-height: 138px;
  position: relative;
  border-radius: calc(138px / 2);
  /* background: #f8f8f8; */
  background: #f6f6f6;
  img {
    position: absolute;
    border-radius: calc(138px / 2);
    min-width: 138px;
    max-width: 138px;
    min-height: 138px;
    max-height: 138px;
    object-fit: cover;
  }
`;
const Right = styled.div`
  margin-left: 38px;
  @media (max-width: 685px) {
    margin-left: 0;
  }
`;
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 685px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;
const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: 685px) {
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;
// const ActDesContainer = styled.div`
//   width: 10%;
// `;
const Heading = styled.div`
  font-size: 1.75em;
  width: 80%;
  font-family: "ProximaNova-Bold";
  @media (max-width: 685px) {
    width: 100%;
    font-size: 20px;
  }
`;
const EditorsBadge = styled.div`
  width: 20%;
  background-color: #63c2a3;
  color: white;
  font-family: "ProximaNova-SemiBold";
  font-size: 0.625em;
  margin: 0 12px;
  max-width: 93px;
  text-align: center;
  padding: 2px 5px;
  @media (max-width: 685px) {
    width: 100%;
    margin: 16px 0;
  }
`;

const ActionMobile = styled.div`
  display: flex;
  cursor: pointer;
  background: #f8f8f8;
  align-items: center;
  justify-content: space-evenly;
  max-width: 120px;
  height: 32px;
  padding: 0 14px;
  margin-top: 32px;
  img {
    margin-left: 2px;
    width: 10px;
  }
  p {
    font-size: 14px;
    font-family: "ProximaNova-Regular";
    color: #0070f5;
  }
  @media (max-width: 685px) {
    display: flex;
  }
`;
const Paragraph = styled.div`
  margin-top: 18px;
  font-size: 1.125em;
  line-height: 1.44;
  font-family: "ProximaNova-Light";
  span {
    font-family: "ProximaNova-SemiBold";
    color: #2f3b55;
    cursor: pointer;
  }
`;
