import React from "react";

export const FilterCountContext = React.createContext();

function FilterCountProvider({ children }) {
  const [filtersForCount, setFiltersForCount] = React.useState();
  const value = React.useMemo(() => {
    return {
      filtersForCount,
      setFiltersForCount
    };
  }, [filtersForCount, setFiltersForCount]);

  return (
    <FilterCountContext.Provider value={value}>
      {children}
    </FilterCountContext.Provider>
  );
}

export default FilterCountProvider;
