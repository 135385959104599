import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  margin: 0 auto;
`;

const FormElement = styled.div`
  width: 380px;
  margin-top: 28px;
  position: relative;
  .form-error {
    position: absolute;
    top: 100%;
    color: red;
    margin-left: 2px;
    margin-top: 2px;
    font-size: 12px;
  }
  @media (max-width: 685px) {
    width: 100%;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  font-size: 1em;
  font-family: "ProximaNova-Regular";
  border: solid 0.5px #434ea0;
  padding-left: 5px;
`;
const Label = styled.label`
  align-self: flex-start;
  text-align: left;
  width: 126px;
  height: 15px;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-weight: 300;
  color: #000000;
`;

const FormButton = styled.button`
  all: unset;
  width: 100%;
  height: 62px;
  border-radius: 1px;
  background-color: #2f3b55;
  margin: 38px 0;
  text-align: center;
  color: white;
  -webkit-text-fill-color:white;
  font-size: 1em;
  font-family: "ProximaNova-Bold";
  cursor: pointer;
`;

const Close = styled.div`
  position: absolute;
  right: 5px;
  z-index: 100;
  background: blue;
  top: ${props => (props.signUp ? 0 : "-40px")};
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
`;

const TextArea = styled.textarea`
  height: 80px;
  font-size: 18px;
  width: 100%;
  margin-top: 10px;
`;

export { Form, Input, Label, FormButton, FormElement, Close, TextArea };
