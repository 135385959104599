import axios from "axios";
import { Configs } from "../utils/Configs";
import { UserAuthentication } from "./StoreManager";
import { navigate } from "@reach/router";

const client = axios.create({
  baseURL: Configs.api.baseUrl,
  timeout: 30000
});

const request = async (config, isAppendAuthenticationToken, token, type) => {
  const onSuccess = response => {
    return Promise.resolve(response.data);
  };

  const onError = error => {
    //console.log("Request Failed:", error);

    if (error.response) {
      if (error.response.data.status === 401) {
        localStorage.clear();
        window.location.reload();
        navigate("/");
      }

      return Promise.reject(error.response.data);
    } else {
      //console.log("Error Message:", error.message);
      return Promise.reject(error.message);
    }
  };
  const isUser = token ? token : UserAuthentication.getToken();
  const headers = {
    "Content-Type": type ? type : "application/json",
    Accept: type ? type : "application/json",
    "Auth-token": isAppendAuthenticationToken && isUser ? isUser : null
  };
  client.defaults.headers = headers;
  return client(config)
    .then(onSuccess)
    .catch(onError);
};

export default request;
