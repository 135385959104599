import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import FilterMain from "./FilterMain";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { FilterModalControllerContext } from "../context/FilterModalControllerContext";
import CancelIcon from "@material-ui/icons/CancelRounded";
// import Tick from "@material-ui/icons/filter";
import { QueryParams } from "../managers/QueryParamsManager";
import { FilterCountContext } from "../context/FilterCountContext";
import { SnackbarControllerContext } from "../context/SnackbarControllerContext";
function FilterContainer(props) {
  function getModalStyle() {
    return {
      top: "50%",
      left: "50%",
      transform: `translate(-50%, -50%)`
    };
  }

  const useStyles = makeStyles(theme =>
    createStyles({
      paper: {
        position: "absolute",
        width: "85%",
        height: "90%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "22px 21px",
        outline: "none",
        overflowY: "scroll"
      }
    })
  );
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [selected, setSelected] = useState({});
  const [category, setCategory] = useState([]);
  const [workersRange, setWorkersRange] = useState({});
  const [brands, setBrands] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [sdgs, setSdgs] = useState([]);
  const { filterModalProps, setFilterModalProps } = React.useContext(
    FilterModalControllerContext
  );
  const { filtersForCount, setFiltersForCount } = useContext(
    FilterCountContext
  );
  const { setSnackbarProps } = useContext(SnackbarControllerContext);
  const [certifications, setCertifications] = useState([]);
  const [keepFilters, setKeepFilters] = useState(null);
  const [filterCount, setFilterCount] = useState(0);
  useEffect(() => {
    let filtersAvailable = [];
    if (!filterCount) {
      const localFilters = QueryParams.refineQueryParams();
      setFiltersForCount(localFilters);
    }
    for (let key in filtersForCount) {
      if (
        filtersForCount[key] &&
        key.replace(/\s/g, "").toLowerCase() !== "editorspick" &&
        key.replace(/\s/g, "").toLowerCase() !== "pageno" &&
        key.replace(/\s/g, "").toLowerCase() !== "suppliername" &&
        filtersForCount[key].value !== "default"
      ) {
        filtersAvailable.push(filtersForCount[key]);
      }
    }
    setFilterCount(filtersAvailable.length);
  }, [filtersForCount]);
  useEffect(() => {
    handleSuccess(props.filterOptions);
    const localDropdownFilters = QueryParams.refineQueryParams();
    if (localDropdownFilters) {
      setSelected(localDropdownFilters);
    }
  }, [props.filterOptions]);
  const handleSuccess = response => {
    setCategory(response.categories);
    setWorkersRange(response.workers_range);
    setCertifications(response.certifications);
    setImpacts(response.impacts);
    setBrands(response.brands);
    setSdgs(response.sdgs);
  };
  const getFilterListFromApi = (name, type) => {
    const filterLists =
      name && name.length
        ? name.map(data => {
            return {
              value: type && type === "code" ? `${data.code}` : `${data.name}`,
              label: `${data.name}`,
              type: data.value,
              className: "myOptionClassName"
            };
          })
        : [];
    return filterLists;
  };
  const filterTypes = [
    {
      type: "input",
      name: "Product Category",
      options: [...getFilterListFromApi(category)]
    },
    {
      type: "input",
      name: "Associated Brands",
      options: [...getFilterListFromApi(brands)]
    },
    {
      type: "input",
      name: "UN Sustainability Goals",
      options: [...getFilterListFromApi(sdgs)]
    },
    {
      type: "input",
      name: "Factory Location"
    },
    {
      type: "input",
      name: "Impact Focus",
      options: [...getFilterListFromApi(impacts)]
    },
    {
      type: "input",
      name: "ESG Certification",
      options: [...getFilterListFromApi(certifications)]
    },
    {
      type: "dropdown",
      name: "Number of Workers",
      options: [
        {
          label: "Number of Workers",
          value: "default",
          className: "myOptionHeaderClassName"
        },
        ...getFilterListFromApi(workersRange, "code")
      ]
    }
  ];

  const onSelect = (select, name, isApiCall) => {
    let params;
    const localDropdownFilters = QueryParams.refineQueryParams();
    if (localDropdownFilters) {
      params = {
        ...localDropdownFilters,
        [name]: select,
        pageNo: 1
      };
      if (isApiCall) {
        setFiltersForCount(params);
      }
      let refineParams = {};
      for (let key in params) {
        if (
          params[key] && params[key].value
            ? params[key].value !== "default"
            : params[key]
        ) {
          refineParams = { ...refineParams, [key]: params[key] };
        }
      }

      if (isApiCall) {
        let setKeep = {};
        for (let key in keepFilters) {
          if (key !== name) {
            setKeep = { ...setKeep, [key]: keepFilters[key] };
          }
        }
        setKeepFilters(setKeep);
        QueryParams.setQueryParams(refineParams);
      } else {
        if (!select && keepFilters) {
          let setKeep = keepFilters;
          delete setKeep[name];
          setKeepFilters(setKeep);
        }
        keepFilters
          ? setKeepFilters({ ...keepFilters, ...refineParams })
          : setKeepFilters(refineParams);
      }
    } else {
      params = {
        [name]: select,
        pageNo: 1
      };
      if (isApiCall) {
        setFiltersForCount(params);
      }
      let refineParams = {};
      for (let key in params) {
        if (
          params[key] && params[key].value
            ? params[key].value !== "default"
            : params[key]
        ) {
          refineParams = { ...refineParams, [key]: params[key] };
        }
      }
      if (isApiCall) {
        let setKeep = {};
        for (let key in keepFilters) {
          if (key !== name) {
            setKeep = { ...setKeep, [key]: keepFilters[key] };
          }
        }
        setKeepFilters(setKeep);
        QueryParams.setQueryParams(refineParams);
      } else {
        if (!select && keepFilters) {
          let setKeep = keepFilters;
          delete setKeep[name];
          setKeepFilters(setKeep);
        }
        keepFilters
          ? setKeepFilters({ ...keepFilters, ...refineParams })
          : setKeepFilters(refineParams);
      }
    }
    if (isApiCall) {
      props.applyingFilter(true);
      props.getProducts({ ...params, pageNo: 1 });
      setSelected({ ...selected, [name]: select });
    }
  };
  const onPressApply = () => {
    QueryParams.setQueryParams(keepFilters);
    props.applyingFilter(true);
    props.getProducts({ ...keepFilters, pageNo: 1 });
    // setSelected({ ...selected, [name]: select });
  };
  const clearAllFilters = () => {
    QueryParams.clearFilters();
  };
  function checkEquality() {
    if (Object.keys(selected).length < Object.keys(keepFilters).length) {
      return true;
    } else if (
      Object.keys(selected).length === Object.keys(keepFilters).length
    ) {
      let array1 = [];
      let array2 = [];
      for (let key in selected) {
        if (selected[key] && selected[key].length) {
          array1.push(selected[key].length);
        }
      }
      for (let key in keepFilters) {
        if (keepFilters && keepFilters[key].length) {
          array2.push(keepFilters[key].length);
        }
      }
      if (JSON.stringify(array1) === JSON.stringify(array2)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  return (
    <FilterColumnContainer>
      <FilterWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 40,
            marginTop: 20,
          }}
        >
          <div
            style={{
              fontFamily: "ProximaNova-SemiBold",
              color: "black",
              fontSize: "1.25em"
            }}
          >
            Filters
          </div>
          {filterCount > 0 ? (
            <div
              onClick={() => clearAllFilters()}
              style={{
                fontFamily: "ProximaNova-Regular",
                color: "rgb(47, 58, 85)",
                cursor: "pointer",
                fontSize: 14,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Clear all
              <CancelIcon
          style={{ color: "rgb(47, 58, 85)",  fontSize: '14px',
          marginLeft: '4px'}}
        />
            </div>
          ) : null}
        </div>
        <div
          style={{
            height: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: 20
          }}
        >
          {keepFilters && checkEquality() ? (
            <div
              onClick={() => onPressApply()}
              style={{
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                color: "rgb(47, 58, 85)",
                fontFamily: "ProximaNova-Regular",
                cursor: "pointer"
              }}
            >
              Apply Filters
              {/* <Tick
          style={{ color: "rgb(47, 58, 85)", fontSize: '14px',
          marginLeft: '4px'}}
        /> */}
            </div>
          ) : null}
        </div>
        <FilterMain
          filterTypes={filterTypes}
          onSelect={(select, name, isApiCall) =>
            onSelect(select, name, isApiCall)
          }
          // badges={badges}
          selected={selected}
        />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={filterModalProps.isOpen}
          onClose={() => setFilterModalProps({ isOpen: false })}
        >
          <div style={modalStyle} className={classes.paper}>
            <ModalHeader>
              <p
                style={{
                  fontFamily: "ProximaNova-SemiBold",
                  color: "black",
                  alignSelf: "flex-start",
                  fontSize: "1.25em"
                }}
              >
                Filters
              </p>
              <div style={{display: 'flex', alignItems: 'center'}}>
              {keepFilters && checkEquality() ? (  <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#2f3a55",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: 2,
                  cursor: "pointer"
                }}
                onClick={() => {onPressApply(); setFilterModalProps({ isOpen: false })}}
              >
                <p
                  style={{
                    fontFamily: "ProximaNova-SemiBold",
                    paddingRight: 5,
                    fontSize: 12
                  }}
                >
                  Apply
                </p>
                {/* <i
                  className="fa fa-check"
                  aria-hidden="true"
                  style={{ fontSize: 10 }}
                /> */}
              </div> ) : null }
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "whitesmoke",
                  color: "#2f3a55",
                  padding: "5px 10px",
                  borderRadius: 2,
                  cursor: "pointer",
                  marginLeft: 5
                }}
                onClick={() => {
                  setKeepFilters(selected);
                  setFilterModalProps({ isOpen: false })}}
              >
                <p
                  style={{
                    fontFamily: "ProximaNova-SemiBold",
                    paddingRight: 5,
                    fontSize: 12
                  }}
                >
                  Cancel
                </p>
                {/* <i
                  className="fa fa-times"
                  aria-hidden="true"
                  style={{ fontSize: 10 }}
                /> */}
              </div>
              </div>
            </ModalHeader>
            <FilterMain
              filterTypes={filterTypes}
              onSelect={(select, name, isApiCall) =>
                onSelect(select, name, isApiCall)
              }
              // badges={}
              selected={selected}
            />
            {/* ) : (
              <LoadingContainer>
                <p>Loading...</p>
              </LoadingContainer>
            )} */}
          </div>
        </Modal>
      </FilterWrapper>
    </FilterColumnContainer>
  );
}

export default FilterContainer;

const LoadingContainer = styled.div`
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-family: "ProximaNova-SemiBold";
    font-size: 1.8em;
  }
`;
const FilterWrapper = styled.div`
  width: 241px;
  @media (max-width: 685px) {
    width: 100%;
  }
  @media (min-width: 686px) and (max-width: 900px) {
    width: 100%;
  }
`;
const FilterColumnContainer = styled.div`
  width: 320px;
  height: 100%;
  position: sticky;
  top: 0;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  @media (min-width: 900px) and (max-width: 1060px) {
    padding: 0 30px;
    width: 286px;
  }
  @media (min-width: 686px) and (max-width: 900px) {
    padding: 0 30px;
    width: 225px;
  }
  @media (max-width: 685px) {
    display: none;
  }
  p {
    font-family: "ProximaNova-SemiBold";
    color: black;
    align-self: flex-start;
    font-size: 1.25em;
    padding: 20px 0;
  }
`;
const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  i {
    height: 12px;
    width: 12px;
  }
`;
