import React from "react";
import styled from "styled-components";
function LoaderContainer(props) {
  return (
    <LoaderContainerMain>
      <p>{props.message}</p>
      {props.loader === false ? null : (
        <img alt="loader" src={require("../assets/images/loader.gif")} />
      )}
    </LoaderContainerMain>
  );
}
const LoaderContainerMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  p {
    font-family: "ProximaNova-SemiBold";
    font-size: 2em;
    padding-right: 10px;
    @media (max-width: 785px) {
      font-size: 1em;
    }
  }
  img {
    margin: 10px 0;
    width: 60px;
    height: 60px;
    @media (max-width: 785px) {
      width: 30px;
      height: 30px;
    }
  }
`;

export default LoaderContainer;
