import { TweenMax, Circ, ScrollToPlugin } from "gsap/all";
const plugins = [ScrollToPlugin];

const navigateAnim = name => {
  TweenMax.to(window, 2, { scrollTo: `${name}`, ease: Circ.easeInOut });
};
const InternalNavigation = {
  navigateAnim
};
export default InternalNavigation;
