import React, { useState } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { UserContext } from "../context/UserContext";
import HourglassEmptyOutlined from "@material-ui/icons/HourglassEmptyOutlined";
import DoneIcon from "@material-ui/icons/Done";
import { ModalContext } from "../context/ModalContext";
import { SupplierContext } from "../context/SupplierContext";

function DetailsIntroCard(props) {
  const { supplier } = React.useContext(SupplierContext);
  const { setLoginModalOpen } = React.useContext(ModalContext);

  const { user } = React.useContext(UserContext);
  const [readChar, setReadChar] = React.useState({
    mobile: 100,
    desktop: 200
  });

  useState(() => {
    if (props.readmore) {
      setReadChar({
        desktop: props.details.about.length,
        mobile: props.details.about.length
      });
    }
  }, []);
  const onBookmarkClick = flag => {
    if (user) {
      props.onBookmark(flag ? false : true);
    } else {
      // setSnackbarProps({
      //   isOpen: true,
      //   variant: "info",
      //   message: "Please Login First."
      // });
      setLoginModalOpen(true);
    }
  };
  const getUrlFixed = url => {
    const urlFixed =
      url.substring(0, 7) === "http://" || url.substring(0, 8) === "https://"
        ? url
        : "http://" + url;

    return urlFixed;
  };
  const checkActions = () => {
    if (
      !props.details.contact_email &&
      !props.details.contact_phone &&
      !props.details.website &&
      !props.details.contact_fax
    ) {
      return false;
    } else {
      return true;
    }
  };

  const infoAction = [
    {
      image: "send",
      value:
        props.details && props.details.contact_email
          ? props.details.contact_email
          : null
    },
    {
      image: "phone",
      value:
        props.details && props.details.contact_phone
          ? props.details.contact_phone
          : null
    },
    {
      image: "globe",
      value:
        props.details && props.details.website ? props.details.website : null
    },
    {
      image: "archive",
      value:
        props.details && props.details.contact_fax
          ? props.details.contact_fax
          : null
    }
  ];

  return (
    <DetailsIntroCardContainer>
      <Top>
        <Left
          style={{
            background:
              props.details && props.details.logo_url ? null : "#f9f9f9"
          }}
        >
          <img
            className="placeholder"
            style={{
              objectFit:
                props.details && props.details.logo_url ? "contain" : "cover",
              objectPosition:
                props.details && props.details.logo_url ? "left" : "center"
            }}
            src={
              props.details && props.details.logo_url
                ? props.details.logo_url
                : require("../assets/images/Placeholder-logo.png")
            }
            alt="goodlist"
          />
          {/* <SvgContainer>
            {props.details.is_environment_friendly &&
            props.details.is_socially_responsible ? (
              <>
                <img
                  className="ellipseImage"
                  src={require("../assets/images/orangeEllipse.svg")}
                  alt="ellipse"
                />
                <img
                  className="iconImage"
                  src={require("../assets/images/handshake.svg")}
                  alt="editorspick"
                />
                <img
                  className="ellipseImage2"
                  src={require("../assets/images/orangeEllipse.svg")}
                  alt="ellipse"
                />
                <img
                  className="iconImage2"
                  src={require("../assets/images/tree-leaf.svg")}
                  alt="editorspick"
                />
              </>
            ) : props.details.is_environment_friendly ? (
              <>
                <img
                  className="ellipseImage"
                  src={require("../assets/images/greenEllipse.svg")}
                  alt="ellipse"
                />
                <img
                  className="iconImage"
                  src={require("../assets/images/tree-leaf.svg")}
                  alt="editorspick"
                />
              </>
            ) : props.details.is_socially_responsible ? (
              <>
                <img
                  className="ellipseImage"
                  src={require("../assets/images/orangeEllipse.svg")}
                  alt="ellipse"
                />
                <img
                  className="iconImage"
                  src={require("../assets/images/handshake.svg")}
                  alt="editorspick"
                />
              </>
            ) : null}
          </SvgContainer> */}
        </Left>

        <Right>
          <Header>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Name>
                {props.details && props.details.name
                  ? props.details.name
                  : "Supplier"}
              </Name>
              <Action
                onClick={() =>
                  supplier.claimed_status
                    ? null
                    : props.onClaim(props.details.id)
                }
                style={{
                  cursor:
                    supplier.claimed_status === "claimed" ||
                    supplier.claimed_status === "approved"
                      ? "default"
                      : "pointer"
                }}
              >
                {supplier.claimed_status === "approved" ? (
                  <p>Claimed</p>
                ) : supplier.claimed_status === "claimed" ? (
                  <p>Claim pending</p>
                ) : (
                  <p>Claim profile</p>
                )}
                {supplier.claimed_status === "approved" ? (
                  <DoneIcon
                    style={{ fontSize: 14, color: "#0070f5", marginLeft: 5 }}
                  />
                ) : supplier.claimed_status === "claimed" ? (
                  <HourglassEmptyOutlined
                    style={{ fontSize: 14, color: "#0070f5", marginLeft: 5 }}
                  />
                ) : (
                  <img
                    src={require("../assets/images/arrow-right@3x.png")}
                    alt="click"
                  />
                )}
              </Action>
            </div>
            <MediaQuery query="(min-width: 767px)">
              {props.details ? (
                <Bookmark
                  style={{
                    cursor:
                      props.bookmarkLoader && props.bookmarkLoader.flag
                        ? "not-allowed"
                        : "pointer"
                  }}
                  onClick={() => onBookmarkClick(props.details.is_bookmarked)}
                  src={
                    props.bookmarkLoader &&
                    props.bookmarkLoader.flag &&
                    props.bookmarkLoader.flag
                      ? require("../assets/images/loader.gif")
                      : props.details.is_bookmarked
                      ? require("../assets/images/bookmarked@3x.png")
                      : require("../assets/images/bookmark@3x.png")
                  }
                />
              ) : null}
            </MediaQuery>
          </Header>

          <Location>
            {props.details && props.details.city
              ? props.details.city + ","
              : null}{" "}
            {props.details && props.details.country
              ? props.details.country
              : null}
          </Location>
          <Count>
            {props.details && props.details.workers_count
              ? props.details.workers_count + " Employees"
              : null}
          </Count>
          <MediaQuery query="(min-width: 767px)">
            {props.details && props.details.about ? (
              <AboutContainer>
                <Heading>About</Heading>
                {props.details.about.length > 200 ? (
                  <>
                    <Paragraph>
                      {props.details.about.substring(0, readChar.desktop)}
                      <span
                        onClick={() =>
                          readChar.desktop === 200
                            ? setReadChar({
                                ...readChar,
                                desktop: props.details.about.length
                              })
                            : setReadChar({
                                ...readChar,
                                desktop: 200
                              })
                        }
                      >
                        {readChar.desktop === 200
                          ? "...Read more"
                          : " Show less"}
                      </span>
                    </Paragraph>
                  </>
                ) : (
                  <Paragraph>{props.details.about}</Paragraph>
                )}
                {props.details.is_socially_responsible ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 28
                    }}
                  >
                    <img
                      className="iconImage"
                      src={require("../assets/images/handshake.svg")}
                      alt="editorspick"
                    />{" "}
                    <EditorsPickPara>Socially Responsible</EditorsPickPara>
                  </div>
                ) : null}
                {props.details.is_environment_friendly ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 28
                    }}
                  >
                    <img
                      className="iconImage"
                      src={require("../assets/images/tree-leaf.svg")}
                      alt="editorspick"
                    />{" "}
                    <EditorsPickPara>
                      Environmentally Responsible
                    </EditorsPickPara>
                  </div>
                ) : null}
              </AboutContainer>
            ) : null}
          </MediaQuery>
        </Right>
      </Top>
      <MediaQuery query="(max-width: 767px)">
        <ActionContainer>
          <ActionMobile
            onClick={() =>
              supplier.claimed_status ? null : props.onClaim(props.details.id)
            }
          >
            {supplier.claimed_status === "approved" ? (
              <p>Claimed</p>
            ) : supplier.claimed_status === "claimed" ? (
              <p>Claim pending</p>
            ) : (
              <p>Claim profile</p>
            )}
          </ActionMobile>
          <Bookmark
            style={{
              cursor:
                props.bookmarkLoader && props.bookmarkLoader.flag
                  ? "not-allowed"
                  : "pointer"
            }}
            onClick={() => onBookmarkClick(props.details.is_bookmarked)}
            src={
              props.bookmarkLoader &&
              props.bookmarkLoader.flag &&
              props.bookmarkLoader.flag
                ? require("../assets/images/loader.gif")
                : props.details.is_bookmarked
                ? require("../assets/images/bookmarked@3x.png")
                : require("../assets/images/bookmark@3x.png")
            }
          />
        </ActionContainer>
        {props.details && props.details.about ? (
          <AboutContainer>
            <Heading>About</Heading>
            {props.details.about.length > 100 ? (
              <>
                <Paragraph>
                  {props.details.about.substring(0, readChar.mobile)}
                  <span
                    onClick={() =>
                      readChar.mobile === 100
                        ? setReadChar({
                            ...readChar,
                            mobile: props.details.about.length
                          })
                        : setReadChar({
                            ...readChar,
                            mobile: 100
                          })
                    }
                  >
                    {readChar.mobile === 100 ? "...Read more" : " Show less"}
                  </span>
                </Paragraph>
              </>
            ) : (
              <Paragraph>{props.details.about}</Paragraph>
            )}
            {props.details.is_socially_responsible ? (
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 28 }}
              >
                <img
                  className="iconImage"
                  src={require("../assets/images/handshake.svg")}
                  alt="editorspick"
                />{" "}
                <EditorsPickPara>Socially Responsible</EditorsPickPara>
              </div>
            ) : null}
            {props.details.is_environment_friendly ? (
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 28 }}
              >
                <img
                  className="iconImage"
                  src={require("../assets/images/tree-leaf.svg")}
                  alt="editorspick"
                />{" "}
                <EditorsPickPara>Environmentally Responsible</EditorsPickPara>
              </div>
            ) : null}
          </AboutContainer>
        ) : null}
      </MediaQuery>
      {checkActions() ? (
        <Bottom style={ supplier && supplier.sdgs && supplier.sdgs.length ? {  borderBottom: 'solid 0.3px rgba(151, 151, 151, 0.98)'} : null}>
          {infoAction.map((data, index) => {
            return data.value ? (
              data.image === "send" ||
              data.image === "globe" ||
              data.image === "phone" ? (
                <a
                  style={{ cursor: "pointer" }}
                  target={data.image === "globe" ? "_blank" : null}
                  key={index}
                  href={
                    data.image === "send"
                      ? `mailto:${data.value}`
                      : data.image === "phone"
                      ? `tel:${data.value}`
                      : getUrlFixed(data.value)
                  }
                >
                  <InfoAction>
                    <img
                      src={require(`../assets/images/${data.image}.svg`)}
                      alt="Info"
                    />
                    <p>
                      {data.value.length > 30
                        ? data.value.slice(0, 30) + "..."
                        : data.value}
                    </p>
                  </InfoAction>
                </a>
              ) : (
                <InfoAction key={index}>
                  <img
                    src={require(`../assets/images/${data.image}.svg`)}
                    alt="Info"
                  />

                  <p>
                    {data.value.length > 20
                      ? data.value.slice(0, 20) + "..."
                      : data.value}
                  </p>
                </InfoAction>
              )
            ) : null;
          })}
        </Bottom>
      ) : null}
      {supplier && supplier.sdgs && supplier.sdgs.length ? (
        <div style={{ marginTop: 28 }}>
          <SubHeading>Associated UN Sustainable Development Goals</SubHeading>
          <ul>
            {supplier.sdgs.map((data, index) => {
              return <Paragraph key={index}>{data}</Paragraph>;
            })}
          </ul>
        </div>
      ) : null}
    </DetailsIntroCardContainer>
  );
}

export default DetailsIntroCard;
const DetailsIntroCardContainer = styled.div`
  border: 1px solid rgba(151, 151, 151, 0.15);
  margin-top: 33px;
  height: 100%;
  max-width: 1040px;
  background: white;
  padding: 28px;
  display: flex;
  flex-direction: column;
  @media (max-width: 685px) {
    padding: 20px;
  }
  .iconImage {
    width: 20px;
  }
`;
const SvgContainer = styled.div`
  position: absolute;
  height: 77px;
  width: 100%;
  overflow: hidden;
  .ellipseImage {
    position: absolute;
    z-index: 3;
    bottom: -5px;
    left: -30px;
    top: -30px;
    width: 90px;
    transform: rotate(190deg);
    @media (max-width: 685px) {
      width: 80px;
    }
  }
  .ellipseImage2 {
    position: absolute;
    z-index: 3;
    bottom: -5px;
    right: -30px;
    top: -30px;
    width: 90px;
    transform: rotate(250deg);
    @media (max-width: 685px) {
      width: 80px;
    }
  }
  .iconImage2 {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 20px;
    z-index: 5;
    @media (max-width: 685px) {
      width: 15px;
      right: 9px;
      top: 9px;
    }
  }
`;
const Left = styled.div`
  width: 260px;
  height: 204px;
  min-width: 260px;
  min-height: 204px;
  position: relative;
  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 767px) {
    width: 152px;
    height: 124px;
    min-width: 152px;
    min-height: 124px;
    background: transparent !important;
    .placeholder {
      width: 100%;
      height: 100%;
    }
  }
`;
const Right = styled.div`
  padding-left: 39px;
  width: 100%;
  @media (max-width: 685px) {
    padding-left: 10px;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const Name = styled.div`
  max-width: 70%;
  font-family: "ProximaNova-SemiBold";
  font-size: 1.25em;
  @media (max-width: 767px) {
    font-size: 16px;
    max-width: 100%;
  }
`;
const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #f9f9f9;
  padding: 0 14px;
  height: 32px;
  margin-left: 20px;
  min-width: 120px;
  img {
    width: 10px;
    margin-left: 5px;
  }
  p {
    font-size: 0.875em;
    font-family: "ProximaNova-Regular";
    color: #0070f5;
  }
  @media (max-width: 767px) {
    margin-left: 0;
    display: none !important;
  }
`;
const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;
`;
const SubHeading = styled.div`
  margin-top: 28px;
  font-family: "ProximaNova-SemiBold";
  color: #313e7e;
  font-size: 1.1em;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;
const ActionMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: none;
  cursor: pointer;
  /* margin-bottom: 26px; */
  width: 80%;
  height: 27px;
  border: solid 0.3px #2f3b55;
  p {
    font-size: 12px;
    font-family: "ProximaNova-SemiBold";
    color: #2f3b55;
  }
  @media (min-width: 767px) {
    display: none;
  }
`;
const InfoAction = styled.div`
  min-width: 224px;
  padding: 0 45px;
  min-height: 60px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
  }
  p {
    font-family: "ProximaNova-Regular";
    font-size: 1em;
    padding-left: 12px;
  }

  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
    margin-top: 20px;
    min-height: 60px;
    padding: 0 10px;
  }
`;
const Bookmark = styled.img`
  cursor: pointer;
  max-width: 19px;
  flex: 0.1;
  @media (max-width: 767px) {
    position: relative;
    margin: 0 auto;
    top: 0;
  }
`;
const Top = styled.div`
  display: flex;
  position: relative;
`;
const AboutContainer = styled.div`
  position: relative;
  @media (max-width: 767px) {
    padding-top: 40px;
  }
`;
const Heading = styled.p`
  font-family: "ProximaNova-SemiBold";
  font-size: 1em;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const EditorsPickPara = styled.p`
  padding-left: 12px;
  font-family: "ProximaNova-Regular";
  font-size: 1em;
  color: rgba(0, 0, 0, 0.51);
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const Paragraph = styled.p`
  padding-top: 8px;
  font-family: "ProximaNova-Regular";
  font-size: 1em;
  line-height: 1.63;
  color: rgba(0, 0, 0, 0.51);
  @media (max-width: 767px) {
    font-size: 16px;
  }
  span {
    padding-top: 21px;
    font-family: "ProximaNova-SemiBold";
    color: #2f3b55;
    cursor: pointer;
  }
`;
const Location = styled.p`
  margin: 10px 0;
  font-family: "ProximaNova-Regular";
  color: #8a8a8a;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
const Count = styled.p`
  font-family: "ProximaNova-Regular";
  color: #8a8a8a;
  padding-top: 7px;
  padding-bottom: 17px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const Bottom = styled.div`
  margin-top: 38px;
  border-top: solid 0.3px rgba(151, 151, 151, 0.98);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  min-height: 100px;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: black;
    &:hover {
      color: #0000ee;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;
