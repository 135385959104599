import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../components/home/header/Header";
import DetailsIntroCard from "./DetailsIntroCard";
import Footer from "../components/footer/Footer";
import GoodListService from "./GoodListService";
import FullpageLoader from "../common/FullpageLoader";
import { SnackbarControllerContext } from "../context/SnackbarControllerContext";
import FactoryDetailsCard from "./FactoryDetailsCard";
import { UserContext } from "../context/UserContext";
import Modal from "react-modal";
import { navigate } from "@reach/router";
import ClaimProfile from "./ClaimProfile";
import { customModalStyles } from "../utils/customModalStyles";
import { ModalContext } from "../context/ModalContext";
import { SupplierContext } from "../context/SupplierContext";
function SupplierDetailsView(props) {
  const [details, setDetails] = useState(null);
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  const [readmoreFromProps, setReadmoreFromProps] = useState(false);
  const [bookmarkLoader, setBookmarkLoader] = useState(false);
  const { user } = React.useContext(UserContext);
  const {
    setLoginModalOpen,
    setClaimProfileModalOpen,
    isClaimProfileModalOpen
  } = React.useContext(ModalContext);
  const { setSupplier } = React.useContext(SupplierContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.location.state && props.location.state.readmore) {
      setReadmoreFromProps(props.location.state.readmore);
    }
    async function getDetails() {
      setDetails(null);
      try {
        const result = await GoodListService.getDetails(
          props.userId && props.userId,
          user ? JSON.parse(user).auth_token : null
        );
        if (result && result.status === 200) {
          handleSuccess(result);
        }
      } catch (error) {
        handleError(error);
      }
    }
    getDetails();
  }, [user]);
  const handleClaimProfileClose = () => {
    setClaimProfileModalOpen(false);
  };
  const bookmarkSupplier = async flag => {
    setBookmarkLoader({
      flag: true
    });
    try {
      const result = await GoodListService.bookmarkSupplier(props.userId, flag);
      if (result && result.status === 200) {
        handleBookmarkSuccess(result, false);
      } else if (result && result.status === 201) {
        handleBookmarkSuccess(result, true);
      }
    } catch (error) {
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.error.message && typeof error.error.message === "string"
            ? error.error.message
            : "Please try again later"
      });
    }
    setBookmarkLoader({
      flag: false
    });
  };
  const handleBookmarkSuccess = (result, flag) => {
    const supplier = details;
    supplier.is_bookmarked = flag;
    setDetails(supplier);
    setBookmarkLoader({
      flag: false
    });
    setSnackbarProps({
      variant: "success",
      isOpen: true,
      message:
        result.response.message && typeof result.response.message === "string"
          ? result.response.message
          : "Success"
    });
  };
  const handleSuccess = result => {
    setDetails(result.response.supplier);
    setSupplier(result.response.supplier);
  };
  const handleError = () => {
    setSnackbarProps({
      variant: "error",
      isOpen: true,
      message: "Supplier not found."
    });
    navigate("/");
  };
  const onClaimProfileClick = async id => {
    if (!user) {
      // setSnackbarProps({
      //   variant: "info",
      //   isOpen: true,
      //   message: "Please login first."
      // });
      setLoginModalOpen(true);
    } else {
      setClaimProfileModalOpen(true);
    }
  };
  return (
    <>
      <Header main={true} suppliers={true} />
      {isClaimProfileModalOpen && (
        <Modal
          isOpen={isClaimProfileModalOpen}
          style={customModalStyles}
          contentLabel="Claim Profile"
          ariaHideApp={false}
          onRequestClose={handleClaimProfileClose}
          shouldCloseOnOverlayClick={true}
        >
          <ClaimProfile onClose={handleClaimProfileClose} />
        </Modal>
      )}
      <Main>
        {props.location.state &&
        props.location.state.component === "editorsPick" ? null : (
          <BackToButton onClick={() => window.history.back()}>
            <img
              alt="arrow"
              src={require("../assets/images/arrow-right@3x.png")}
            />{" "}
            <p>Back to Results</p>
          </BackToButton>
        )}
        {details ? (
          <>
            <DetailsIntroCard
              readmore={readmoreFromProps}
              details={details}
              onClaim={id => onClaimProfileClick(id)}
              onBookmark={flag => bookmarkSupplier(flag)}
              bookmarkLoader={bookmarkLoader}
            />
            <SubHeading>
              {details.name ? `Factories under ${details.name}` : null}
            </SubHeading>
            {details && details.factories && details.factories.length
              ? details.factories.map((data, index) => {
                  return (
                    <FactoryDetailsCard
                      supplier={details.name}
                      factory={data}
                      key={index}
                    />
                  );
                })
              : null}
          </>
        ) : (
          <FullpageLoader />
        )}
      </Main>
      <Footer contact={true} />
    </>
  );
}

export default SupplierDetailsView;
const Main = styled.div`
  min-height: calc(100vh - 99px);
  width: 100%;
  padding: 22px 40px;
  background: #f9f9f9;
  @media (max-width: 584px) {
    padding: 22px 0px;
  }
`;
const SubHeading = styled.div`
  font-family: "ProximaNova-SemiBold";
  color: #2f3b55;
  padding: 0 45px;
  font-size: 1.5em;
  margin: 30px 0;
  @media (max-width: 785px) {
    padding: 0 20px;
    font-size: 18px;
  }
`;
const BackToButton = styled.div`
  width: 137px;
  height: 32px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  img {
    width: 10px;
    transform: rotate(180deg);
  }
  p {
    font-family: "ProximaNova-Regular";
    font-size: 0.875em;
    color: #0070f5;
  }
  @media (max-width: 584px) {
    margin-left: 16px;
  }
`;
