import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../home/header/Header";
import Footer from "../footer/Footer";
import AboutImage from "../../assets/images/sdg-poster.png";
function WhoWeAre(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const mission = [
    "We believe that sustainability initiatives should comprehensively address worker and community rights, animal welfare, and environmental responsibility.",
    "We believe the fastest way to solve the grand challenges of our time is to align all global supply chains with the    United Nations Sustainable Development Goals (UN SDGs), a universal framework for positive change.",
    "We believe in transparency, and highlight producers that share clear impact metrics around the UN SDGs, be it their employee gender breakdown or the types of dyes they use.",
    "We believe verification is critical to driving positive change, and seek to highlight producers that use independent 3rd parties to confirm their impact claims.",
    "We believe in progress over perfection, and know that shifting global supply chains will not happen overnight; what’s most important is meeting brands and producers where they are and providing the resources to accelerate change."
  ];
  return (
    <WhoWeAreContainer>
      <Header main={true} aboutLink={true} />
      <Main>
        <HeroImage>
          <img src={`${AboutImage}`} alt="sdg" style={{width: '100%'}}/>
        </HeroImage>
        <AboutPara>
        GoodList is a platform to source sustainable raw materials and finished goods from vetted suppliers. The fashion industry has a universal imperative to dramatically reduce its negative impact on people and planet by positively evolving their supply chain. We believe the journey begins with choosing the right sourcing partners who balance high-quality manufacturing with responsible operations. Our network brings together companies working towards this goal, including established suppliers of top sustainable brands, producers with credible environmental or social certifications, and innovators with ground-breaking ESG solutions. To better facilitate global efforts, GoodList suppliers are benchmarked against the 17 United Nations Sustainable Development Goals, a blueprint to achieve a better and more sustainable future for all. 
        </AboutPara>
        <SubHeading>Our Team</SubHeading>
        <WhoWeArePara>
        Our Founder and CEO, Divya Demato, spent nearly 17 years on the frontlines with companies struggling to match both purpose and profit. She built GoodList to address this challenge and propel the sustainability movement forward. Divya and the team bring extensive experience in supply chain, social and environmental impact, technology and innovation across industries that span the globe. 
        </WhoWeArePara>
        <SubHeading>First Principles</SubHeading>
        {mission.map((data, index) => {
          return (
            <Mission key={index}>
              <Dot></Dot>
              <MissionText>{data}</MissionText>
            </Mission>
          );
        })}
      </Main>
      <Footer contact={true} />
    </WhoWeAreContainer>
  );
}

export default WhoWeAre;

const WhoWeAreContainer = styled.div`
  height: 100%;
`;
const Main = styled.div`
  position: relative;
  height: 100%;
  padding: 40px 140px;
  @media (max-width: 900px) {
    padding: 40px 80px;
  }
  @media (max-width: 685px) {
    padding: 20px 20px;
  }
`;
const HeroImage = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
`;

const AboutPara = styled.p`
  padding: 20px 0;
  font-family: "ProximaNova-Regular";
  font-size: 1.125em;
  line-height: 2;
  @media (max-width: 685px) {
    font-size: 14px;
  }
`;
const SubHeading = styled.p`
  font-size: 30px;
  padding: 20px 0;
  color: #313e7e;
  font-family: "ProximaNova-Bold";
  @media (max-width: 685px) {
    font-size: 20px;
  }
`;
const WhoWeArePara = styled.p`
  padding-top: 15px;
  padding-bottom: 20px;
  font-family: "ProximaNova-Regular";
  font-size: 1.125em;
  line-height: 2;
  @media (max-width: 685px) {
    padding-top: 15px;
    font-size: 14px;
  }
  @media (max-width: 425px) {
    padding-top: 10px;
  }
`;

const Mission = styled.div`
  padding: 15px 0;
  display: flex;
  align-items: baseline;
`;
const Dot = styled.div`
  width: 10px;
  height: 10px;
  max-width: 10px;
  min-width: 10px;
  max-height: 10px;
  min-height: 10px;
  background: #313e7e;
  border-radius: 50%;
  @media (max-width: 685px) {
    width: 7.5px;
    height: 7.5px;
    max-width: 7.5px;
    min-width: 7.5px;
    max-height: 7.5px;
    min-height: 7.5px;
  }
`;
const MissionText = styled.p`
  padding: 0 20px;
  font-family: "ProximaNova-Regular";
  font-size: 1.125em;
  @media (max-width: 685px) {
    padding-right: 0;
    font-size: 15px;
    line-height: 2;
  }
`;
