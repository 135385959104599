import React from "react";
import styled from "styled-components";
import LoadingContainer from "../common/LoaderContainer";
import DetailsExtrasCard from "./DetailsExtrasCard";
import DetailsProductPreviewCard from "./DetailsProductPreviewCard";
import DetailsCertificationCard from "./DetailsCertificationCard";
function FactoryDetailsCard(props) {
  if (props.factory) {
    const checkActions = () => {
      if (
        !props.factory.email &&
        !props.factory.contact &&
        !props.factory.website &&
        !props.factory.fax
      ) {
        return false;
      } else {
        return true;
      }
    };
    const infoAction = [
      {
        image: "send",
        value:
          props.factory && props.factory.contact_email
            ? props.factory.contact_email
            : null
      },
      {
        image: "phone",
        value:
          props.factory && props.factory.contact_phone
            ? props.factory.contact_phone
            : null
      },
      {
        image: "globe",
        value:
          props.factory && props.factory.website ? props.factory.website : null
      },
      {
        image: "archive",
        value:
          props.factory && props.factory.contact_fax
            ? props.factory.contact_fax
            : null
      }
    ];
    const getUrlFixed = url => {
      const urlFixed =
        url.substring(0, 7) === "http://" || url.substring(0, 8) === "https://"
          ? url
          : "http://" + url;

      return urlFixed;
    };
    return (
      <FactoryDetailsCardContainer>
        <FactoryInfo>
          {props.factory.name ? (
            <Logo
              style={{
                background: props.factory.logo_url ? null : "#f9f9f9"
              }}
            >
              <img
                src={
                  props.factory.logo_url
                    ? props.factory.logo_url
                    : require("../assets/images/Placeholder-logo.png")
                }
                alt="goodlist"
              />
            </Logo>
          ) : null}
          <Info>
            {props.factory.name ? <Name>{props.factory.name}</Name> : null}
            {/* {props.factory.address ? (
              <Location>{props.factory.address}</Location>
            ) : null} */}
          </Info>
        </FactoryInfo>
        {checkActions() ? (
          <Bottom>
            {infoAction.map((data, index) => {
              return data.value ? (
                data.image === "send" ||
                data.image === "globe" ||
                data.image === "phone" ? (
                  <a
                    target={data.image === "globe" ? "_blank" : null}
                    key={index}
                    href={
                      data.image === "send"
                        ? `mailto:${data.value}`
                        : data.image === "phone"
                        ? `tel:${data.value}`
                        : getUrlFixed(data.value)
                    }
                  >
                    <InfoAction>
                      <img
                        src={require(`../assets/images/${data.image}.svg`)}
                        alt="info"
                      />
                      <p>
                        {data.value.length > 20
                          ? data.value.slice(0, 20) + "..."
                          : data.value}
                      </p>
                    </InfoAction>
                  </a>
                ) : (
                  <InfoAction key={index}>
                    <img
                      src={require(`../assets/images/${data.image}.svg`)}
                      alt="info"
                    />

                    <p>
                      {data.value.length > 20
                        ? data.value.slice(0, 20) + "..."
                        : data.value}
                    </p>
                  </InfoAction>
                )
              ) : null;
            })}
          </Bottom>
        ) : null}
        {props.factory.name ? <div className="line"></div> : null}
        <DetailsProductPreviewCard factory={props.factory} />
        <DetailsCertificationCard factory={props.factory} />
        <DetailsExtrasCard factory={props.factory} supplier={props.name} />
      </FactoryDetailsCardContainer>
    );
  } else {
    return <LoadingContainer name="Loading.." />;
  }
}

export default FactoryDetailsCard;

const FactoryDetailsCardContainer = styled.div`
  border: 1px solid rgba(151, 151, 151, 0.15);
  margin-top: 20px;
  max-width: 1040px;
  background: white;
  padding: 39px 49px;
  .line {
    margin-top: 20px;
    height: 0.5px;
    background: rgba(151, 151, 151, 0.18);
  }
  @media (max-width: 685px) {
    padding: 20px;
  }
`;
const Name = styled.p`
  font-family: "ProximaNova-SemiBold";
  font-size: 1.25em;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const FactoryInfo = styled.div`
  display: flex;
  align-items: center;
`;
const Info = styled.div`
  padding-left: 30px;
`;
const Logo = styled.div`
  position: relative;
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
  img {
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 585px) {
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
  }
`;
const InfoAction = styled.div`
  min-width: 224px;
  padding: 0 45px;
  min-height: 60px;
  margin-top: 20px;
  margin-right: 10px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
  }
  p {
    font-family: "ProximaNova-Regular";
    font-size: 1em;
    padding-left: 12px;
  }

  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
    margin-top: 20px;
    min-height: 60px;
    padding: 0 10px;
  }
`;
const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100px;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  a {
    text-decoration: none;
    color: black;
    &:hover {
      color: #0000ee;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;
