import React from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";
import Header from "../home/header/Header";
import Footer from "../footer/Footer";
import { ScrollPosition } from "../../managers/StoreManager";
function Contact() {
  React.useEffect(() => {
    ScrollPosition.deleteScrollValue();
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainContainer className="mainContainer" style={{ background: " #f9f9f9" }}>
      <Header main={true} contactLink={true} s />
      <ContactConatiner id="contact">
        <ContactCard>
          <Left>
            <Heading>Contact Us</Heading>
            <Paragraph>
              We are building the world’s most trusted and comprehensive network
              of ethical and sustainable suppliers for raw materials and
              finished goods. If you want to join our community or learn more,
              we’d love to hear from you!
            </Paragraph>
            <ContactForm />
          </Left>
          <Right>
            <img
              src={require("../../assets/images/ellipsewithImg.png")}
              alt="ellipse"
            />
          </Right>
        </ContactCard>
      </ContactConatiner>
      <Footer contact={true} />
    </MainContainer>
  );
}

export default Contact;
const MainContainer = styled.div`
  min-height: 100vh;
  @media (min-width: 2400px) {
    height: 100vh;
    overflow: auto;
  }
`;
const ContactConatiner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 685px) {
    margin-top: 0px;
  }
`;

const ContactCard = styled.div`
  display: flex;
  width: 834px;
  min-height: 701px;
  background: white;
  margin: 30px auto;
  @media (min-width: 685px) and (max-width: 900px) {
    width: 85%;
  }
  @media (max-width: 685px) {
    width: 90%;
    flex-direction: column;
    margin: 16px auto;
  }
`;
const Right = styled.div`
  position: relative;
  width: 38%;
  height: 100%;
  overflow: hidden;
  img {
    margin-top: 30px;
    transform: rotate(178deg);
    width: 500px;
  }
  @media (max-width: 685px) {
    display: none;
  }
`;
const Left = styled.div`
  padding: 35px 0;
  padding-left: 58px;
  height: 100%;
  width: 62%;
  display: flex;
  flex-direction: column;
  @media (max-width: 685px) {
    padding: 35px 33px;
    width: 100%;
  }
`;
const Heading = styled.div`
  font-family: "ProximaNova-Bold";
  font-size: 1.875em;
  @media (max-width: 685px) {
    font-size: 20px;
  }
`;
const Paragraph = styled.div`
  padding-top: 12px;
  width: 80%;
  font-family: "ProximaNova-Regular";
  font-size: 1em;
  line-height: 1.63;
  color: rgba(0, 0, 0, 0.51);
  @media (max-width: 685px) {
    width: 100%;
    font-size: 14px;
  }
`;
