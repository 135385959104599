import React from "react";

export const SupplierContext = React.createContext();

function SupplierProvider({ children }) {
  const [supplier, setSupplier] = React.useState({});
  const value = React.useMemo(() => {
    return {
      supplier,
      setSupplier
    };
  }, [supplier, setSupplier]);

  return (
    <SupplierContext.Provider value={value}>
      {children}
    </SupplierContext.Provider>
  );
}

export default SupplierProvider;
