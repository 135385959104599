import React, { useReducer, useMemo } from "react";
import { GoodlistReducer, ADD_FILTERS } from "./GoodlistReducer";

export const GoodlistContext = React.createContext();

const GoodlistProvider = props => {
  const [state, dispatch] = useReducer(GoodlistReducer, {});
  const addFilters = filters => {
    dispatch({
      type: ADD_FILTERS,
      filters: filters
    });
  };
  // const getResponse = filters => {
  //   dispatch({
  //     type: GET_RESPONSE,
  //     filters: filters
  //   });
  // };
  const value = useMemo(() => {
    return {
      filters: state,
      addFilters
      // getResponse
    };
  }, [state]);
  return (
    <GoodlistContext.Provider value={value}>
      {props.children}
    </GoodlistContext.Provider>
  );
};

export default GoodlistProvider;
