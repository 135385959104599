import React, { useState, useMemo } from "react";

export const ModalContext = React.createContext();

const ModalProvider = props => {
  const [isSignUpModalOpen, setSignupModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(
    false
  );
  const [isPasswordResetModalOpen, setPasswordResetModalOpen] = useState(false);
  const [isClaimProfileModalOpen, setClaimProfileModalOpen] = useState(false);
  const value = useMemo(() => {
    return {
      isSignUpModalOpen,
      isLoginModalOpen,
      isForgotPasswordModalOpen,
      isPasswordResetModalOpen,
      isClaimProfileModalOpen,
      setLoginModalOpen,
      setSignupModalOpen,
      setForgotPasswordModalOpen,
      setPasswordResetModalOpen,
      setClaimProfileModalOpen
    };
  }, [
    isSignUpModalOpen,
    isLoginModalOpen,
    isForgotPasswordModalOpen,
    isPasswordResetModalOpen,
    isClaimProfileModalOpen
  ]);
  return (
    <ModalContext.Provider value={value}>
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
