import React, { useEffect } from "react";
import Home from "../home/Home";
import About from "../about/About";
import Footer from "../footer/Footer";
import TopPersons from "../topPersons/TopPersons";
import InternalNavigation from "../../common/InternalNavigation";
import { ScrollPosition } from "../../managers/StoreManager";
import { UserContext } from "../../context/UserContext";
import { ModalContext } from "../../context/ModalContext";
import { SnackbarControllerContext } from "../../context/SnackbarControllerContext";
import { FullpageLoaderContext } from "../../context/FullpageLoaderContext";
import AuthService from "../../services/AuthService";
import FullpageLoader from "../../common/FullpageLoader";
import { NavigateContext } from "../../context/NavigateContext";

function LandingPage(props) {
  const { setResetToken, setresetEmail } = React.useContext(UserContext);
  const { setPasswordResetModalOpen } = React.useContext(ModalContext);
  const { navigateAnim, setNavigateAnim } = React.useContext(NavigateContext);
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  const { fullPageLoader, setFullPageLoader } = React.useContext(
    FullpageLoaderContext
  );

  const validateResetCode = async resetCode => {
    try {
      const validateResetCodeResponse = await AuthService.validateResetCode(
        props.token
      );
      if (
        validateResetCodeResponse &&
        validateResetCodeResponse.status === 200
      ) {
        setFullPageLoader({
          isOpen: false
        });
        setResetToken(validateResetCodeResponse.response.message.reset_code);
        setresetEmail(validateResetCodeResponse.response.message.email);
        setPasswordResetModalOpen(true);
      }
    } catch (err) {
      setFullPageLoader({
        isOpen: false
      });
      handleError(err);
    }
  };

  const handleError = error => {
    setSnackbarProps({
      variant: "error",
      isOpen: true,
      message:
        error && typeof error === "string"
          ? error
          : error.message && typeof error.message === "string"
          ? error.message
          : "Link Expired. Try to reset the password again"
    });
  };

  useEffect(() => {
    if (navigateAnim) {
      InternalNavigation.navigateAnim(navigateAnim);
      setNavigateAnim(null);
    }
    if (props.token) {
      setFullPageLoader({
        isOpen: true
      });
      validateResetCode(props.token);
    }
  }, [props]);
  useEffect(() => {
    ScrollPosition.deleteScrollValue();
  }, []);

  return (
    <div className="mainContainer" style={{ background: " #f9f9f9" }}>
      {props.token && fullPageLoader.isOpen ? <FullpageLoader /> : null}
      {/* <BackDrop /> */}
      <Home />
      <About />
      <TopPersons />
      <Footer />
    </div>
  );
}

export default LandingPage;
