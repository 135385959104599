import * as Yup from "yup";

const loginValidationSchema = Yup.object({
  email: Yup.string()
    .email("Email is invalid")
    .required("Email is required"),
  password: Yup.string().required("Password is required")
});
const forgotPasswordValidationSchema = Yup.object({
  email: Yup.string()
    .email("Email is invalid")
    .required("Email is required")
});
const passwordResetValidationSchema = Yup.object({
  password: Yup.string().required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "passwords must match")
    .required()
});
const signUpValidationSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z\s.]+$/,
      "Please enter a valid first Name with alphabets and spaces"
    )
    .required("First Name is required"),
  lastName: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z\s.]+$/,
      "Please enter a valid last Name with alphabets and spaces"
    )
    .required("Last Name is required"),
  email: Yup.string()
    .email()
    .matches(
      /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%&*.]{7,}$/,
      "Please enter a valid email with alphanumeric and special characters"
    )
    .required("Email is required"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%&*.]{7,}$/,
      "Password be min 7 characters with alphanumeric and special characters"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "passwords must match")
    .required("Confirm Password is required")
});
const contactValidationSchema = Yup.object({
  fullName: Yup.string()
    .trim()
    .required("Name is required"),
  email: Yup.string()
    .email()
    .required("Email is required"),
  contactNumber: Yup.string()
    .matches(
      /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
      "Please enter a valid phone number of this format "
    )
    .required("Number is required"),
  reason: Yup.string()
    .max(255)
    .trim()
    .required("Contact Message is required")
  // buyer: Yup.string()
  //   .trim()
  //   .required()
});

const claimProfileValidationSchema = Yup.object({
  email: Yup.string()
    .email()
    .required("Email is required"),
  phone: Yup.string()
    .matches(
      /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
      "Please enter a valid phone number of this format "
    )
    .required()
});
export {
  loginValidationSchema,
  signUpValidationSchema,
  contactValidationSchema,
  forgotPasswordValidationSchema,
  passwordResetValidationSchema,
  claimProfileValidationSchema
};
