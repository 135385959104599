import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Search from "../assets/images/search-copy.svg";
import PropTypes from "prop-types";
import clsx from "clsx";
import Select from "react-select";
import {
  emphasize,
  makeStyles,
  useTheme,
  withStyles
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import GoodListService from "../GoodlistComponents/GoodListService";
import useDebounce from "../common/use-debounce";
import { SnackbarControllerContext } from "../context/SnackbarControllerContext";
const CssTextField = withStyles({
  root: {
    width: "100%",
    "& .MuiInputLabel-root": {
      fontSize: "0.92em",
      color: "#606060",
      fontFamily: "ProximaNova-Regular"
    },
    "& label.Mui-focused": {
      color: "#2f3a55",
      fontFamily: "ProximaNova-Bold"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2f3a55"
    },
    "& input.Mui-focused": {
      background: "none"
    },
    "& .MuiInputBase-input": {
      display: "flex",
      height: "auto",
      fontFamily: "ProximaNova-SemiBold",
      color: "black",
      fontSize: "1em",
      margin: 0,
      padding: 0,
      background: `url(${Search}) no-repeat right 8px`,
      backgroundSize: "12px 12px"
    }
  }
})(TextField);
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
    minWidth: 290
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: theme.spacing(0, 0.25, 0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  }
}));
function NoOptionsMessage(props) {
  return (
    <Typography
      style={{ textAlign: "center" }}
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};
function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ])
};
function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      disabled={ props.data.type === false ? true : false }
      {...props.innerProps}
    >
      <div
        style={{ overflow: "hidden", textOverflow: "ellipsis", fontSize: 12 }}
      >
        {props.children}
      </div>
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]),
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired
};

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.any.isRequired,
  selectProps: PropTypes.object.isRequired
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

function MultiValue(props) {
  return (
    <Chip
      style={{
        backgroundColor: "#2f3a55",
        color: "white",
        height: "31px",
        fontSize: 14
      }}
      tabIndex={-1}
      label={
        props.children.length > 14
          ? props.children.slice(0, 12) + ".."
          : props.children
      }
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={
        <CancelIcon
          style={{ color: "white", fontSize: 18 }}
          {...props.removeProps}
        />
      }
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired
  }).isRequired,
  selectProps: PropTypes.object.isRequired
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.element.isRequired,
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};
function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { TextFieldProps }
  } = props;

  return (
    <CssTextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]).isRequired,
  selectProps: PropTypes.object.isRequired
};

const components = {
  Control,
  Menu,
  MultiValue,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null
};
function AutosuggestInput(props) {
  const classes = useStyles();
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  const theme = useTheme();
  const [multi, setMulti] = React.useState(props.selectedValuesFromLocal ? props.selectedValuesFromLocal : null);
  const [locations, setLocations] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [fetching, setFetching] = React.useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm);
  React.useEffect(() => {
    if(props.selectedValuesFromLocal && props.selectedValuesFromLocal.length) {
      setMulti(props.selectedValuesFromLocal)
    }
  }, [props.selectedValuesFromLocal])
  React.useEffect(() => {
    if (debouncedSearchTerm) {
      setFetching(true);
      getLocations(searchTerm);
    }
  }, [debouncedSearchTerm]);
  function handleChangeMulti(value) {
    if(!value && !props.selectedValuesFromLocal.length) {
      setMulti(value);
      props.handleChangeMulti(props.label, value, false);
    }
else if(!value && props.selectedValuesFromLocal.length || props.selectedValuesFromLocal && props.selectedValuesFromLocal.length > value.length ) {
    props.handleChangeMulti(props.label, value, true);
    setMulti(value)
  } else {
    props.handleChangeMulti(props.label, value, false);
    setMulti(value);
  }
  }
  const handleInputChange = value => {
    if (props.label.toLowerCase() === "factory location") {
      setLocations(null);
      setSearchTerm(value);
    }
  };
  async function getLocations(val) {
    try {
      const locations = await GoodListService.getLocations(val);
      setLocations(locations.response.locations);
      setFetching(false);
    } catch (error) {
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.message && typeof error.message === "string"
            ? error.message
            : "Please try again later"
      });
    }
  }
  const getSuggestions = () => {
    if (props.label.toLowerCase() === "factory location") {
      return locations && locations.length
        ? locations.map(location => ({
            value: location,
            label: location
          }))
        : [];
    } else {
      return (
        props.options &&
        props.options.map(suggestion => ({
          value: suggestion.value,
          label: suggestion.label,
          code: suggestion.code,
          type: suggestion.type
        }))
      );
    }
  };
  const objectsEqual = (o1, o2) => 
   typeof o1 === 'object' && Object.keys(o1).length > 0 
        ? Object.keys(o1).length === Object.keys(o2).length 
            && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
  const arraysEqual = (a1, a2) => 
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

  const suggestions = getSuggestions();
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  };
  function getNoOptionMessage() {
    if (!locations) {
      if (fetching) {
        return "Fetching..";
      } else {
        return "Search location";
      }
    } else if (locations) {
      if (fetching) {
        return "Fetching..";
      }
    }
    if (locations && !locations.length) {
      return "Location not found / Not applicable";
    }
  }
  function applyFilter () {
    if(props.selectedValuesFromLocal &&
            props.selectedValuesFromLocal.length > 0) {
                const arrayValues =  [...props.selectedValuesFromLocal, ...multi];
               const result =  arrayValues.filter((item, pos) => arrayValues.indexOf(item) === pos)
                props.handleChangeMulti(props.label, result);
            } else {
              props.handleChangeMulti(props.label, multi);
            }
  }
  return (
    <>
    {/* <div style={{height: '20px',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
    <div style={{color: '#2f3a55', cursor: 'pointer', fontSize: '12px' }} onClick={() => applyFilter()} >
    {multi && props.selectedValuesFromLocal && !arraysEqual(props.selectedValuesFromLocal , multi) ? 'Apply' : null}</div>
    </div> */}
    <Select
      arr
      isClearable={false}
      classes={classes}
      styles={selectStyles}
      inputId={`react-select-multiple${props.label}`}
      TextFieldProps={{
        label: `${props.label}`,
        InputLabelProps:
          (multi && multi.length > 0) ||
          (props.selectedValuesFromLocal &&
            props.selectedValuesFromLocal.length > 0)
            ? { shrink: true }
            : null
      }}
      options={suggestions}
      noOptionsMessage={() =>
        props.label === "Factory Location" ? getNoOptionMessage() : "No brands found"
      }
      components={components}
      value={multi}
      placeholder={""}
      onChange={handleChangeMulti}
      onInputChange={value => handleInputChange(value)}
      isMulti
    ></Select>
    </>
  );
}

export default AutosuggestInput;
