import React from "react";
import styled from "styled-components";
import Button from "../../../common/Button";
import InternalNavigation from "../../../common/InternalNavigation";
import { navigate } from "@reach/router";

import { UserContext } from "../../../context/UserContext";
import { ModalContext } from "../../../context/ModalContext";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "react-modal";
import LoginForm from "../../auth/LoginForm";
import SignUpForm from "../../auth/SignupForm";
import ForgotPasswordForm from "../../auth/ForgotPasswordForm";
import PasswordResetForm from "../../auth/PasswordResetForm";

import { customModalStyles } from "../../../utils/customModalStyles";
import { customSignUpModalStyles } from "../../../utils/customSignUpModalStyles";

import AuthService from "../../../services/AuthService";
import { SnackbarControllerContext } from "../../../context/SnackbarControllerContext";
import { NavigateContext } from "../../../context/NavigateContext";

function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user, setUser } = React.useContext(UserContext);
  const { setNavigateAnim } = React.useContext(NavigateContext);
  const { setSnackbarProps } = React.useContext(SnackbarControllerContext);
  const {
    isLoginModalOpen,
    isSignUpModalOpen,
    isForgotPasswordModalOpen,
    isPasswordResetModalOpen,
    setLoginModalOpen,
    setSignupModalOpen,
    setForgotPasswordModalOpen,
    setPasswordResetModalOpen
  } = React.useContext(ModalContext);

  async function logout() {
    // localStorage.clear();
    // setUser(null);
    // navigate("/");
    // setAnchorEl(null);
    //debugger;

    const token = JSON.parse(user)["auth_token"];
    try {
      const result = await AuthService.logout(token);
      if (result && result.status === 200) {
        localStorage.clear();
        setUser(null);
        setAnchorEl(null);
        navigate("/");
      }
    } catch (error) {
      // TODO: This is temp, this  has to be removed after the API response is fixed.
      // localStorage.clear();
      // setUser(null);
      // navigate("/");
      // setAnchorEl(null);
      // END TODO
      setSnackbarProps({
        variant: "error",
        isOpen: true,
        message:
          error && typeof error === "string"
            ? error
            : error.message && typeof error.message === "string"
            ? error.message
            : "Please try again later"
      });
    }
  }
  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function viewBookmarks() {
    setAnchorEl(null);
    navigate("/bookmarks");
  }
  function viewAdmin() {
    setAnchorEl(null);
    navigate("admin");
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }
  function handleLoginClose() {
    setLoginModalOpen(false);
  }

  function handleSignUpClose() {
    setSignupModalOpen(false);
  }
  function handleForgotPasswordClose() {
    setForgotPasswordModalOpen(false);
  }
  function handlePasswordResetClose() {
    setPasswordResetModalOpen(false);
  }
  function getUserInitials(fullname) {
    let initials = "";
    if (fullname) {
      fullname
        .split(" ")
        .forEach(token => (token ? (initials += token[0]) : ""));
    }
    return initials.toUpperCase();
  }

  const navigateTo = name => {
    if (props.main || props.contact) {
      setNavigateAnim(name);
      navigate("/");
    } else {
      InternalNavigation.navigateAnim(name);
    }
  };
  const getOpacity = y => {
    return y / 200;
  };
  return (
    <HeaderContainer
      {...props}
      style={{
        background: props.contact
          ? "white"
          : window.pageYOffset > 200 && !props.main
          ? "white"
          : props.pageY
          ? `rgba(255,255,255, ${getOpacity(props.pageY)})`
          : props.main
          ? "#f9f9f9"
          : "transparent",
        boxShadow:
          window.pageYOffset > 200 && !props.main
            ? "0 0 30px 0 rgba(0, 0, 0, 0.15)"
            : props.pageY > 200
            ? "0 0 30px 0 rgba(0, 0, 0, 0.15)"
            : "none"
      }}
    >
      <HeaderLeft {...props}>
        {/* <a onClick={() => navigateTo("#top")}>
          <span className="logoBold">good</span>
          <span className="logoRegular">list</span>
        </a> */}
        <img
          onClick={() => navigateTo("#top")}
          src={require("../../../assets/images/goodlist_logo2.svg")}
          style={{ width: "140px", cursor: "pointer" }}
          alt="goodlist"
        />
      </HeaderLeft>
      <HeaderMain {...props}>
        <div>
          <p
            style={
              props.aboutLink
                ? { color: "#d980fa", fontFamily: "ProximaNova-SemiBold" }
                : null
            }
            onClick={() => navigate("about_us")}
          >
            About
          </p>
        </div>
        <div>
          <p
            style={
              props.contactLink
                ? { color: "#d980fa", fontFamily: "ProximaNova-SemiBold" }
                : null
            }
            onClick={() => navigate("/contact")}
          >
            Contact
          </p>
        </div>
        <div>
          <p
            style={
              props.suppliers
                ? { color: "#d980fa", fontFamily: "ProximaNova-SemiBold" }
                : null
            }
            onClick={() => navigate("/suppliers")}
          >
            Suppliers
          </p>
        </div>
      </HeaderMain>
      <HeaderRight>
        {user && JSON.parse(user)["auth_token"] ? (
          <>
            <UserItem onClick={handleMenuClick} {...props}>
              <p className="initials">
                {user && JSON.parse(user).full_name
                  ? getUserInitials(JSON.parse(user).full_name)
                  : ""}
              </p>
              <p className="userName">
                {user ? JSON.parse(user).full_name : ""}
              </p>
              <i
                className="fa fa-angle-down"
                style={{
                  color: "grey",
                  fontSize: 10,
                  fontWeight: "lighter"
                }}
              />
            </UserItem>
            <Menu
              style={{ marginTop: 50 }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {JSON.parse(user).is_admin ? (
                <MenuItem
                  style={{ fontFamily: "ProximaNova-Regular" }}
                  onClick={viewAdmin}
                >
                  Admin
                </MenuItem>
              ) : null}
              <MenuItem
                style={{ fontFamily: "ProximaNova-Regular" }}
                onClick={viewBookmarks}
              >
                Bookmarks
              </MenuItem>
              <MenuItem
                style={{ fontFamily: "ProximaNova-Regular" }}
                onClick={logout}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Button
            width={"111px"}
            btnText={"Login"}
            height={"35px"}
            headerBtn
            buttonClick={() => setLoginModalOpen(true)}
          />
        )}
      </HeaderRight>

      {/* Login Modal */}
      {isLoginModalOpen && (
        <Modal
          isOpen={isLoginModalOpen}
          style={customModalStyles}
          contentLabel="Login"
          ariaHideApp={false}
          onRequestClose={handleLoginClose}
          shouldCloseOnOverlayClick={true}
        >
          <LoginForm onClose={handleLoginClose} />
        </Modal>
      )}

      {/* SignUpModal */}
      {isSignUpModalOpen && (
        <Modal
          isOpen={isSignUpModalOpen}
          style={customSignUpModalStyles}
          contentLabel="Signup"
          ariaHideApp={false}
          onRequestClose={handleSignUpClose}
          shouldCloseOnOverlayClick={true}
        >
          <SignUpForm onClose={handleSignUpClose} />
        </Modal>
      )}
      {isForgotPasswordModalOpen && (
        <Modal
          isOpen={isForgotPasswordModalOpen}
          style={customModalStyles}
          contentLabel="ForgotPassword"
          ariaHideApp={false}
          onRequestClose={handleForgotPasswordClose}
          shouldCloseOnOverlayClick={true}
        >
          <ForgotPasswordForm onClose={handleForgotPasswordClose} />
        </Modal>
      )}
      {isPasswordResetModalOpen && (
        <Modal
          isOpen={isPasswordResetModalOpen}
          style={customModalStyles}
          contentLabel="PasswordReset"
          ariaHideApp={false}
        >
          <PasswordResetForm onClose={handlePasswordResetClose} />
        </Modal>
      )}

      {/* {props.main ? null : (
        <img
          src={require("../../../assets/images/ellipse.svg")}
          alt="ellipse"
        />
      )} */}
    </HeaderContainer>
  );
}

export default Header;

const HeaderContainer = styled.div`
  z-index: 9;
  width: 100%;
  padding: 0 60px;
  border-bottom: ${props =>
    props.main ? "1px solid rgba(151,151,151, 0.15)" : null};
  position: ${props => (props.main ? "relative" : "fixed")};
  left: 0;
  top: 0;
  height: 99px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* img {
    position: absolute;
    z-index: -1;
    bottom: -5px;
    left: -20px;
    width: 280px;
    transform: rotate(190deg);
  } */
  @media (max-width: 1060px) {
    padding: 0 30px;
  }
  @media (max-width: 685px) {
    position: relative;
    padding: 0 20px;
    width: 100%;
    height: 75px;
    /* img {
      position: absolute;
      z-index: -1;
      bottom: -5px;
      left: -20px;
      width: 280px;
      transform: rotate(190deg);
    } */
  }
`;
const UserItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  .userName {
    margin-right: 7px;
    height: 17px;
    font-family: "ProximaNova-Bold";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props =>
      props.home
        ? props.pageY > 150
          ? "black"
          : "rgb(207, 207, 207)"
        : "black"};
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: flex-start;
  color: white;
  a {
    cursor: pointer;
    text-decoration: none;
    color: ${props => (props.main ? "#d980fa" : "white")};
    font-size: 1.875em;
    .logoBold {
      font-family: "Brown-Regular";
    }
    .logoRegular {
      font-family: "Brown-Light";
    }
  }
`;
const HeaderMain = styled.div`
  display: flex;
  list-style: none;
  align-items: center;
  @media (max-width: 685px) {
    display: none;
  }
  div {
    font-family: "ProximaNova-Regular";
    font-size: 1em;
    /* padding: 0 35px; */
    width: 100px;
    cursor: pointer;
    p {
      color: ${props =>
        props.home
          ? props.pageY > 150
            ? "black"
            : "rgb(207, 207, 207)"
          : "black"};
      text-decoration: none;
      &:hover {
        font-family: "ProximaNova-SemiBold";
        color: #d980fa;
      }
    }
  }
`;
const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .initials {
    font-family: "ProximaNova-SemiBold";
    width: 40px;
    height: 40px;
    background-color: #2f3a55;
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding-top: 12px;
    justify-content: center;
    border-radius: 50%;
    margin-right: 7px;
  }

  @media (max-width: 685px) {
    .userName {
      display: none;
    }
    .initials {
      background-color: #2f3a55;
    }
  }
  .loginMenu {
    top: 2em;
  }
`;
