import request from "../managers/ApiManager";
import { Configs } from "../utils/Configs";

const login = values => {
  return request({
    url: Configs.api.auth.login,
    method: "POST",
    data: values,
  });
};
const signup = values => {
  return request({
    url: Configs.api.auth.signup,
    method: "POST",
    data: values,
  });
};

const logout = token => {
  return request(
    {
      url: Configs.api.auth.logout,
      method: "POST",
      data: {},
    },
    true
  );
};
const forgotPassword = email => {
  return request({
    url: Configs.api.auth.forgotPassword,
    method: "POST",
    data: { email: email },
  });
};
const validateResetCode = resetCode => {
  return request({
    url: Configs.api.auth.validateResetCode,
    method: "POST",
    data: { reset_code: resetCode },
  });
};

const passwordReset = (values, token, email) => {
  return request({
    url: Configs.api.auth.passwordReset,
    method: "POST",
    data: {
      password: values.password,
      password_confirmation: values.confirmPassword,
      email: email,
      reset_code: token,
    },
  });
};
const AuthService = {
  login,
  signup,
  logout,
  forgotPassword,
  passwordReset,
  validateResetCode,
};
export default AuthService;
