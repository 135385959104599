import React from "react";
import styled from "styled-components";
// import Dropdown from "react-dropdown";

function DetailsExtrasCard(props) {
  // const factoryChoices =
  //   props.factory && props.factory.factory_type
  //     ? Array.isArray(props.factory.factory_type)
  //       ? props.factory.factory_type.map(data => {
  //           return {
  //             value: `${data}`,
  //             label: `${data}`,
  //             className: "myOptionClass"
  //           };
  //         })
  //       : [
  //           {
  //             value: `${props.factory.factory_type}`,
  //             label: `${props.factory.factory_type}`,
  //             className: "myOptionClass"
  //           }
  //         ]
  //     : null;
  // const productChoices = [
  //   {
  //     value: "Processed Chicken",
  //     label: "Processed Chicken",
  //     className: "myOptionClass"
  //   },
  //   {
  //     value: "Un-Processed Chicken",
  //     label: "Un-Processed Chicken",
  //     className: "myOptionClass"
  //   }
  // ];
  function getAddressAsArray() {
    let array = [];
    if (props.factory) {
      if (props.factory.address) {
        array.push(props.factory.address);
      }
      if (props.factory.city) {
        array.push(props.factory.city);
      }
      if (props.factory.state) {
        array.push(props.factory.state);
      }
      if (props.factory.country) {
        array.push(props.factory.country);
      }
      return array;
    }
  }
  function brandDetails(data) {
    return [
      {
        key: "Facility Used",
        value:
          props.factory && props.factory.factory_type
            ? props.factory.factory_type
            : null
      },
      {
        key: "Facility Address",
        type: "address",
        value: props.factory ? getAddressAsArray() : null
      },
      {
        key: "No. of Workers involved",
        value:
          props.factory && props.factory.total_workers
            ? props.factory.total_workers
            : null
      },
      {
        key: "M/F Ratio of Workers",
        value: props.factory && props.factory.ratio ? props.factory.ratio : null
      },
      {
        key: "Migrant Workers",
        value:
          props.factory && props.factory.migrant_workers
            ? props.factory.migrant_workers
            : null
      },
      {
        key: "Trade Union Associated with Workers",
        value: props.factory && props.factory.union ? props.factory.union : null
      },
      { key: "Collective Bargaining status", value: null }
    ];
  }

  function brandDetails2(data) {
    return [
      {
        key: "Subcontractors",
        value:
          data && data.subcontract && data.subcontract.trim().length > 0
            ? "YES"
            : "NO"
      },
      {
        key: "Brand Supplier since",
        value:
          data && data.brand_supplier_since ? data.brand_supplier_since : null
      },
      {
        key: "Brand Supplier Grade",
        value:
          data && data.brand_supplier_grade ? data.brand_supplier_grade : null
      },
      {
        key: "ESG program setup",
        value: data && data.brand_esg_program ? data.brand_esg_program : null
      },
      {
        key: "Brand Tier",
        value: data && data.brand_tier ? data.brand_tier : null
      }
    ];
  }
  return (
    <ExtrasCardContainer>
      {/* <SubHeading>Factory Type</SubHeading>
      <StyledDropdown
        menuClassName="myMenuClassName"
        arrowClassName="myArrowClassName"
        className="dropDown"
        controlClassName="myControlClassName"
        options={factoryChoices}
        value={factoryChoices[0]}
        arrowClosed={
          <span className="arrow-closed">
            <img src={require("../assets/images/dropdown@3x.png")} />
          </span>
        }
        arrowOpen={
          <span className="arrow-open">
            <img src={require("../assets/images/dropdown@3x.png")} />
          </span>
        }
      /> */}
      {/* <SubHeading>Products</SubHeading>
      <StyledDropdown
        menuClassName="myMenuClassName"
        arrowClassName="myArrowClassName"
        className="dropDown"
        controlClassName="myControlClassName"
        options={productChoices}
        value={productChoices[0]}
        arrowClosed={
          <span className="arrow-closed">
            <img src={require("../assets/images/dropdown@3x.png")} />
          </span>
        }
        arrowOpen={
          <span className="arrow-open">
            <img src={require("../assets/images/dropdown@3x.png")} />
          </span>
        }
      /> */}
      {/* <DropDown>
        <p>Processed Chicken</p>
        <img src={require("../assets/images/dropdown@3x.png")} />
      </DropDown> */}
      {props.factory &&
      props.factory.factory_products &&
      props.factory.factory_products.length
        ? props.factory.factory_products.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <BrandDetailsContainer>
                  {brandDetails(data).map((brand1, index) => {
                    return brand1.value ? (
                      <BrandDetails key={index}>
                        <Key>{brand1.key}</Key>
                        {brand1.type === "address" ? (
                          <Value style={{ flexDirection: "column" }}>
                            {brand1.value.map((add, index) => {
                              return (
                                <div key={index} style={{ paddingBottom: 10 }}>
                                  {add}
                                </div>
                              );
                            })}
                          </Value>
                        ) : (
                          <Value>{brand1.value}</Value>
                        )}
                      </BrandDetails>
                    ) : null;
                  })}
                </BrandDetailsContainer>
                <SubHeading>
                  {props.factory.name
                    ? `Associated Brands with Supplier’s ${
                        props.factory.name
                      } Factory ${
                        data.product ? `and ` + data.product.category : null
                      }`
                    : data.brands.length
                    ? `Associated Brands with Supplier and ` +
                      data.product.category
                    : null}
                </SubHeading>
                {data &&
                  data.brands.map((brand, index) => {
                    return (
                      <React.Fragment key={index}>
                        <BrandInfo>
                          <Logo
                            style={{
                              background:
                                brand && brand.logo_url ? null : "#f9f9f9"
                            }}
                          >
                            {brand && (
                              <img
                                alt="brand logo"
                                src={
                                  brand.logo_url
                                    ? brand.logo_url
                                    : require("../assets/images/Placeholder-logo.png")
                                }
                              />
                            )}
                          </Logo>
                          <Info>
                            <Name>{brand && brand.name}</Name>
                            <Details>
                              {brand && brand.parent_name
                                ? "Parent Organization : " + brand.parent_name
                                : null}
                            </Details>
                          </Info>
                        </BrandInfo>
                        <div>
                          {brandDetails2(brand).map((brand2, index) => {
                            return brand2.value ? (
                              <BrandDetails key={index}>
                                <Key>{brand2.key}</Key>
                                <Value>{brand2.value}</Value>
                              </BrandDetails>
                            ) : null;
                          })}
                        </div>
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            );
          })
        : null}
    </ExtrasCardContainer>
  );
}

export default DetailsExtrasCard;
const ExtrasCardContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  background: white;
  border-top: solid 0.3px rgba(151, 151, 151, 0.98);
  /* padding-top: 20px;
  @media (max-width: 685px) {
    padding-top: 20px;
  } */
`;
const SubHeading = styled.div`
  font-family: "ProximaNova-SemiBold";
  color: #313e7e;
  font-size: 1.125em;
  margin-top: 50px;
`;
// const StyledDropdown = styled(Dropdown)`
//   margin-bottom: 34px;
//   .myControlClassName {
//     width: 352px;
//     height: 61px;
//     margin-top: 13px;
//     background: #f5f5f5;
//     font-family: "ProximaNova-SemiBold";
//     padding: 0 36px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }

//   img {
//     width: 12px;
//     margin-right: 20px;
//   }
//   .myOptionClass {
//     font-family: "ProximaNova-Regular";
//     padding-top: 18px;
//     &:last-child {
//       padding-bottom: 18px;
//     }
//   }
//   .myMenuClassName {
//     width: 352px;
//     padding: 0 36px;
//     cursor: pointer;
//     box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
//   }
//   @media (max-width: 585px) {
//     .myControlClassName {
//       width: 100%;
//     }
//     .myMenuClassName {
//       width: 100%;
//     }
//   }
// `;
const BrandInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
const Logo = styled.div`
  position: relative;
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
  @media (max-width: 585px) {
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
  }
`;
const Info = styled.div`
  padding: 0 21px;
`;
const Name = styled.p`
  font-family: "ProximaNova-SemiBold";
  font-size: 1.25em;
`;
const Details = styled.p`
  font-family: "ProximaNova-Regular";
  padding-top: 4px;
  font-size: 1em;
  color: #8a8a8a;
`;
const BrandDetailsContainer = styled.div`
  margin-top: 24px;
`;
const BrandDetails = styled.div`
  display: flex;
  margin: 32px 0;
`;
const Key = styled.p`
  width: 170px;
  color: #8a8a8a;
  font-size: 1em;
  font-family: "ProximaNova-Regular";
  @media (max-width: 585px) {
    font-size: 0.9em;
    width: 50%;
    padding-right: 10px;
  }
`;
const Value = styled.div`
  margin-left: 39px;
  font-size: 1em;
  width: 50%;
  font-family: "ProximaNova-Regular";
  @media (max-width: 585px) {
    margin-left: 0px;
    width: 50%;
    font-size: 0.9em;
  }
`;
// const Program = styled.div``;
// const ProgramName = styled.p`
//   font-family: "ProximaNova-SemiBold";
//   font-size: 1em;
// `;
// const Paragraph = styled.p`
//   font-family: "ProximaNova-Regular";
//   font-size: 1em;
//   padding-top: 13px;
//   padding-bottom: 15px;
// `;
