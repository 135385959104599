import React from "react";
import styled from "styled-components";
function CopyRight() {
  return (
    <CopyRightContainer>
      © {new Date().getFullYear()} goodlist.co built by{" "}
      <a href="http://f22labs.com" target="_blank">
        <img
          className="f22logo"
          src={require("../../assets/images/f22logo.png")}
        />{" "}
        F22 Labs
      </a>
    </CopyRightContainer>
  );
}

export default CopyRight;

const CopyRightContainer = styled.p`
  position: relative;
  z-index: 999;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 0.875em;
  padding: 31px 0;
  @media (max-width: 685px) {
    padding: 12px 0;
  }
  a {
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .f22logo {
    width: 20px;
    margin: 0 4px;
  }
`;
