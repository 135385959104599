// import GoodListService from "../GoodlistComponents/GoodListService";
export const ADD_FILTERS = "ADD_FILTERS";
export const GET_RESPONSE = "GET_RESPONSE";

const addFilters = (newFilters, state) => {
  const newState = { ...newFilters };
  const oldState = { ...state };
  return { ...oldState, ...newState };
};
// const listProducts = async (newFilters, state) => {
// setFilters(params);
//   const newState = { ...newFilters };
//   const oldState = { ...state };
//   const combinedState = { ...oldState, ...newState };
//   try {
//     console.log("combi", combinedState);
//     const response = await GoodListService.listProducts(combinedState);
//     console.log("bia", response);
//     return response;
//   } catch (err) {
//     return err;
//   }
// };
export const GoodlistReducer = (state, action) => {
  switch (action.type) {
    case ADD_FILTERS:
      return addFilters(action.filters, state);
    // case GET_RESPONSE:
    //   return listProducts(action.filters, state);
    default:
      return state;
  }
};
