import React from "react";
import ReactGA from "react-ga";
import "./App.css";
import LandingPage from "./components/LandingPage/LandingPage";
import { Router, createHistory } from "@reach/router";
import GoodList from "./GoodList";
import AppProviders from "./context/app-providers";
import Contact from "./components/contact/Contact";
import TermsAndConditions from "./components/termsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./components/termsAndConditions/PrivacyPolicy";
import SupplierDetailsView from "./GoodlistComponents/SupplierDetailsView";
import BookmarkedSuppliers from "./GoodlistComponents/BookmarkedSuppliers";
import WhoWeAre from "./components/whoWeAre/WhoWeAre";
import UploadData from "./admin/UploadData";
import NotFound from "./common/NotFound";

//Google Analytics
const history = createHistory(window);
ReactGA.initialize(process.env.REACT_APP_GA_KEY );
history.listen(location => {
  if (location) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
});
function App(props) {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="mainContainer" style={{ background: "#f9f9f9" }}>
      <AppProviders>
        <Router history={history}>
        <NotFound default />
          <LandingPage path="/" />
          <LandingPage path="resetpassword/:token" />
          <GoodList path="suppliers" />
          <SupplierDetailsView path="details/:userId" />
          <BookmarkedSuppliers path="bookmarks" />
          <UploadData path="admin" />
          <WhoWeAre path="about_us" />
          <Contact path="contact" />
          <TermsAndConditions path="termsofservice" />
          <PrivacyPolicy path="privacypolicy" />
        </Router>
      </AppProviders>
    </div>
  );
}

export default App;
