import React from "react";
import styled from "styled-components";
import Header from "../home/header/Header";
import Footer from "../footer/Footer";

function PrivacyPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainContainer className="mainContainer" style={{ background: " #f9f9f9" }}>
      <Header contact={true} main={true} />
      <PrivacyPolicyContainer>
        <Content>
          <h2 className="heading">Privacy policy</h2>
          <p>
            This privacy policy ("Policy") describes how GoodList LLC ("GoodList
            LLC", "we", "us" or "our") collects, protects and uses the
            personally identifiable information ("Personal Information") you
            ("User", "you" or "your") may provide on the{" "}
            <a
              href="http://www.goodlist.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              goodlist.co
            </a>{" "}
            website and any of its products or services (collectively, "Website"
            or "Services"). It also describes the choices available to you
            regarding our use of your Personal Information and how you can
            access and update this information. This Policy does not apply to
            the practices of companies that we do not own or control, or to
            individuals that we do not employ or manage.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Automatic collection of information</h2>
          <p>
            When you visit the Website our servers automatically record
            information that your browser sends. This data may include
            information such as your device's IP address, browser type and
            version, operating system type and version, language preferences or
            the webpage you were visiting before you came to our Website, pages
            of our Website that you visit, the time spent on those pages,
            information you search for on our Website, access times and dates,
            and other statistics.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Collection of personal information</h2>
          <p>
            You can visit the Website without telling us who you are or
            revealing any information by which someone could identify you as a
            specific, identifiable individual. If, however, you wish to use some
            of the Website's features, you will be asked to provide certain
            Personal Information (for example, your name and e-mail address). We
            receive and store any information you knowingly provide to us when
            you create an account, or fill any online forms on the Website. When
            required, this information may include your email address, name,
            phone number, or other Personal Information. You can choose not to
            provide us with your Personal Information, but then you may not be
            able to take advantage of some of the Website's features. Users who
            are uncertain about what information is mandatory are welcome to
            contact us.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Managing personal information</h2>
          <p>
            You are able to access, add to, update and delete certain Personal
            Information about you. The information you can view, update, and
            delete may change as the Website or Services change. When you update
            information, however, we may maintain a copy of the unrevised
            information in our records. Some information may remain in our
            private records after your deletion of such information from your
            account. We will retain and use your Personal Information for the
            period necessary to comply with our legal obligations, resolve
            disputes, and enforce our agreements unless a longer retention
            period is required or permitted by law. We may use any aggregated
            data derived from or incorporating your Personal Information after
            you update or delete it, but not in a manner that would identify you
            personally. Once the retention period expires, Personal Information
            shall be deleted. Therefore, the right to access, the right to
            erasure, the right to rectification and the right to data
            portability cannot be enforced after the expiration of the retention
            period.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Links to other websites</h2>
          <p>
            Although this Website may link to other websites, we are not,
            directly or indirectly, implying any approval, association,
            sponsorship, endorsement, or affiliation with any linked website,
            unless specifically stated herein. Some of the links on the Website
            may be "affiliate links". This means if you click on the link and
            purchase an item, GoodList LLC will receive an affiliate commission.
            We are not responsible for examining or evaluating, and we do not
            warrant the offerings of, any businesses or individuals or the
            content of their websites. We do not assume any responsibility or
            liability for the actions, products, services, and content of any
            other third-parties. You should carefully review the legal
            statements and other conditions of use of any website which you
            access through a link from this Website. Your linking to any other
            off-site websites is at your own risk.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Use of collected information</h2>
          <p>
            Any of the information we collect from you may be used to
            personalize your experience; improve our Website; improve customer
            service and respond to queries and emails of our customers; send
            newsletters; send notification emails such as password reminders,
            updates, etc; run and operate our Website and Services. Information
            collected automatically is used only to identify potential cases of
            abuse and establish statistical information regarding Website usage.
            This statistical information is not otherwise aggregated in such a
            way that would identify any particular user of the system.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Intellectual property rights</h2>
          <p>
            This Agreement does not transfer to you any intellectual property
            owned by GoodList LLC or third-parties, and all rights, titles, and
            interests in and to such property will remain (as between the
            parties) solely with GoodList LLC. All trademarks, service marks,
            graphics and logos used in connection with our Website or Services,
            are trademarks or registered trademarks of GoodList LLC or GoodList
            LLC licensors. Other trademarks, service marks, graphics and logos
            used in connection with our Website or Services may be the
            trademarks of other third-parties. Your use of our Website and
            Services grants you no right or license to reproduce or otherwise
            use any GoodList LLC or third-party trademarks.
          </p>
        </Content>
        <Content>
          <h2 className="heading">California Privacy Rights</h2>
          <p>
            In addition to the rights as explained in this Privacy Policy,
            California residents who provide Personal Information (as defined in
            the statute) to obtain products or services for personal, family, or
            household use are entitled to request and obtain from us, once a
            calendar year, information about the Personal Information we shared,
            if any, with other businesses for marketing uses. If applicable,
            this information would include the categories of Personal
            Information and the names and addresses of those businesses with
            which we shared such personal information for the immediately prior
            calendar year (e.g., requests made in the current year will receive
            information about the prior year). To obtain this information please
            contact us.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Privacy of children</h2>
          <p>
            We do not knowingly collect any Personal Information from children
            under the age of 13. If you are under the age of 13, please do not
            submit any Personal Information through our Website or Service. We
            encourage parents and legal guardians to monitor their children's
            Internet usage and to help enforce this Policy by instructing their
            children never to provide Personal Information through our Website
            or Service without their permission. If you have reason to believe
            that a child under the age of 13 has provided Personal Information
            to us through our Website or Service, please contact us. You must
            also be old enough to consent to the processing of your Personal
            Information in your country (in some countries we may allow your
            parent or guardian to do so on your behalf).
          </p>
        </Content>
        <Content>
          <h2 className="heading">Newsletters</h2>
          <p>
            We offer electronic newsletters to which you may voluntarily
            subscribe at any time. You may choose to stop receiving our
            newsletter or marketing emails by following the unsubscribe
            instructions included in these emails or by contacting us. However,
            you will continue to receive essential transactional emails.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Do Not Track signals</h2>
          <p>
            Some browsers incorporate a Do Not Track feature that signals to
            websites you visit that you do not want to have your online activity
            tracked. Tracking is not the same as using or collecting information
            in connection with a website. For these purposes, tracking refers to
            collecting personally identifiable information from consumers who
            use or visit a website or online service as they move across
            different websites over time. How browsers communicate the Do Not
            Track signal is not yet uniform. As a result, this Website is not
            yet set up to interpret or respond to Do Not Track signals
            communicated by your browser. Even so, as described in more detail
            throughout this Policy, we limit our use and collection of your
            personal information.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Dispute resolution</h2>
          <p>
            The formation, interpretation, and performance of this Agreement and
            any disputes arising out of it shall be governed by the substantive
            and procedural laws of New York, United States without regard to its
            rules on conflicts or choice of law and, to the extent applicable,
            the laws of United States. The exclusive jurisdiction and venue for
            actions related to the subject matter hereof shall be the state and
            federal courts located in New York, United States, and you hereby
            submit to the personal jurisdiction of such courts. You hereby waive
            any right to a jury trial in any proceeding arising out of or
            related to this Agreement. The United Nations Convention on
            Contracts for the International Sale of Goods does not apply to this
            Agreement
          </p>
        </Content>
        <Content>
          <h2 className="heading">Advertisement</h2>
          <p>
            We may display online advertisements and we may share aggregated and
            non-identifying information about our customers that we collect
            through the registration process or through online surveys and
            promotions with certain advertisers. We do not share personally
            identifiable information about individual customers with
            advertisers. In some instances, we may use this aggregated and
            non-identifying information to deliver tailored advertisements to
            the intended audience.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Affiliates</h2>
          <p>
            We may disclose information about you to our affiliates for the
            purpose of being able to offer you related or additional products
            and services. Any information relating to you that we provide to our
            affiliates will be treated by those affiliates in accordance with
            the terms of this Privacy Policy.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Links to other websites</h2>
          <p>
            Our Website contains links to other websites that are not owned or
            controlled by us. Please be aware that we are not responsible for
            the privacy practices of such other websites or third-parties. We
            encourage you to be aware when you leave our Website and to read the
            privacy statements of each and every website that may collect
            Personal Information.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Information security</h2>
          <p>
            We secure information you provide on computer servers in a
            controlled, secure environment, protected from unauthorized access,
            use, or disclosure. We maintain reasonable administrative,
            technical, and physical safeguards in an effort to protect against
            unauthorized access, use, modification, and disclosure of Personal
            Information in its control and custody. However, no data
            transmission over the Internet or wireless network can be
            guaranteed. Therefore, while we strive to protect your Personal
            Information, you acknowledge that (i) there are security and privacy
            limitations of the Internet which are beyond our control; (ii) the
            security, integrity, and privacy of any and all information and data
            exchanged between you and our Website cannot be guaranteed; and
            (iii) any such information and data may be viewed or tampered with
            in transit by a third-party, despite best efforts.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Data breach</h2>
          <p>
            In the event we become aware that the security of the Website has
            been compromised or users Personal Information has been disclosed to
            unrelated third parties as a result of external activity, including,
            but not limited to, security attacks or fraud, we reserve the right
            to take reasonably appropriate measures, including, but not limited
            to, investigation and reporting, as well as notification to and
            cooperation with law enforcement authorities. In the event of a data
            breach, we will make reasonable efforts to notify affected
            individuals if we believe that there is a reasonable risk of harm to
            the user as a result of the breach or if notice is otherwise
            required by law. When we do, we will send you an email.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Legal disclosure</h2>
          <p>
            We will disclose any information we collect, use or receive if
            required or permitted by law, such as to comply with a subpoena, or
            similar legal process, and when we believe in good faith that
            disclosure is necessary to protect our rights, protect your safety
            or the safety of others, investigate fraud, or respond to a
            government request. In the event we go through a business
            transition, such as a merger or acquisition by another company, or
            sale of all or a portion of its assets, your user account, and
            Personal Information will likely be among the assets transferred.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Changes and amendments</h2>
          <p>
            We may update this Privacy Policy from time to time in our
            discretion and will notify you of any material changes to the way in
            which we treat Personal Information. When changes are made, we will
            revise the updated date at the bottom of this page. We may also
            provide notice to you in other ways in our discretion, such as
            through contact information you have provided. Any updated version
            of this Privacy Policy will be effective immediately upon the
            posting of the revised Privacy Policy unless otherwise specified.
            Your continued use of the Website or Services after the effective
            date of the revised Privacy Policy (or such other act specified at
            that time) will constitute your consent to those changes. However,
            we will not, without your consent, use your Personal Data in a
            manner materially different than what was stated at the time your
            Personal Data was collected.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Acceptance of this policy</h2>
          <p>
            You acknowledge that you have read this Policy and agree to all its
            terms and conditions. By using the Website or its Services you agree
            to be bound by this Policy. If you do not agree to abide by the
            terms of this Policy, you are not authorized to use or access the
            Website and its Services.
          </p>
        </Content>
        <Content>
          <h2 className="heading">Contacting us</h2>
          <p>
            If you would like to contact us to understand more about this
            Agreement or wish to contact us concerning any matter relating to
            it, you may send an email to info@goodlist.co
          </p>
          <p>This document was last updated on August 29, 2019</p>
        </Content>
      </PrivacyPolicyContainer>
      <Footer contact={true} />
    </MainContainer>
  );
}

export default PrivacyPolicy;
const MainContainer = styled.div`
  min-height: 100vh;
  @media (min-width: 2400px) {
    height: 100vh;
    overflow: auto;
  }
`;

const PrivacyPolicyContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 80px;
  margin-left: 1.6em;
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 685px) {
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
  }
  @media (max-width: 1022px) {
    margin-left: 0em;
  }
`;

const Content = styled.div`
  margin: 1em 1em 1em 2em;
  .heading {
    font-family: "Helvetica";
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #172340;
    margin-bottom: 0.6em;
  }
  p {
    font-family: "ProximaNova-Regular";
    font-size: 14px;
    line-height: 22px;
    a {
      text-decoration: none;
      color: #3177b3;
    }
    a:hover {
      text-decoration: underline;
    }
  }
`;
