import React from "react";
import ModalProvider from "./ModalContext";
import UserProvider from "./UserContext";
import SnackbarProvider from "./SnackbarControllerContext";
import SnackbarUtil from "../utils/SnackbarUtil";
import FilterModalProvider from "../context/FilterModalControllerContext";
import FullpageLoaderProvider from "./FullpageLoaderContext";
import GoodlistProvider from "./GoodlistContext";
import SupplierProvider from "./SupplierContext";
import ScrollPositionProvider from "./ScrollPositionContext";
import FilterCountProvider from "./FilterCountContext";
import NavigateProvider from "./NavigateContext";
import FilterOptionsProvider from "./FilterOptionsContext";
function AppProviders({ children }) {
  return (
    <SnackbarProvider>
      <SnackbarUtil />
      <FullpageLoaderProvider>
        <UserProvider>
          <FilterModalProvider>
            <ModalProvider>
              <SupplierProvider>
                <ScrollPositionProvider>
                  <FilterCountProvider>
                    <NavigateProvider>
                      <FilterOptionsProvider>
                      <GoodlistProvider>{children}</GoodlistProvider>
                      </FilterOptionsProvider>
                    </NavigateProvider>
                  </FilterCountProvider>
                </ScrollPositionProvider>
              </SupplierProvider>
            </ModalProvider>
          </FilterModalProvider>
        </UserProvider>
      </FullpageLoaderProvider>
    </SnackbarProvider>
  );
}

export default AppProviders;
